<script>
let defaultMessage = 'Your application will relaunch automatically after the update is complete...';
export default {
  name: 'ErrorDialog',
  props:[
    'dialog',
    'message',
    'onCloseModal',
  ],
  data () {
    return {
      isDialog: null,
      isMessage: '',
    }
  },
  methods:{
    onClose(){
      this.onCloseModal(false);
    }
  },
  created() {
    this.isDialog = this.dialog;
    this.isMessage = this.message ? this.message : defaultMessage;
  },
  watch: {
    isDialog(dialog){
      this.isDialog = dialog;
    }
  }
};
</script>

<template>
  <div class="text-center pa-4">
    <v-dialog
      v-model="isDialog"
      width="auto"
      persistent
    >
      <v-card
        min-width="400"
      >
        <v-card-title class="bg-red">
          <v-icon size="small" class="mx-2">mdi-alert</v-icon>
          <span>Warning Message</span>
        </v-card-title>
        <v-divider/>
        <v-card-text class="">
          {{isMessage}}
        </v-card-text>
        <v-divider/>

        <v-card-actions>
          <v-btn
            class="text-red"
            width="50%"
            text="Close"
            @click="onClose()"
          ></v-btn>

          <v-spacer></v-spacer>

          <v-btn
            class="text-green"
            width="50%"
            text="YES"
            @click="onClose()"
          ></v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>

</style>