<template>
  <div>
    <v-card rounded="0" class="px-10 pa-3">
      <v-row>
        <v-col cols="6" sm="6" md="2">
          <img class="mt-2" width="200px" src="../../../assets/logo.png" alt="" />
        </v-col>

        <v-col cols="12" sm="12" md="8" class="hidden"></v-col>
      </v-row>
    </v-card>

    <div class="margin-center">
      <br />
      <br />
      <h2 class="text-center">Welcome back to Staff section</h2>
      <v-alert
        border="top"
        type="error"
        class="mb-2"
        v-if="msgInvalidLogin !== null"
        variant="outlined"
        prominent
        style="background-color: white"
      >
        {{ msgInvalidLogin }}
      </v-alert>
      <br />

      <v-form ref="form" lazy-validation>
        
        <p class="mb-2"></p>

        <v-text-field
          v-model.trim="email"
          :rules="emailRules"
          label="Email"
          placeholder="Email"
          variant="outlined"
          prepend-inner-icon="mdi-email"
          required
          autocomplete="off"
          density="compact"
          persistent-placeholder
        ></v-text-field>

        <p class="mb-2"></p>

        <v-text-field
          v-model.trim="password"
          :rules="passwordRules"
          label="Password"
          placeholder="Password"
          variant="outlined"
          prepend-inner-icon="mdi-lock"
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          @click:append-inner="visible = !visible"
          required
          autocomplete="off"
          density="compact"
          persistent-placeholder
        ></v-text-field>

        <br />

        <v-btn
          class="me-4"
          block
          color="blue-darken-4"
          rounded="xl"
          @click="signIn"
        >
          Sign In
        </v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "@/store/staffAuth";
import axios from "axios";
import { TOKEN_HEADERS } from "@/utils/headerToken";
import { URL_STAFF_VIEW_LOGIN } from "@/utils/apiUrl";
import { originalCode } from "@/utils/formatNumber";

const browserInfo = {
  appName: navigator.appName,
  appVersion: navigator.appVersion,
  userAgent: navigator.userAgent,
  platform: navigator.platform,
};

export default {
  name: "StaffLoginPage",
  data: () => ({
    userInfo: [],
    browserInfo: [],
    email: "",
    emailRules: [
      (v) => !!v || "First name is required",
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "email must be valid",
    ],
    password: "",
    passwordRules: [(v) => !!v || "First name is required"],
    visible: false,
    msgInvalidLogin: null,
  }),
  created() {
    this.getUserInfo();
    if(location.host.includes('127.0.0.1') || location.host.includes('localhost')){
      this.email = 'admin@gmail.com';
      this.password = '12345678';
    }
  },
  methods: {
    async getUserInfo() {
      try {
        const response = await axios.get("https://ipapi.co/json");
        this.userInfo = response.data;
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    },

    async signIn() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        
        let authStore = useAuthStore();

        await authStore.login(this.email, this.password);
        
        if (authStore.token) {
          localStorage.removeItem("msgInvalid");
          this.getUserLoginInfo(originalCode(authStore.id));
          location.href = '/dashboard';
          this.$router.push({ path: "/dashboard" });
        }

        if (localStorage.getItem("msgInvalid") !== null) {
          this.msgInvalidLogin = localStorage.getItem("msgInvalid");
        } 
      }
    },

    async getUserLoginInfo(staffId) {
      const data = {
        staffId: parseInt(staffId),
        ip: this.userInfo.ip,
        isp: this.userInfo.org,
        browser: browserInfo.userAgent,
        longitude: this.userInfo.longitude.toString(),
        latitude: this.userInfo.latitude.toString(),
        city: this.userInfo.city,
        country: this.userInfo.country_name,
        countryCode: this.userInfo.country_code,
        countryRegion: this.userInfo.region_code,
      };

      await axios
        .post(URL_STAFF_VIEW_LOGIN, data,
        {
          headers: TOKEN_HEADERS,
        }
        )
        .then((response) => {
          if (response.data.statusCode === 400) {
            console.log("Success");
          }
        })
        .catch((error) => console.error("There was an error!", error));
    },
  },
};
</script>
