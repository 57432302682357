import moment from "moment";

export const formatNumber = (value) => {
  if (value >= 1000000000) {
    return (value / 1000000000).toFixed(1) + "B";
  }
  if (value >= 1000000) {
    return (value / 1000000).toFixed(1) + "M";
  }
  if (value >= 1000) {
    return (value / 1000).toFixed(1) + "K";
  }
  return value;
};

export const prefixCode = () => {
  return 619248;
};

export const suffixCode = () => {
  return 485619;
};

export const routedAliasCode = () => {
  return prefixCode() + suffixCode();
}

export const originalCode = (value) => {
  return value - routedAliasCode();
}

export const dateFormatted = (value) => {
  return moment(value).format("MMMM DD, YYYY");
}
