import { defineStore } from "pinia";

export const useProjectEventStore = defineStore('projectEventEntity', {
    state: () => ({
        entity: JSON.parse(localStorage.getItem('entity')) || []
    }),
    actions: {
        stateProjectEventEntity(event) {
            this.entity = event;
            localStorage.setItem('entity', JSON.stringify(this.entity));
        },

        clearLocalStorageContents() {
            this.entity = [];
            localStorage.setItem('entity', JSON.stringify(this.entity));
        }
    }
});

export const useEmailSubscribe = defineStore('emailSubscribe', {
    state: () => ({
        mail: JSON.parse(localStorage.getItem('mail')) || []
    }),
    actions: {
        stateEmailSubscribe(email) {
            this.mail = email;
            localStorage.setItem('mail', JSON.stringify(this.mail));
        },

        clearLocalStorageContents() {
            this.mail = [];
            localStorage.setItem('mail', JSON.stringify(this.mail));
        }
    }
});