export const BASE_URL = process.env.VUE_APP_BASE_URL;
export const PREFIX = '/api';
export const BASE_URL_API = BASE_URL + PREFIX;
// customer authentication
export const CUSTOMER_SIGN_IN = BASE_URL_API + '/auth/customer/sign-in';
export const CUSTOMER_SIGN_UP = BASE_URL_API + '/auth/customer/sign-up';
export const CUSTOMER_PROFILE = BASE_URL_API + '/auth/customer/user-profile';
export const UPDATE_PROFILE = BASE_URL_API + '/auth/customer/update-profile';
export const UPDATE_ACCOUNT = BASE_URL_API + '/auth/customer/update-account';
export const UPDATE_ACCOUNT_VERIFICATION = BASE_URL_API + '/auth/customer/update-account-verification';
export const UPLOAD_PHOTO = BASE_URL_API + '/auth/customer/photo';
export const UPLOAD_PHOTO_PATH = BASE_URL_API + '/auth/customer/path/';
export const BANK_TYPE = BASE_URL_API + '/customer/bank-type';
export const WALLET_TYPE = BASE_URL_API + '/customer/wallet/';
// export const BANK_ACCOUNT_DEPOSIT = BASE_URL_API + '/customer/bank-account-deposit';
export const BANK_ACCOUNT_WITHDRAW = BASE_URL_API + '/customer/bank-account-withdraw';
export const SET_PIN_CODE = BASE_URL_API + '/customer/wallet/set-pin-code';
export const UPDATE_PHOTO = BASE_URL_API + '/auth/customer/update-photo';
export const REFERRAL = BASE_URL_API + '/referral-rewards';
//about-us
// export const ABOUT_US_BLOG = BASE_URL_API + '/client/about-us-blog';
// export const ANNUAL_REPORT = BASE_URL_API + '/client/about-us/annual-report';
// export const EVENT = BASE_URL_API + '/client/about-us/event';
// export const POLITICAL_RISK_INSURANCE = BASE_URL_API + '/client/about-us/political-risk-insurance';
//our-process
// export const OUR_PROCESS = BASE_URL_API + '/client/our-process';
// export const ASIA_PACIFIC = BASE_URL_API + '/client/our-process/asia-pacific';
// export const OUR_IMPACT = BASE_URL_API + '/client/our-process/our-impact';
// export const SMALL_INVESTMENT_PROGRAM = BASE_URL_API + '/client/our-process/small-investment-program';
// export const CURRENCY = BASE_URL_API + '/client/our-process/currency';
// projects
export const PROJECT = BASE_URL_API + '/client/project';

export const PROJECT_BY_ID = BASE_URL_API + '/client/project/get-project-by-id';
export const PROJECT_SEARCH = BASE_URL_API + '/client/project/search-project';
export const COUNT_PROJECT_BY_USER_INVEST = BASE_URL_API + '/client/project/count-project-by-user-invest';
// export const CLOSING_SOON = BASE_URL_API + '/client/project/closing-soon';
// export const CROWD_FINDING = BASE_URL_API + '/client/project/crowd-finding';
// export const FEATURE_PROJECT = BASE_URL_API + '/client/project/feature-project';
// export const NEWEST_PROJECT = BASE_URL_API + '/client/project/newest-project';
// export const PROJECT_DETAILS = BASE_URL_API + '/client/project/project-details';
// ukraine
export const UKRAINE = BASE_URL_API + '/client/ukraine';
// covid
// export const COVID = BASE_URL_API + '/client/covid';
// export const COVID_DETAILS = BASE_URL_API + '/client/covid/details';
// home
export const HOME = BASE_URL_API + '/client/home';
// contact us
// export const CONTACT_US = BASE_URL_API + '/client/contact-us';
//
export const COMPANY = BASE_URL_API + '/client/company';
// invest
export const CUSTOMER_INVEST = BASE_URL_API + '/client/customer-invest';
export const CUSTOMER_INVEST_TRANSACTION  = CUSTOMER_INVEST + "/transactions";
export const SEARCH_INVEST_NO = CUSTOMER_INVEST + '/search-invest-no';
export const RETURN_INTEREST_TRANSACTION = CUSTOMER_INVEST + '/return-interest-transactions';

export const CUSTOMER_DEPOSIT = BASE_URL_API + '/client/customer-deposit';
//
export const CUSTOMER_NOTIFICATION = BASE_URL_API + '/client/customer-notification';
export const CUSTOMER_NOTIFICATION_CLEAR = CUSTOMER_NOTIFICATION + '/clear'

export const API_URL_USER =  'https://get.geojs.io/v1/ip/country.json';
export const DEFAULT_IMAGE =  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNNLEL-qmmLeFR1nxJuepFOgPYfnwHR56vcw&s';
export const LOADING_IMAGE =  'https://assets-v2.lottiefiles.com/a/d0c1a50a-1171-11ee-bc55-77e80401811a/LgJcg4Igt2.gif';