<template>
  <Suspense timeout="0">
    <router-view />
  </Suspense>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapState(["company"]),
  },
  created() {
    document.title = "Welcome to Asia Projects";
  },
};
</script>

<style scoped>
</style>
