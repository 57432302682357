<template>
  <FrontendLayout>
    <v-img
      min-height="720"
      width="100%"
      cover
      :src="
        data.image !== null || data.image !== ''
          ? path + data.image
          : 'https://assets-v2.lottiefiles.com/a/d0c1a50a-1171-11ee-bc55-77e80401811a/LgJcg4Igt2.gif'
      "
    >
      <template v-slot:placeholder>
        <v-row align="center" class="fill-height ma-0" justify="center">
          <v-progress-circular
            color="grey-lighten-5"
            indeterminate
          ></v-progress-circular>
        </v-row>
      </template>
      <!--
      <h2 class="text-uppercase text-center text-blue-darken-5">
        {{ data.title }}
      </h2>
      -->
    </v-img>

    <div class="bg-grey-lighten-4">
      <h2 class="text-uppercase text-center text-blue-darken-3 pa-5" style="letter-spacing: 2px;">
        {{ data.title }}
      </h2>

      <div class="margin-auto">
        <v-row>
          <v-col cols="12" md="8">
            <p
              class="text-content text-justify font-format pa-5"
              v-html="data.desc"
            ></p>
          </v-col>

          <v-col cols="12" md="4">
            <div style="height: auto; top: 55px; position: sticky">
              <h2 style="letter-spacing: 2px;">Related</h2>
              <hr />
              <CardRelated :events="events" />
              <!--
              <template v-for="i in 6" v-bind:key="i">
                <CardRelated />
              </template> -->
            </div>
          </v-col>
        </v-row>

        <br /><br /><br />
      </div>
    </div>
  </FrontendLayout>
</template>
<script>
import FrontendLayout from "@/views/client/components/FrontendLayout.vue";
import CardRelated from "@/views/client/about-us/components/CardRelated.vue";
import {
  Path_Our_Political_Risk_Insurance_Image,
  URL_Our_Political_Risk_Insurance,
  URL_PROJECT,
} from "@/utils/apiUrl";
import axios from "axios";
import { TOKEN_HEADERS } from "@/utils/headerToken";

export default {
  name: "AboutUs",
  components: { FrontendLayout, CardRelated },
  data() {
    return {
      path: Path_Our_Political_Risk_Insurance_Image,

      data: [],
      events: [],
    };
  },
  methods: {
    async fetchData() {
      await axios
        .get(URL_Our_Political_Risk_Insurance, { headers: TOKEN_HEADERS })
        .then((response) => {
          this.data = response.data[0];
        })
        .catch((error) => {
          console.error("Failed to", error);
        });
    },

    async fetchProjects() {
      try {
        const response = await axios.get(URL_PROJECT, {
          headers: TOKEN_HEADERS,
        });
        //this.events = response.data;
        if (response.data !== null) {
          this.events = response.data
            .filter((project) => project.project_type === "Active")
            .sort(() => 0.5 - Math.random())
            .slice(0, 6);
        }
      } catch (error) {
        console.error("Error fetching projects event:", error);
      }
    },
  },
  created() {
    this.fetchData();
    this.fetchProjects();
  },
};
</script>

<style scoped>
.font-format {
  font-size: 0.875rem;
  line-height: 25px;
}
</style>
