<template>
  <FrontendLayout>
    <div style="height: 700px; background-color: gainsboro">
      <v-img
        style="height: 700px; width: 100%; background-color: gainsboro"
        cover
        :src="
          aboutUs?.image !== null
            ? aboutUs?.image
            : 'https://assets-v2.lottiefiles.com/a/d0c1a50a-1171-11ee-bc55-77e80401811a/LgJcg4Igt2.gif'
        "
      >
        <template v-slot:placeholder>
          <v-row align="center" class="fill-height ma-0" justify="center">
            <v-progress-circular
              color="grey-lighten-5"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </template>
        <!--
        <h2 class="text-uppercase text-center text-white">
          {{ aboutUs.title }}
        </h2>
        -->
      </v-img>
    </div>

    <div class="bg-grey-lighten-4">
      <h3 class="text-uppercase text-center text-blue-darken-3 pa-5">
        {{ aboutUs.title }}
      </h3>

      <div class="margin-auto">
        <p class="text-content text-justify font-format" v-html="aboutUs.description"></p>
      </div>
      <br /><br /><br />
    </div>
  </FrontendLayout>
</template>

<script>
import FrontendLayout from "../components/FrontendLayout.vue";
import { mapState } from "vuex";

export default {
  name: "AboutUs",
  computed: {
    ...mapState(["aboutUs"]),
  },
  components: { FrontendLayout },
};
</script>

<style scoped>
.font-format {
  font-size: 0.875rem;
  line-height: 25px;
}
</style>
