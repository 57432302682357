<template>
  <div class="margin-auto">
    <v-card class="card-padding" rounded="xl" elevation="0">

      <!--        <pre>-->
      <!--          {{form}}-->
      <!--        </pre>-->

      <v-row>
        <v-col cols="12" md="3">
          <p class="mb-3 text-h5"> Select Payment Method </p>

          <v-sheet rounded="xl" class="d-flex" color="" height="50" width="100%">

            <v-sheet border class="d-flex align-center justify-center rounded-ts-xl rounded-bs-xl" color="white"
                     width="50">
              <v-icon class="">mdi-cash-multiple</v-icon>
            </v-sheet>

            <v-sheet border width="100%" class="rounded-te-xl rounded-be-xl">
              <v-autocomplete
                density="comfortable"
                v-model="walletAccount"
                :items="[
                   // {
                   //    id: 4,
                   //    availableBalance: 40,
                   //    accountBalance: 44,
                   //    bankAccount: '000 000 100',
                   //    walletType: 'personal account',
                   //    pin_code: '4444'
                   //  }
                   ...wallet
                ]"
                variant="plain"
                rounded="xl"
                suffix="USD"
                menu-icon="mdi-chevron-right"
                return-object
                class="ml-2"
              >
                <template v-slot:chip="{ props, item }">
                  <p v-if="walletAccount" class="mr-2 text-overflow-hidden">{{ item.raw.bankAccount }}</p>
                  <v-chip
                    v-if="walletAccount"
                    color="primary"
                    v-bind="props"
                    :text="item.raw.walletType"
                    variant="flat"
                    density="compact"
                  ></v-chip>
                </template>

                <template v-slot:item="{ props, item }">
                  <v-list-item
                    v-bind="props"
                    :title="item.raw.bankAccount"
                    :subtitle="item.raw.walletType"
                  ></v-list-item>
                </template>

              </v-autocomplete>
            </v-sheet>
          </v-sheet>

        </v-col>

        <v-col cols="12" md="9"></v-col>

        <v-col cols="12" md="3">
          <p class="mb-3 text-h5"> Choose Wallet </p>

          <v-sheet rounded="xl" class="d-flex" color="" height="50" width="100%">

            <v-sheet border class="d-flex align-center justify-center rounded-ts-xl rounded-bs-xl" color="white"
                     width="50">
              <v-icon class="">mdi-wallet-outline</v-icon>
            </v-sheet>

            <v-sheet border width="100%" class="rounded-te-xl rounded-be-xl">
              <v-autocomplete
                density="comfortable"
                v-model="walletAccount"
                :items="[
                   // {
                   //    id: 4,
                   //    availableBalance: 40,
                   //    accountBalance: 44,
                   //    bankAccount: '000 000 100',
                   //    walletType: 'personal account',
                   //    pin_code: '4444'
                   //  }
                   ...wallet
                ]"
                variant="plain"
                rounded="xl"
                suffix="USD"
                menu-icon="mdi-chevron-right"
                return-object
                class="ml-2"
              >
                <template v-slot:chip="{ props, item }">
                  <p v-if="walletAccount" class="mr-2 text-overflow-hidden">{{ item.raw.bankAccount }}</p>
                  <v-chip
                    v-if="walletAccount"
                    color="primary"
                    v-bind="props"
                    :text="item.raw.walletType"
                    variant="flat"
                    density="compact"
                  ></v-chip>
                </template>

                <template v-slot:item="{ props, item }">
                  <v-list-item
                    v-bind="props"
                    :title="item.raw.bankAccount"
                    :subtitle="item.raw.walletType"
                  ></v-list-item>
                </template>

              </v-autocomplete>
            </v-sheet>
          </v-sheet>

        </v-col>

        <v-col cols="12" md="9"></v-col>

        <v-col cols="12" md="3">
          <p class="mb-3 text-h5"> Wallet Account </p>
          <v-text-field
            readonly
            v-model.number="form.name"
            rounded="xl"
            placeholder="Enter Wallet Account"
            variant="outlined"
            density="comfortable"
            hide-details
            color="primary"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <p class="mb-3 text-h5"> Currency </p>
          <v-btn height="50" rounded="xl" width="45%" class="mx-1" variant="tonal" color="">
            USD
          </v-btn>
          <!--            <v-btn height="50" rounded="xl" width="45%" class="mx-1" variant="outlined" color="" disabled>-->
          <!--              SGD-->
          <!--            </v-btn>-->
        </v-col>

        <v-col cols="12" md="6"></v-col>

        <v-col cols="12" md="3">
          <p class="mb-3 text-h5"> Name </p>
          <v-text-field
            readonly
            v-model.number="form.name"
            rounded="xl"
            placeholder="Enter Name"
            variant="outlined"
            density="comfortable"
            hide-details
            color="primary"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <p class="mb-3 text-h5"> Deposit Amount </p>
          <v-text-field
            type="number"
            v-model.number="form.deposit_amount"
            rounded="xl"
            placeholder="Enter Deposit Amount"
            variant="outlined"
            density="comfortable"
            hide-details
            color="primary"
          ></v-text-field>
          <v-chip size="small" color="blue" class="mt-2">
            Fee {{ form.fee }} USD
          </v-chip>

        </v-col>

        <v-col cols="12" md="6"></v-col>

        <v-col cols="12" md="3">
          <p class="mb-3 text-h5"> Purpose </p>
          <v-text-field
            v-model="form.purpose"
            rounded="xl"
            placeholder="For Purpose"
            variant="outlined"
            density="comfortable"
            hide-details
            color="primary"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <p class="mb-3 text-h5">Amount to received </p>

          <v-card height="50" border variant="outlined" color="primary" rounded="xl">
            <div class="h1 pt-2 px-4">
              <h2>{{ form.amount_received }} USD</h2>
            </div>
          </v-card>
        </v-col>

      </v-row>

      <br />

      <v-btn
        class="text-capitalize"
        color="blue-darken-3"
        rounded="xl"
        size="large"
        variant="tonal"
        width="140"
        @click="investNow()"
        :loading="loading"
      >
        Deposit
      </v-btn>

      <br /><br />

      <v-col cols="12" md="5">
        <v-card class="pa-5" rounded="xl">
          <v-chip color="grey" variant="flat" size="small">Prompt</v-chip>
          <br />
          <p>
            <v-icon>mdi-circle-small</v-icon>
            Minimum Deposit
          </p>
          <p>
            <v-icon>mdi-circle-small</v-icon>
            Fee 0%
          </p>
          <p>
            <v-icon>mdi-circle-small</v-icon>
            Max sure receiving bank account info is correct
          </p>
          <p>
            <v-icon>mdi-circle-small</v-icon>
            The deposit time 24h available
          </p>
          <p>
            <v-icon>mdi-circle-small</v-icon>
            The deposit amount will arrive 30 minute
          </p>
          <p>
            <v-icon>mdi-circle-small</v-icon>
            More details please kindly contact our online operation
          </p>
        </v-card>
      </v-col>

    </v-card>
  </div>

  <template v-if="isErrorDialog">
    <ErrorDialog :dialog="isErrorDialog" :message="isErrorMessage" :onCloseModal="onCloseModalError" />
  </template>

  <template v-if="isSuccessDialog">
    <SuccessModel :dialog="isSuccessDialog" :message="isSuccessMessage" :onCloseModal="onCloseModalSuccess" />
  </template>
</template>

<script>
import { mapState } from 'vuex';
import { CUSTOMER_DEPOSIT } from '@/utils/customerApiUrl';
import ErrorDialog from '@/views/client/components/dialog/ErrorDialog.vue';
import SuccessModel from '@/views/client/components/dialog/SuccessModel.vue';

export default {
  name: 'DepositTab',
  components: { SuccessModel, ErrorDialog },
  computed: {
    ...mapState(['wallet', 'profile']),
  },
  data() {
    return {
      walletAccount: null,
      form: {
        deposit_amount: 0,
        deposit_amount_currency: 0,
        tax_fee: 0,
        fee: 0,
        feePercent: 2.5,
        amount_dollar: 0,
        amount_received: 0,
        purpose: '',
        status: 'pending',
        bank_account_deposit_id: 1,
        name: '',
      },
      loading: false,
      // modal message
      isErrorDialog: false,
      isErrorMessage: '',
      // modal success
      isSuccessDialog: false,
      isSuccessMessage: '',
    };
  },
  methods: {
    onCloseModalError(dialog) {
      this.isErrorDialog = dialog;
    },

    onCloseModalSuccess(dialog) {
      this.isSuccessDialog = dialog;
    },

    async investNow() {

      if (this.profile.status.toLowerCase() !== 'done') {
        this.isErrorMessage = 'Your account is not complete document. Please submit your document.';
        this.isErrorDialog = true;
        return;
      }

      this.loading = true;
      let form = {
        ...this.form,
      };
      await this.axios
        .post(CUSTOMER_DEPOSIT, form)
        .then((response) => {
          //console.log("response to", response.data);
          setTimeout(() => {
            this.loading = false;

            this.form = {
              deposit_amount: 0,
              deposit_amount_currency: 0,
              tax_fee: 0,
              fee: 0,
              feePercent: 2.5,
              amount_dollar: 0,
              amount_received: 0,
              purpose: '',
              status: 'pending',
              bank_account_deposit_id: 1,
            };
            this.walletAccount = '';

            this.isSuccessMessage = response.data.message;
            this.isSuccessDialog = true;
          }, 2000);

          setTimeout(() => {
            this.isSuccessDialog = false;
          }, 5000);
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.isErrorMessage = message;
          if (Array.isArray(message)) {
            this.isErrorMessage = message[0];
          }
          this.isErrorDialog = true;
          this.loading = false;
          //console.error("Failed to", error.response.data.message);
        });
    },
  },

  created() {
    if (this.profile) {
      this.form.name = this.profile.name;
    }
  },
  watch: {
    'form.deposit_amount'(amount) {
      if (amount !== '') {
        this.form.deposit_amount_currency = amount;
        let totalFee = amount * this.form.feePercent / 100;
        this.form.amount_dollar = amount - totalFee;
        this.form.amount_received = amount - totalFee;
        this.form.fee = totalFee;
      }
    },
  },
};
</script>

<style scoped>

</style>