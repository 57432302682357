<template>
    <MainLayout>
  
      <FormDialogueInputData></FormDialogueInputData>
      
    </MainLayout>
  </template>
  
  <script>
      import MainLayout from "../../../components/MainLayout.vue";
      import FormDialogueInputData from "@/components/staff/StaffComponent.vue";
  
      export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "User",
        components: { MainLayout, FormDialogueInputData },
        data: () => ({
            staffs: [],
            page: 1,
        }),
      };
  </script>
  
  <style scoped></style>
  