<template>
  <FrontendLayout>
    <div class="bg-grey-lighten-4">
      <div class="margin-auto">
        <br />
        <div>
          <h2 class="text-uppercase text-primary">All Events</h2>
        </div>
        <br />
      </div>

      <VCard class="bg-primary" min-height="140" rounded="0">
        <div class="margin-auto fontSize">
          <VRow class="my-5">
            <VCol cols="12" md="3">
              <p>Sector</p>
              <v-autocomplete
                rounded="xl"
                placeholder="Status"
                density="compact"
                variant="solo"
                v-model="form.sectorId"
                :items="sectors"
                :item-title="'name'"
                :item-value="'id'"
                menu-icon="mdi-chevron-right"
              >
              </v-autocomplete>
            </VCol>
            <VCol cols="12" md="3">
              <p>Type</p>
              <v-autocomplete
                rounded="xl"
                placeholder="Event"
                density="compact"
                variant="solo"
                v-model.trim="form.eventType"
                :items="typeItems"
                menu-icon="mdi-chevron-right"
              >
              </v-autocomplete>
            </VCol>
            <VCol cols="12" md="3">
              <p>Slide</p>
              <v-autocomplete
                rounded="xl"
                placeholder="Slide"
                density="compact"
                variant="solo"
                v-model.trim="form.eventSlide"
                :items="slideItems"
                menu-icon="mdi-chevron-right"
              >
              </v-autocomplete>
            </VCol>
            <VCol cols="12" md="3">
              <v-row>
                <v-col cols="12" md="10">
                  <p>Search</p>
                  <v-text-field
                    rounded="xl"
                    placeholder="Search"
                    v-model.trim="form.textSearch"
                    density="compact"
                    variant="solo"
                    menu-icon="mdi-chevron-right"
                  />
                </v-col>
                <v-col cols="auto" md="2">
                  <p>&nbsp;</p>
                  <v-btn
                    size="x-large"
                    rounded="xl"
                    density="compact"
                    @click="onSearch()"
                  >
                    <v-icon icon="mdi-magnify-expand" size="x-large"></v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <!--
              <VBtn
                rounded="xl"
                size="large"
                block
                variant="outlined"
                @click="onSearch()"
                class="text-none"
              >
                Search
              </VBtn> -->
            </VCol>

            <!--
            <VCol cols="12" md="4">
              <VBtn
                rounded="xl"
                size="large"
                block
                variant="outlined"
                @click="onSearch()"
                class="text-none"
              >
                Filter
              </VBtn>
            </VCol>
            -->
          </VRow>
          <!--
          <v-row class="my-5">
            <VCol cols="12" md="8">
              <v-text-field
                rounded="xl"
                placeholder="Search"
                density="compact"
                variant="solo"
                menu-icon="mdi-chevron-right"
              />
            </VCol>

            <VCol cols="12" md="4">
              <VBtn
                rounded="xl"
                size="large"
                block
                variant="outlined"
                @click="onSearch()"
                class="text-none"
              >
                Search
              </VBtn>
            </VCol>
          </v-row>
          -->
        </div>
      </VCard>

      <div class="margin-auto">
        <br /><br />
        <p class="fontSize">{{ paginationItemCount }}</p>
        <br />

        <div v-if="projectEvents.length > 0">
          <div v-for="event in projectEvents" v-bind:key="event.id">
            <v-divider />
            <br />
            <p class="text-content font-weight-bold fontSize opacity-75">
              {{ formattedDate(event.createdAt) }}
            </p>
            <router-link
              to="/events-details"
              class="text-decoration-none text-white fontSize"
              @click="goToProjectEventDetailPage(event)"
            >
              <p
                class="text-content font-weight-bold text-justify py-2"
                style="font-size: 1.1rem"
                v-html="truncateDescription(event.desc1)"
              ></p>
            </router-link>
            <p
              class="text-content text-justify fontSize"
              v-html="truncateDescription(event.desc2)"
            ></p>
            <br />
            <v-row>
              <v-col cols="12" md="4">
                <span class="text-content font-weight-bold fontSize">Date: </span>
                <span class="text-content fontSize">{{
                  formattedDate(event.createdAt)
                }}</span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="text-content font-weight-bold fontSize">Type: </span>
                <span class="text-content fontSize">{{ event.filterType }}</span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="text-content font-weight-bold fontSize">Sector: </span>
                <span class="text-content fontSize">{{ event.sector.name }}</span>
              </v-col>
            </v-row>
            <br /><br />
          </div>
        </div>
        <div v-else>
          <v-alert
            class="fontSize"
            border="end"
            border-color="warning"
            elevation="2"
            color="error"
          >
            No items available
          </v-alert>
        </div>

        <br />
        <br />
        <div class="text-center">
          <v-pagination
            v-model="page"
            class="fontSize"
            :length="pageCount"
            rounded="circle"
            :total-visible="7"
          ></v-pagination>
        </div>
        <!--
        <div class="d-flex align-content-start flex-wrap">
          
          <VBtn
            width="100"
            size=""
            rounded="xl"
            class="ma-2 text-none"
            variant="tonal"
          >
            First
          </VBtn>

          <VBtn
            width="100"
            size=""
            rounded="xl"
            class="ma-2 text-none"
            variant="tonal"
          >
            Previous
          </VBtn>

          <v-pagination
            v-model="form.page"
            :length="6"
            rounded="circle"
            prev-icon=""
            next-icon=""
          ></v-pagination>

          <VBtn
            width="100"
            size=""
            rounded="xl"
            class="ma-2 text-none"
            variant="tonal"
          >
            Next
          </VBtn>

          <VBtn
            width="100"
            size=""
            rounded="xl"
            class="ma-2 text-none"
            variant="tonal"
          >
            Last
          </VBtn>
          
        </div>-->
      </div>

      <br />
      <br />
    </div>
  </FrontendLayout>
</template>

<script>
import axios from "axios";
import FrontendLayout from "../components/FrontendLayout";
import { URL_FILTER_PROJECT_EVENT, URL_SECTOR } from "@/utils/apiUrl";
import { useProjectEventStore } from "@/store/recordFrontendStore";
import { dateFormatted } from "@/utils/formatNumber";

export default {
  name: "EventsPage",
  components: {
    FrontendLayout,
  },
  data() {
    return {
      slideItems: ["-- All --", "Video", "Slide", "News", "Event", "Story"],
      typeItems: ["-- All --", "Event"],
      sectors: [{ id: 0, name: "-- All --" }],
      projectEvents: [],

      form: {
        sectorId: 0,
        eventType: null,
        eventSlide: null,
        textSearch: null,
      },

      // pagination
      paginationItemCount: 0,
      page: 1,
      perPage: 10,
      pageCount: 0,
    };
  },
  watch: {
    // Watch for page change
    page(newPage) {
      this.filterProjectEvent(newPage);
    },
  },
  created() {
    this.loadSectors();
    this.filterProjectEvent(this.page);
  },
  methods: {
    formattedDate(dateTime) {
      return dateFormatted(dateTime);
    },

    truncateDescription(desc) {
      if (desc) {
        return desc.length > 500 ? desc.substring(0, 500) + "..." : desc;
      }
    },

    async loadSectors() {
      try {
        const response = await axios.get(URL_SECTOR);
        response.data.forEach((sector) => {
          this.sectors.push({
            id: sector.id,
            name: sector.name,
          });
        });
      } catch (error) {
        console.error("Error fetching sectors:", error);
      }
    },

    async filterProjectEvent(page) {
      try {
        const params = {
          page,
          perPage: this.perPage,
          sectorId: this.form.sectorId ? this.form.sectorId : 0,
          eventType: this.form.eventType !== "-- All --" ? this.form.eventType : "null",
          eventSlide:
            this.form.eventSlide !== "-- All --" ? this.form.eventSlide : "null",
          textSearch: this.form.textSearch ? this.form.textSearch : "null",
        };

        const response = await axios.get(URL_FILTER_PROJECT_EVENT, { params });

        this.projectEvents = response.data.projectEvents;
        this.pageCount = response.data.pageCount;
        this.paginationItemCount = `Showing ${this.page} - ${this.perPage} of ${this.projectEvents.length} results`;
      } catch (error) {
        console.error("Error fetching projects event:", error);
      }
    },

    goToProjectEventDetailPage(eventEntity) {
      var stateProjectEvent = useProjectEventStore();
      stateProjectEvent.stateProjectEventEntity(eventEntity);
    },

    onSearch() {
      this.filterProjectEvent(this.page);
    },
  },
};
</script>

<style scoped>
.fontSize {
  font-size: 0.875rem;
  line-height: 25px;
}
</style>
