<script>
import { RETURN_INTEREST_TRANSACTION } from '@/utils/customerApiUrl';
import { formatPrice, getParams, formatDate } from '@/utils/function';
import ProjectSelect from '@/views/client/projects/components/ProjectSelect.vue';
import SearchInvestSelect from '@/views/client/profile/transactions/components/SearchInvestSelect.vue';
import moment from 'moment';

export default {
  name: 'FundTransfers',
  components: { SearchInvestSelect, ProjectSelect },
  data() {
    return {
      formatDate,
      formatPrice,
      dateRange: null,
      params: {
        take: 10,
        skip: 1,
        projectId: '',
        startDate: '',
        endDate: '',
        search: '',
        invest_no: '',
        is_crowdfunding: '',
      },
      loading: false,
      totalPages: 0,
      total: 0,
      data: [],
      headers: [
        { align: 'start', key: 'id', sortable: false, title: 'Invest No' },
        { key: 'contrast', title: 'Date received' },
        { align: 'start', key: 'id', sortable: false, title: 'Project ID' },
        { key: 'interest', title: 'Interest (%)' },
        { key: 'star_rank', title: 'Star rank (%)' },
        { key: 'type', title: 'Type return ' },
        { key: 'interest_profit', title: 'Interest received' },
        { key: 'is_crowdfunding', title: 'Project Type' },
      ],
    };
  },
  methods: {

    async fetchData() {
      this.loading = true;
      let params = getParams(this.params);
      await this.axios
        .get(RETURN_INTEREST_TRANSACTION + params)
        .then((response) => {
          let { data, total, totalPages } = response.data;
          ///console.log(response.data.data);
          this.data = data;
          this.total = total;
          this.totalPages = totalPages;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error('Failed to', error);
        });
    },

    callbackProject(project) {
      if (project) {
        this.params.projectId = project.id;
        this.fetchData();
      }
    },

    callbackInvest(invest) {
      if (invest) {
        this.params.invest_no = invest.invest_no;
        this.fetchData();
      }
    },
  },
  created() {
    this.fetchData();
  },
  watch: {
    // filter page
    'params.skip': function() {
      this.fetchData();
    },
    'params.take': function() {
      this.fetchData();
    },
    'params.search': function(search) {
      this.params.search = search;
      this.fetchData();
    },
    'params.is_crowdfunding': function(is_crowdfunding) {
      if (is_crowdfunding.includes('All')) {
        this.params.is_crowdfunding = '';
      }
      this.fetchData();
    },
    'dateRange': function(dateRange) {
      if (dateRange && dateRange.length > 0) {
        let startDate = dateRange[0];
        let endDate = dateRange[dateRange.length - 1];
        this.params.startDate = moment(startDate).format('YYYY-MM-DD');
        this.params.endDate = moment(endDate).format('YYYY-MM-DD');
        this.fetchData();
      }
    },
  },
};
</script>

<template>
  <h3>Return Interest fund</h3>
  <br />
<!--        <pre>-->
<!--            {{ data[0] }}-->
<!--          </pre>-->
  <v-card class="pa-5" rounded="0" min-height="140" elevation="0" border>
    <v-row>
      <v-col cols="12" md="3">
        <p class="ma-2">Choose Date Range</p>

        <div>
          <v-date-input
            prepend-icon=""
            v-model="dateRange"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            class="elevation-0"
            append-inner-icon="mdi-calendar-range"
            placeholder="YYYY/MM/DD - YYYY/MM/DD"
            color="blue"
            multiple="range"
          ></v-date-input>
        </div>

      </v-col>

      <v-col cols="12" md="3">
        <ProjectSelect
          :callback-project="callbackProject"
          is-density="comfortable"
          is-title="Select Project ID"
          is-variant="outlined"
          :propertyAll="false"
        />
      </v-col>

      <v-col cols="12" md="3">
        <SearchInvestSelect
          :callback-invest="callbackInvest"
          is-density="comfortable"
          is-title="Select Invest No"
          is-variant="outlined" />
      </v-col>

      <v-col cols="12" md="3">
        <p class="ma-2">Project Type</p>
        <v-autocomplete
          menu-icon="mdi-chevron-right"
          v-model="params.is_crowdfunding"
          density="comfortable"
          rounded="xl"
          variant="outlined"
          :items="[
              'All',
              'Normal',
              'Crowdfunding',
            ]"
          class="elevation-0"
          color="blue"
        ></v-autocomplete>
      </v-col>

    </v-row>
  </v-card>

  <br />

  <v-row>
    <v-col cols="12" md="2">
      <div class="d-flex justify-start">
        <span class="ma-2">Show</span>

        <div>
          <v-select
            menu-icon=""
            v-model="params.take"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            :items="['10', '25', '50', '100', '500', '1000',]"
            class="elevation-0"
            color="blue"
          ></v-select>
        </div>

        <span class="ma-2">Entries</span>
      </div>
    </v-col>
    <v-col cols="12" md="10">
      <div class="d-flex justify-end">
        <span class="ma-2">Search</span>

        <div style="width: 30%">
          <v-text-field
            placeholder="search ..."
            append-inner-icon="mdi-magnify"
            width="100%"
            menu-icon=""
            v-model="params.search"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            class="elevation-0"
            color="blue"
          ></v-text-field>
        </div>
      </div>
    </v-col>
  </v-row>

  <v-card rounded="0">
    <v-data-table
      density="comfortable"
      hide-default-footer
      :loading="loading"
    >
      <v-card elevation="0" rounded="0" class="pa-5" v-if="data.length < 1">
        No record available.
      </v-card>

      <thead>
      <tr class="bg-primary">
        <th v-for="row in headers" v-bind:key="row.key" class="row-none-wrap text-capitalize">
          {{ row.title }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="row in data" v-bind:key="row.id" class="row-none-wrap">
        <td>#{{ row.Invest.invest_no }}</td>
        <td>{{ formatDate(row.return_date, 'YYYY-MM-DD HH:mm A') }}</td>
        <td>{{ row.Invest.Project.project_id }}</td>
        <td>{{ formatPrice(row.interest) }}%</td>
        <td>{{ formatPrice(row.level_percent) }}%</td>
        <td>{{ row.interest_name }}</td>
        <td>$ {{ formatPrice(row.profit) }}</td>
        <td>{{ row.is_crowdfunding }}</td>
      </tr>
      </tbody>
    </v-data-table>
  </v-card>

  <div class="app-paginate mt-5">
    <v-pagination
      v-model="params.skip"
      :length="totalPages"
      class="d-flex align-center"
      rounded="circle"
      color="primary"
      border
      total-visible="10"
    >
      <template #prev>
        <div class="d-flex">
          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip=1"
            :disabled="1 === totalPages"
            variant="outlined"
            border
            color="primary"
          >
            First
          </VBtn>

          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip--"
            :disabled="params.skip === 1"
            variant="outlined"
            border
            color="primary"
          >
            Previous
          </VBtn>
        </div>
      </template>
      <template #next>
        <div class="d-flex">
          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip++"
            :disabled="params.skip === totalPages"
            variant="outlined"
            border
            color="primary"
          >
            Next
          </VBtn>

          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip = totalPages"
            :disabled="params.skip === totalPages"
            variant="outlined"
            border
            color="primary"
          >
            Last
          </VBtn>
        </div>
      </template>
    </v-pagination>
  </div>

</template>

<style scoped>

</style>
