import axios from "axios";
import {BASE_URL} from "@/utils/customerApiUrl";

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json'
    },
});

// Function to set the Authorization token dynamically
export const setAuthToken = (token) => {
    if (token) {
        axiosInstance.defaults.headers['Authorization'] = `Bearer ${token}`;
        localStorage.setItem('client_token', token); // Store token persistently
        localStorage.setItem('token', token);
    } else {
        delete axiosInstance.defaults.headers['Authorization'];
        localStorage.removeItem('client_token'); // Remove token when logging out
    }
};

// Automatically attach token if available on startup
const savedToken = localStorage.getItem('client_token');
if (savedToken) {
    setAuthToken(savedToken);
}

// Interceptor to handle token expiration (401 response)
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
      if (error.response && error.response.status === 401) {
          setAuthToken(null); // Clear token on unauthorized response
          window.location.href = '/sign-in'; // Redirect to login page
      }
      return Promise.reject(error);
  }
);

export default axiosInstance;