<script>
import FrontendLayout from "@/views/client/components/FrontendLayout.vue";
import { useProjectEventStore } from "@/store/recordFrontendStore";
import { dateFormatted } from "@/utils/formatNumber";
import { Path_Project_Event_Image } from "@/utils/apiUrl";

export default {
  name: "EventsDetailsPage",
  components: {
    FrontendLayout,
  },
  data() {
    return {
      path: Path_Project_Event_Image,
      event: null,
    };
  },
  created() {
    var stateProjectEvent = useProjectEventStore();
    this.event = stateProjectEvent.entity || null;
  },
  methods: {
    formatedDate(value) {
      return dateFormatted(value);
    },
  },
};
</script>

<template>
  <FrontendLayout>
    <div class="bg-grey-lighten-4">
      <div class="margin-auto">
        <br />
        <div>
          <h4 class="text-uppercase text-grey">Events</h4>

          <br /><br />

          <h3 style="font-size: 1.2rem" v-html="event.desc1"></h3>

          <br />

          <p class="font-format">{{ formatedDate(event.createdAt) }}</p>

          <!--<p>Phnom Penh, Cambodia</p>-->

          <br />
          <div v-show="event.map || event.desc1">
            <div style="height: 700px; background-color: gainsboro">
              <v-img
                style="height: 700px; width: 100%; background-color: gainsboro"
                cover
                :src="
                  event.map !== null
                    ? path + event.map
                    : 'https://assets-v2.lottiefiles.com/a/d0c1a50a-1171-11ee-bc55-77e80401811a/LgJcg4Igt2.gif'
                "
              >
                <template v-slot:placeholder>
                  <v-row align="center" class="fill-height ma-0" justify="center">
                    <v-progress-circular
                      color="grey-lighten-5"
                      indeterminate
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>
            <!--
          <v-img
            min-height="720"
            width="100%"
            cover
            src="https://www.worldbank.org/content/dam/photos/780x439/2023/apr/SAEF-Economic-Mobility-spring-2023-03-Chp-3-780x439-02.jpg"
          >
          </v-img> -->

            <br />
            <p class="text-justify" v-html="event.desc1"></p>

            <br /><br />
          </div>
          <!--
          <h2>Details</h2>

          <h3>
            <p>
              Topic:
              <span class="font-weight-regular"
                >Social Progress in South East Asia</span
              >
            </p>
          </h3>
          
          <h3>
            <p>
              Day 1: <span class="font-weight-regular">January 01, 2023</span>
            </p>
          </h3>

          <h3>
            <p>
              Day 2: <span class="font-weight-regular">January 01, 2023</span>
            </p>
          </h3>

          <h3>
            <p>
              Location:
              <span class="font-weight-regular"
                >Diamond Hotel, Phnom Penh, Cambodia</span
              >
            </p>
          </h3>

          <br /><br />
          -->
          <div v-show="event.linkYoutube || event.desc2">
            <iframe
              width="100%"
              height="600"
              :src="
                event.linkYoutube !== null
                  ? event.linkYoutube
                  : 'https://www.youtube.com/embed/dQw4w9WgXcQ'
              "
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            >
            </iframe>

            <br /><br />

            <p class="text-justify" v-html="event.desc2"></p>

            <br /><br />
          </div>
          <div
            style="height: 700px; background-color: gainsboro"
            v-show="event.image || event.desc3"
          >
            <v-img
              style="height: 700px; width: 100%; background-color: gainsboro"
              contain
              :src="
                event.image !== null
                  ? path + event.image
                  : 'https://assets-v2.lottiefiles.com/a/d0c1a50a-1171-11ee-bc55-77e80401811a/LgJcg4Igt2.gif'
              "
            >
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular
                    color="grey-lighten-5"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <p class="text-justify py-5 pa-3 font-format" v-html="event.desc3"></p>
          </div>
        </div>
        <br />
      </div>
    </div>
  </FrontendLayout>
</template>

<style scoped>
.font-format {
  font-size: 0.875rem;
  line-height: 25px;
}
</style>
