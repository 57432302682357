<template>
  <div>
    <v-card rounded="0" class="px-10 pa-3">
      <v-row>
        <v-col cols="6" sm="6" md="2">
          <img class="mt-2" width="215px" height="40px" :src="state.logo" alt="" />
        </v-col>

        <v-col cols="12" sm="12" md="8" class="hidden"></v-col>

        <v-col cols="6" sm="6" md="2">
          <div class="d-flex justify-end">
            <v-btn variant="text" icon="mdi-close" @click="handleBack"></v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <div class="margin-center">
      <br />
      <br />
      <h2 class="text-center">MIGA Monthly Newsletter</h2>
      <br />
      <p class="text-left">
        <span>
          Subscribe to our monthly newsletter and keep informed of MIGA's latest news,
          feature stories, projects, events and other updates.
        </span>
      </p>
      <br />
      <v-alert
        border="end"
        border-color="warning"
        elevation="2"
        color="error"
        class="mb-3 font-format"
        v-show="state.error"
      >
        {{ state.message }}
      </v-alert>
      <p class="mb-3 font-format">Please fill out the following information:</p>
      <v-form ref="formLogin">
        <strong class="mb-3 font-format"
          >Email <span class="text-error subscript">*</span></strong
        >
        <v-text-field
          v-model="state.form.email"
          variant="outlined"
          density="compact"
          autocomplete="off"
          placeholder="Enter your email address"
          v-on:keyup="handleChange"
          type="email"
          :rules="nameRules"
        />
        <strong class="mb-3 font-format">Name</strong>
        <v-text-field
          v-model="state.form.name"
          variant="outlined"
          density="compact"
          autocomplete="off"
          placeholder="Enter your name"
        />
        <strong class="mb-3 font-format">Occupation</strong>
        <v-autocomplete
          v-model="state.form.job"
          :items="state.jobs"
          variant="outlined"
          density="compact"
          required
          persistent-placeholder
          placeholder="Choose occupation..."
          return-object
        ></v-autocomplete>

        <strong class="mb-3 font-format">Country of Residence</strong>
        <v-autocomplete
          v-model="state.form.country"
          :items="state.countries"
          :item-title="'name'"
          :item-value="'id'"
          variant="outlined"
          density="compact"
          required
          persistent-placeholder
          placeholder="Choose country..."
          return-object
        ></v-autocomplete>
        <i class="mb-3 font-format"
          >I agree with the terms of the
          <span class="text-primary">Privacy Notice</span> and consent to my personal data
          being processed, to the extent necessary, to subcribe to my chosen
          newsletter.</i
        >
        <v-checkbox class="ml-0" v-model="state.form.status">
          <template v-slot:label>
            <span>I AGREE TO THE TERMS OF USE <span style="color: red">*</span></span>
          </template>
        </v-checkbox>

        <v-btn
          block
          rounded="xl"
          :disabled="state.isSubmitting"
          color="blue"
          elevation="0"
          @click="handleSubmit()"
        >
          <p class="text-capitalize">Subscribe</p>
        </v-btn>
      </v-form>

      <br />
      <br />

      <p class="text-center">
        By registering, you accept our
        <router-link
          class="text-decoration-none text-black text-decoration-underline"
          to="/"
        >
          Term of use
        </router-link>
        and
        <router-link
          class="text-decoration-none text-black text-decoration-underline"
          to="/"
        >
          Privacy policy
        </router-link>
      </p>
    </div>
  </div>
</template>

<script setup>
import { URL_COUNTRY, URL_SUBSCRIBE } from "@/utils/apiUrl";
import { TOKEN_HEADERS } from "@/utils/headerToken";
import axios from "axios";
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const state = reactive({
  logo: null,
  visible: false,
  error: false,
  isSubmitting: false,

  form: {
    email: null,
    name: null,
    job: null,
    country: null,
    status: "active",
  },

  jobs: [],
  countries: [],

  message: "Something went wrong.",
});

const nameRules = [
  (value) => {
    if (value) return true;
    return "Email is required.";
  },
  (value) => {
    if (value.length > 0) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid Email address";
    }
  },
];

/*
const nameRulesStatus = [
  (value) => {
    if (value) return true;
    return "Please agree with us to subcribe.";
  },
]; */

const handleChange = (event) => {
  state.form.email = event.target.value;
  state.error = false;
};

const handleBack = () => {
  router.go(-1);
};

const subscribe = async (payload) => {
  if (state.form.email === null) {
    state.error = true;
    state.message = "Email is required. " + payload.email;
    return;
  }
  //alert(state.form.status);
  alert(JSON.stringify(payload));

  await axios
    .patch(URL_SUBSCRIBE, payload, { headers: TOKEN_HEADERS })
    .then((response) => {
      this.snakeMessage("success", response.data.message);
    })
    .catch(
      (error) => {
        state.error = false;

        if (error.response.data.statusCode === 400) {
          //this.snakeMessage("error", error.response.data.message);
          state.message = error.response.data.message;
        } else if (error.request) {
          // The request was made but no response was received
          //this.snakeMessage("error", error.request);
          state.message = error.request;
          // Something happened in setting up the request that triggered an Error
          //this.snakeMessage("error", error.message);
          state.message = error.message;
        }

        return false;
      }
      //console.error("record updated was an error!", error)
    );
};

const handleSubmit = async () => {
  let payload = {
    ...state.form,
    country: state.form.country.name,
    status: state.form.status == true ? "active" : "inactive",
  };

  if (state.isSubmitting) return; // Prevent multiple submissions
  state.isSubmitting = true;
  try {
    // Send your form data to the server
    await subscribe(payload);
  } catch (error) {
    console.error(error);
  } finally {
    state.isSubmitting = false; // Re-enable the button after the request
  }
};

const loadJobFromJsonFile = async () => {
  try {
    const response = await fetch("/assets/job.json");
    state.jobs = await response.json();
  } catch (error) {
    console.error("Error loading options:", error);
  }
};

const populateCountry = async () => {
  try {
    const response = await axios.get(URL_COUNTRY);
    response.data.forEach((country) => {
      state.countries.push({
        id: country.id,
        name: country.name,
      });
    });
  } catch (error) {
    console.error("Error loading options:", error);
  }
};

onMounted(() => {
  state.logo = localStorage.getItem("logo") ?? null;
  loadJobFromJsonFile();
  populateCountry();
  state.form = {
    email: null,
    name: null,
    job: null,
    country: null,
  };
});
</script>

<style scoped>
.font-format {
  font-size: 0.875rem;
  line-height: 25px;
}

.subscript {
  font-size: 0.875rem;
}
</style>
