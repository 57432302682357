<template>
  <v-card flat>
    <template v-slot:text>
      <v-text-field
        v-model="search"
        label="Search country..."
        prepend-inner-icon="mdi-magnify"
        variant="outlined"
        hide-details
        single-line
        clearable
      ></v-text-field>
    </template>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor" location="top">
      {{ snackText }}
    </v-snackbar>
    <v-data-table
      color="#b2d7ef"
      rounded="compact"
      divided
      striped
      hover
      class="elevation-1"
      fixed-header
      :search="search"
      :headers="headers"
      :items="countries"
      :items-per-page="itemsPerPage"
      v-model:page="page"
      :sort-by="[{ key: 'createdAt', order: 'desc' }]"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ props }">
            <v-row>
              <v-col cols="6" md="6" lg="6" xl="6" class="d-flex align-center mb-4">
                <v-btn
                  class="mb-2 ml-4"
                  color="primary"
                  v-bind="props"
                  prepend-icon="mdi-plus"
                  variant="flat"
                >
                  <div class="text-none font-weight-regular">Add</div>
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="text-right d-flex mb-6 flex-row-reverse"
              >
                <v-sheet class="ma-2 pa-2 text-center">
                  <v-select
                    v-model="itemsPerPage"
                    :options="[10, 25, 50, 100, 1000]"
                    hide-details
                    density="compact"
                    variant="outlined"
                    @update:model-value="itemsPerPage = parseInt($event, 10)"
                    style="width: 100px"
                  ></v-select>
                </v-sheet>
                <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-toolbar>
              <v-toolbar-title prepend-icon="mdi-form-textbox">{{
                formTitle
              }}</v-toolbar-title>

              <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
            </v-toolbar>
            <v-divider class="mb-0"></v-divider>
            <v-divider class="mb-0"></v-divider>
            <v-form ref="form" enctype="multipart/form-data" lazy-validation>
              <v-card-text>
                <v-alert
                  border="end"
                  border-color="warning"
                  elevation="2"
                  color="error"
                  v-show="!visible"
                >
                  {{ msgError }}
                </v-alert>
                <v-row class="mt-1">
                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <v-select
                          v-model="name"
                          :options="countriesApi"
                          :rules="[() => !!name || 'This field is required']"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-flag-outline"
                          required
                          autocomplete="off"
                          persistent-placeholder
                          placeholder="Choose customer..."
                          :reduce="(country) => country.code"
                          @update:modelValue="fetchCountryApiData"
                          clearable
                          :disabled="disabled"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-text-field
                          v-model.trim="abbr"
                          label="Abbr"
                          placeholder="Abbr"
                          :rules="[() => !!abbr || 'This field is required']"
                          variant="outlined"
                          prepend-inner-icon="mdi-flag"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          clearable
                          :disabled="disabled"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-text-field
                          v-model.trim="code"
                          :disabled="disabled"
                          label="Code"
                          placeholder="Code"
                          :rules="[() => !!code || 'This field is required']"
                          variant="outlined"
                          prepend-inner-icon="mdi-flag"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          @keypress="onlyNumber"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row> </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" sm="8" md="8" lg="8" xl="8">
                        <v-card class="mx-auto" subtitle="Currency">
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <input type="hidden" v-model="hiddenId" />
                                <input type="hidden" v-model="hdFlagCountryImage" />
                                <input
                                  type="hidden"
                                  v-model="hdFlagCountryImageCurrency"
                                />
                                <v-text-field
                                  v-model.trim="currency"
                                  :rules="[() => !!currency || 'This field is required']"
                                  :step="0.1"
                                  v-model="value"
                                  label="Currency"
                                  placeholder="Currency"
                                  variant="outlined"
                                  prepend-inner-icon="mdi-cash"
                                  required
                                  autocomplete="off"
                                  density="compact"
                                  clearable
                                  persistent-placeholder
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="5" md="5" lg="5" xl="5">
                                <v-text-field
                                  v-model.trim="short"
                                  :rules="[() => !!short || 'This field is required']"
                                  label="Short"
                                  placeholder="Short"
                                  variant="outlined"
                                  prepend-inner-icon="mdi-cash"
                                  required
                                  autocomplete="off"
                                  density="compact"
                                  clearable
                                  persistent-placeholder
                                  :disabled="disabled"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="5" md="5" lg="5" xl="5">
                                <v-text-field
                                  v-model.trim="symbol"
                                  :rules="[() => !!symbol || 'This field is required']"
                                  label="Symbol"
                                  placeholder="Symbol"
                                  variant="outlined"
                                  prepend-inner-icon="mdi-cash"
                                  required
                                  autocomplete="off"
                                  density="compact"
                                  clearable
                                  persistent-placeholder
                                  :disabled="disabled"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                                <v-switch
                                  v-model="active"
                                  :label="checkboxSwitchChange"
                                  false-value="0"
                                  true-value="1"
                                  hide-details
                                  color="primary"
                                ></v-switch>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>

                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-row>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-card class="mx-auto" subtitle="Flag" width="100%">
                              <v-card variant="variant" class="mx-auto" elevation="16">
                                <v-img
                                  :src="
                                    previewImage === null
                                      ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                                      : previewImage
                                  "
                                  height="165px"
                                  class="align-end"
                                  fixed
                                >
                                  <template v-slot:placeholder>
                                    <v-row
                                      align="center"
                                      class="fill-height ma-0"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        color="grey-lighten-5"
                                        indeterminate
                                      ></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-card>
                              <!--
                                <v-file-input
                                  v-model.trim="flagImg"
                                  accept="image/png, image/jpeg, image/bmp"
                                  label="Avatar"
                                  placeholder="Pick an avatar"
                                  prepend-icon="mdi-camera"
                                  @change="uploadImage"
                                  hide-input
                                  class="mt-2"
                                ></v-file-input> -->
                            </v-card>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-card class="mx-auto" subtitle="Currency" width="100%">
                              <v-card variant="variant" class="mx-auto" elevation="16">
                                <v-img
                                  :src="
                                    previewImageCurrency === null
                                      ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                                      : previewImageCurrency
                                  "
                                  height="165px"
                                  class="align-end"
                                  fixed
                                  @click="triggerFileInput"
                                >
                                  <template v-slot:placeholder>
                                    <v-row
                                      align="center"
                                      class="fill-height ma-0"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        color="grey-lighten-5"
                                        indeterminate
                                      ></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-card>

                              <v-file-input
                                v-model.trim="currencyImg"
                                accept="image/png, image/jpeg, image/bmp"
                                label="Avatar"
                                placeholder="Pick an avatar"
                                prepend-icon="mdi-camera"
                                @change="uploadImageCurrency"
                                hide-input
                                class="mt-2"
                                ref="fileInput"
                                style="display: none"
                              ></v-file-input>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
            </v-form>

            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                text="Cancel"
                @click="close"
                variant="flat"
                color="error"
              ></v-btn>

              <v-btn
                class="text-none"
                color="primary"
                rounded="xl"
                :text="buttonTitle"
                variant="flat"
                @click="save"
                :disabled="isSubmitting"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <input type="hidden" v-model="hiddenId" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- detect when error  -->
      <template v-slot:no-data>
        <v-alert
          v-if="items.length > 0"
          :value="true"
          color="error"
          icon="warning"
          class="text-left"
        >
          No data found.
        </v-alert>
      </template>
      <!-- end detect when error  -->
      <!-- Custom column table -->
      <template v-slot:[`item.image`]="{ item }">
        <div v-if="item.image" class="text-center">
          <v-avatar size="35" class="my-1">
            <v-img alt="Avatar" :src="item.image" cover></v-img>
          </v-avatar>
        </div>
        <div v-else class="text-center">
          <v-avatar size="35" class="my-1">
            <v-img
              alt="Avatar"
              src="https://png.pngtree.com/png-vector/20191023/ourmid/pngtree-flag-icon-png-image_1851706.jpg"
              cover
            >
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular
                    color="grey-lighten-5"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </div>
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <v-chip
          size="small"
          v-if="item.active === 1"
          prepend-icon="mdi-flag-outline"
          color="success"
        >
          {{ item.active === 1 ? "Active" : "Inactive" }}
        </v-chip>
        <v-chip size="small" v-else prepend-icon="mdi-flag-off-outline" color="error">
          {{ item.active === 0 ? "Inactive" : "Active" }}
        </v-chip>
      </template>
      <template v-slot:[`item.logoCurrency`]="{ item }">
        <div v-if="item.logoCurrency" class="text-center">
          <v-avatar size="35" class="my-1">
            <v-img
              alt="Avatar"
              :src="pathCurrencyImage + item.logoCurrency"
              cover
            ></v-img>
          </v-avatar>
        </div>
        <div v-else class="text-center">
          <v-avatar size="35" class="my-1">
            <v-img
              alt="Avatar"
              src="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
              cover
            >
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular
                    color="grey-lighten-5"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </div>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <v-icon icon="mdi-clipboard-text-clock"></v-icon>
        {{ new Date(item.createdAt).toLocaleString() }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="ma-2" size="x-small" color="red-lighten-2" @click="editItem(item)">
          <v-icon icon="mdi-pencil" start></v-icon>
          Edit
        </v-btn>
        <!--
        <v-icon title="Edit" class="me-2" size="small" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon title="Delete" size="small" @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            rounded="circle"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </div>
      </template>
      <!-- End custom column table -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import {
  Path_COUNTRY_Currency_Image,
  Path_Country_Flag_Image,
  URL_COUNTRY,
  URL_SETTING_COUNTRY,
  URL_UPLOAD_COUNTRY_CURRENCY_IMAGE,
  URL_UPLOAD_COUNTRY_FLAG_IMAGE,
} from "@/utils/apiUrl";
import { IMAGE_TOKEN_HEADERS, TOKEN_HEADERS } from "@/utils/headerToken";
import vSelect from "vue-select";
import { getFileExtension, getFileSizeInMB } from "@/utils/reusableFunctions";

export default {
  components: {
    vSelect,
  },
  data: () => ({
    pathCountryFlag: Path_Country_Flag_Image,
    pathCurrencyImage: Path_COUNTRY_Currency_Image,
    snack: false,
    dialog: false,
    dialogDelete: false,
    visible: true,
    isSubmitting: false,

    items: [],
    countries: [],
    countriesApi: [],
    validation: {
      min: 0,
      max: 10,
      decimal: 10,
    },

    search: null,
    country: null,
    snackColor: null,
    snackText: null,
    msgError: null,
    name: null,
    previewImage: null,
    previewImageCurrency: null,
    currency: null,
    short: null,
    symbol: null,
    abbr: null,
    code: null,
    active: "1",

    editedIndex: -1,
    hiddenId: 0,
    hdFlagCountryImage: null,
    hdFlagCountryImageCurrency: null,

    disabled: null,
    page: 1,
    itemsPerPage: 10,

    headers: [
      { title: "Actions", key: "actions", sortable: false, align: "center" },
      { title: "", key: "image", align: "center" },
      { title: " ", key: "logoCurrency", align: "center" },
      { title: "Status", key: "active", align: "center" },
      { title: "Country", key: "name", align: "center" },
      { title: "Currency", key: "currency", align: "center" },
      { title: "Code", key: "code", align: "center" },
      { title: "Short", key: "short", align: "center" },
      { title: "Symbol", key: "symbol", align: "center" },
      { title: "Abbr", key: "abbr", align: "center" },
      { title: "Created at", key: "createdAt", align: "center" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Country" : "Update Country";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
    checkboxSwitchChange() {
      return this.active === "1" ? "Active" : "Disactive";
    },
    pageCount() {
      return Math.ceil(this.countries.length / this.itemsPerPage);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.PopulateCountries();
    this.fetchCApiCountries();
  },
  methods: {
    clearContent() {
      this.hiddenId = 0;
      this.name = null;
      this.previewImage =
        "https://png.pngtree.com/png-vector/20191023/ourmid/pngtree-flag-icon-png-image_1851706.jpg";
      this.code = 0;
      this.currency = null;
      this.short = null;
      this.symbol = null;
      this.abbr = null;
      this.active = "1";
      this.previewImageCurrency =
        "https://bpcdn.co/images/2017/03/09071328/global-currency-symbols.jpeg";
      this.visible = true;
      this.disabled = null;
    },

    async fetchCApiCountries() {
      try {
        const response = await fetch("/assets/countries.json");
        const countriesJson = await response.json();

        this.countriesApi = [];
        this.countriesApi = countriesJson.map((item) => ({
          label: item.name,
          code: item.alpha2Code,
        }));

        // Add your custom entry
        this.countriesApi.push({
          label: "Cryptocurrency",
          code: "CRYPTO",
        });
        console.error(this.countriesApi);
      } catch (error) {
        console.error("Error loading options:", error);
      }

      /**
      try {
        const response = await axios.get("https://restcountries.com/v2/all");
        //this.countriesApi = response.data;
        //console.log(response.data);
        this.countriesApi = [];
        this.countriesApi = response.data.map((item) => ({
          label: item.name,
          code: item.alpha2Code,
        }));

        // Add your custom entry
        this.countriesApi.push({
          label: "Cryptocurrency",
          code: "CRYPTO",
        });
        console.error(this.countriesApi);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
         */
    },

    async fetchCountryApiData() {
      try {
        if (this.name === "CRYPTO") {
          this.country = "CryptoCoin";
          this.abbr = "CR";
          this.code = "CRYPTO";
          this.currency = "CryptoCoin";
          this.short = "CRC";
          this.symbol = "₿";
          this.previewImage =
            "https://i.etsystatic.com/11417580/r/il/c415f2/3543436563/il_570xN.3543436563_ts1z.jpg";
          this.name = "Cryptocurrency";
        } else {
          const response = await axios.get(
            `https://restcountries.com/v2/alpha/${this.name}`
          );

          this.country = response.data;
          this.abbr = response.data.alpha2Code;
          this.code = response.data.callingCodes[0];
          this.currency = response.data.currencies[0]?.name;
          this.short = response.data.currencies[0]?.code;
          this.symbol = response.data.currencies[0]?.symbol;
          this.previewImage = response.data.flag;
          this.name = response.data.name;
        }
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    },

    editItem(item) {
      this.editedIndex = this.countries.indexOf(item);
      this.disabled = 1;
      this.hiddenId = item.id;
      //this.editedItem = Object.assign({}, item);
      this.name = item.name;
      this.code = item.code;
      this.currency = item.currency;
      this.short = item.short;
      this.symbol = item.symbol;
      this.abbr = item.abbr;
      this.active = item.active.toString();
      this.previewImage = item.image;
      /**
      this.previewImage =
        item.image === null
          ? "https://png.pngtree.com/png-vector/20191023/ourmid/pngtree-flag-icon-png-image_1851706.jpg"
          : this.pathCountryFlag + item.image;  */
      this.previewImageCurrency =
        item.logoCurrency === null
          ? "https://bpcdn.co/images/2017/03/09071328/global-currency-symbols.jpeg"
          : this.pathCurrencyImage + item.logoCurrency;

      this.hdFlagCountryImage = item.image;
      this.hdFlagCountryImageCurrency = item.logoCurrency;

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.countries.indexOf(item);
      this.hiddenId = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //delete from arraylist
      this.countries.splice(this.editedIndex, 1);
      //delete from DB
      const id = this.hiddenId;
      const url = URL_SETTING_COUNTRY + id;
      axios
        .delete(url, { headers: TOKEN_HEADERS })
        .then(function (response) {
          this.snakeMessage("error", response.data.message);
          this.closeDelete();
        })
        .catch((error) => console.error("record deleted was an error!", error));

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    async submitData() {
      //const staffLoginId = localStorage.getItem("id");
      const data = {
        name: this.name,
        image: this.previewImage,
        code: this.code,
        currency: this.currency,
        short: this.short,
        symbol: this.symbol,
        logoCurrency: this.hdFlagCountryImageCurrency,
        abbr: this.abbr,
        active: parseInt(this.active),
      };

      if (this.editedIndex > -1) {
        // edit
        const id = this.hiddenId;
        const url = URL_SETTING_COUNTRY + id;

        await axios
          .patch(url, data, { headers: TOKEN_HEADERS })
          .then((response) => {
            this.snakeMessage("success", response.data.message);
            this.clearContent();
            this.dialog = false;
          })
          .catch(
            (error) => {
              this.visible = false;

              if (error.response.data.statusCode === 400) {
                //this.snakeMessage("error", error.response.data.message);
                this.msgError = error.response.data.message;
              } else if (error.request) {
                // The request was made but no response was received
                //this.snakeMessage("error", error.request);
                this.msgError = error.request;
                // Something happened in setting up the request that triggered an Error
                //this.snakeMessage("error", error.message);
                this.msgError = error.message;
              }

              this.dialog = true;
              return false;
            }
            //console.error("record updated was an error!", error)
          );

        //this.close();
      } // create new
      else {
        await axios
          .post(URL_SETTING_COUNTRY, data, { headers: TOKEN_HEADERS })
          .then((response) => {
            this.snakeMessage("success", response.data.message);
            this.clearContent();
            this.dialog = false;
          })
          .catch(
            (error) => {
              this.visible = false;

              if (error.response.data.statusCode === 400) {
                //this.snakeMessage("error", error.response.data.message);
                this.msgError = error.response.data.message;
              } else if (error.request) {
                // The request was made but no response was received
                //this.snakeMessage("error", error.request);
                this.msgError = error.request;
                // Something happened in setting up the request that triggered an Error
                //this.snakeMessage("error", error.message);
                this.msgError = error.message;
              }

              this.dialog = true;
            }
            //console.error("record updated was an error!", error)
          );
      }

      this.PopulateCountries();
    },

    async save() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        if (this.isSubmitting) return; // Prevent multiple submissions
        this.isSubmitting = true;

        try {
          // Send your form data to the server
          await this.submitData();
        } catch (error) {
          console.error(error);
        } finally {
          this.isSubmitting = false; // Re-enable the button after the request
        }
      }
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },

    async PopulateCountries() {
      axios.get(URL_COUNTRY, { headers: TOKEN_HEADERS }).then((response) => {
        this.countries = response.data;
        this.items.push(response.data);
      });
    },

    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
        console.log(this.previewImage);
      };

      let data = new FormData();
      data.append("file", e.target.files[0]);

      axios.post(URL_UPLOAD_COUNTRY_FLAG_IMAGE, data).then((response) => {
        this.hdFlagCountryImage = response.data.filename;
        console.log("Image has been uploaded.");
      });
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    uploadImageCurrency(e) {
      const file = e.target.files[0];
      if (file) {
        var obj = this.invalidFileSizeOrFileExtension(file);
        if (obj.isTrue) {
          this.visible = false;
          this.msgError = obj.msg;
          return;
        }

        this.visible = true;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.previewImageCurrency = e.target.result;
          console.log(this.previewImage);
        };

        let data = new FormData();
        data.append("file", e.target.files[0]);

        axios
          .post(URL_UPLOAD_COUNTRY_CURRENCY_IMAGE, data, {
            headers: IMAGE_TOKEN_HEADERS,
          })
          .then((response) => {
            this.hdFlagCountryImageCurrency = response.data.filename;
            console.log("Image has been uploaded.");
          });
      }
    },

    invalidFileSizeOrFileExtension(file) {
      let isTrue = false;
      let msg = null;
      const fileSize = getFileSizeInMB(file);
      const fileExtension = getFileExtension(file);

      if (fileSize >= 6) {
        // 6MB
        isTrue = true;
        msg = "File size is biggest 6MB.";
      }

      if (fileExtension === null) {
        isTrue = true;
        msg = "File extension must be jpg, jpeg, png, bmp";
      }

      return { isTrue, msg };
    },

    onlyNumber($event) {
      if ($event.key.length === 1 && isNaN(Number($event.key))) {
        $event.preventDefault();
      }
    },
  },
};
</script>
<style scoped>
@import "vue-select/dist/vue-select.css";

.row-container {
  overflow-y: auto;
}

style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  border: 1px solid #d1d5db;
  text-transform: lowercase;
  font-variant: small-caps;
  height: 55px;
  font-size: medium;
  color: #394066;
  max-height: 200px;
  overflow-y: auto;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
}
</style>
