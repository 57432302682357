<script>
import { Path_PROJECT_IMAGE } from "@/utils/apiUrl";

export default {
  props: ["events"],
  name: "CardRelated",
  data() {
    return {
      path: Path_PROJECT_IMAGE,
    };
  },
  methods: {
    truncateDescription(desc) {
      if (desc) {
        return desc.length > 38 ? desc.substring(0, 38) + "..." : desc;
      }
    },
  },
};
</script>

<template>
  <div>
    <!-- This loop, it gets record only 6 -->
    <v-card
      v-for="(item, index) in events"
      :key="index"
      rounded="xl"
      variant="outlined"
      class="my-5"
    >
      <div class="d-flex">
        <v-avatar rounded="0" size="180">
          <v-img
            :src="
              item.image === null
                ? 'https://media.istockphoto.com/id/1396814518/vector/image-coming-soon-no-photo-no-thumbnail-image-available-vector-illustration.jpg?s=612x612&w=0&k=20&c=hnh2OZgQGhf0b46-J2z7aHbIWwq8HNlSDaNp2wn_iko='
                : path + item.image_project
            "
          ></v-img>
        </v-avatar>

        <div class="pa-1">
          <v-card-title class="text-uppercase text-orange font-format">
            <h4>{{ item.Country.name }}</h4>
          </v-card-title>

          <h3 class="px-4">
            <p
              class="font-format"
              v-html="truncateDescription(item.description_project)"
            ></p>
            <div class="mt-5"></div>
            <v-divider />
            <div class="mt-3"></div>
            <router-link to="/projects" class="text-decoration-none">
              <h5 class="text-primary font-weight-bold text-uppercase font-format">
                Read more
                <v-icon>mdi-arrow-right</v-icon>
              </h5>
            </router-link>
          </h3>
        </div>
      </div>
    </v-card>
  </div>
</template>

<style scoped>
.font-format {
  font-size: 0.875rem;
  line-height: 25px;
  font-weight: 300 !important;
}
</style>
