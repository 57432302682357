<script>
import { formatDate, getParams } from '@/utils/function';
import { CUSTOMER_NOTIFICATION, CUSTOMER_NOTIFICATION_CLEAR } from '@/utils/customerApiUrl';

export default {
  props: ['closeDrawer', 'notificationCount', 'drawer'],
  data() {
    return {
      formatDate: formatDate,
      notifications: [],
      params: {
        take: 10,
        skip: 1,
      },
    };
  },
  methods: {
    async onClear(row) {
      let form = {
        id: row.id,
      };
      await this.axios
        .post(CUSTOMER_NOTIFICATION_CLEAR, form)
        .then((response) => {
          //console.log("response to", response.data);
          if (response) {
            this.notifications = this.notifications.filter(i => i.id !== row.id);
            this.$store.dispatch('getUserProfile');
          }
          //clear all notifications
          if (row.id < 0) {
            this.notifications = [];
          }
        })
        .catch((error) => {
          console.error('Failed to', error.response.data.message);
        });
    },

    async openDrawerData() {
      //this.drawer = true;
      this.notifications = [];
      let params = getParams(this.params);
      await this.axios
        .get(CUSTOMER_NOTIFICATION + params)
        .then((response) => {
          // console.log(response);
          let { data } = response.data;
          this.notifications = data;
        })
        .catch((error) => {
          console.error('Failed to', error);
        });
    },
  },
  watch:{
    drawer(){
      if(this.drawer){
        this.openDrawerData();
      }
    }
  }
};
</script>

<template>
  <!--  <pre>-->
  <!--    {{listNotifications}}-->
  <!--  </pre>-->
  <div class="pa-4">
    <div class="d-flex justify-lg-space-between">
      <VIcon @click="closeDrawer()"> mdi-arrow-left</VIcon>
      <h3>Notifications</h3>
    </div>

    <br />

    <div class="d-flex justify-lg-space-between">
      <h3 class="text-grey-lighten-1">System</h3>
      <h3 class="text-primary text-decoration-underline">Announcement</h3>
    </div>

    <br />

    <v-btn v-if="notificationCount > 0" block class="text-none" variant="tonal" @click="onClear({id: -1})">
      Clear All
    </v-btn>

    <v-card
      class="mx-auto pa-3 my-4"
      v-for="(row, index) in notifications"
      v-bind:key="index"
      rounded="md"
      border
      flat
    >
      <v-card-title>
        <VIcon color="grey-lighten-1"> mdi-bullhorn</VIcon>
        <span class="text-h5 text-primary"> {{ row.title }} </span>
      </v-card-title>
      <v-card-text>
        <p class="three-line text-control">
          {{ row.desc }}
        </p>
        <br /><br />
        <p>Released : {{ formatDate(row.createdAt, 'DD-MM-YYYY HH:MM A') }}</p>
      </v-card-text>

      <v-card-title>
        <v-btn variant="text" @click="onClear(row)">
          clear
        </v-btn>
      </v-card-title>
    </v-card>

  </div>
</template>

<style scoped></style>
