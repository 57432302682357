<script>
import MainLayout from "../../../components/MainLayout.vue";
import Contract from "@/components/finance-contract/ContractComponent.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "FinanceContractPage",
  components: { MainLayout, Contract },
};
</script>

<template>
  <MainLayout>
    <Contract></Contract>
  </MainLayout>
</template>
