<template>
    <MainLayout>
        <v-row>
            <v-col md="8" sm="12" class="text-grey">
                <span>
                    All (10)
                </span>
                <span>|</span>
                <span>
                    Active (2)
                </span>
                <span>|</span>
                <span>
                    Pending (5)
                </span>
                <span>|</span>
                <span>
                    Completed (5)
                </span>
            </v-col>
            <v-col md="4" sm="12">
                <v-row>
                    <v-col md="6">
                        <v-text-field
                                rounded="0"
                                placeholder="..."
                                variant="outlined"
                                density="compact"
                        ></v-text-field>
                    </v-col>
                    <v-col md="6">
                        <v-btn
                                class="text-capitalize"
                                rounded="0"
                                elevation="0"
                                color="grey-lighten-2"
                                block
                        >
                            Search Feedback
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="d-flex justify-lg-space-between">

            <v-col
                    class="flex-grow-0 flex-shrink-0"
                    cols="2"
            >
            <v-sheet>
                <v-select
                        rounded="0"
                        variant="outlined"
                        density="compact"
                        label="Action"
                        :items="['Action']"
                        menu-icon="mdi-chevron-right"
                ></v-select>
            </v-sheet>
            </v-col>

            <v-col
                    class="flex-grow-0 flex-shrink-0"
                    cols="2"
            >
            <v-btn
                    class="text-capitalize"
                    rounded="0"
                    elevation="0"
                    color="blue-lighten-1"
                    variant="tonal"
                    block
            >
                Apply
            </v-btn>
            </v-col>

            <v-col
                    class="flex-grow-0 flex-shrink-0"
                    cols="2"
            >
            <v-select
                    rounded="0"
                    variant="outlined"
                    density="compact"
                    label="Select Date Range"
                    :items="['All Member']"
                    menu-icon="mdi-chevron-right"
            ></v-select>
            </v-col>

            <v-col
                    class="flex-grow-0 flex-shrink-0"
                    cols="2"
            >
            <v-select
                    rounded="0"
                    variant="outlined"
                    density="compact"
                    label="All Member"
                    :items="['All Member']"
                    menu-icon="mdi-chevron-right"
            ></v-select>
            </v-col>


            <v-col
                    class="flex-grow-0 flex-shrink-0"
                    cols="2"
            >
            <v-select
                    rounded="0"
                    variant="outlined"
                    density="compact"
                    label="All Status"
                    :items="['All Status']"
                    menu-icon="mdi-chevron-right"
            ></v-select>
            </v-col>

            <v-col
                    class="flex-grow-0 flex-shrink-0"
                    cols="2"
            >
            <v-btn
                    class="text-capitalize"
                    rounded="0"
                    elevation="0"
                    color="grey-lighten-2"
                    block
            >
                Filter
            </v-btn>
            </v-col>

        </v-row>


        <v-table density="compact" height="500px"  fixed-header>
            <thead >
            <tr>
                <th class="text-left bg-grey-lighten-2">
                    Subject
                </th>
                <th class="text-left bg-grey-lighten-2">
                    Information
                </th>
                <th class="text-left bg-grey-lighten-2">
                    Member
                </th>
                <th class="text-left bg-grey-lighten-2">
                    Status
                </th>
                <th class="text-left bg-grey-lighten-2">
                    Date
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
                    v-for="item in data"
                    :key="item.id"
            >
                <td>
                    <div class="d-flex">
                        {{ item.subject }}
                    </div>
                </td>
                <td>{{ item.information }}</td>
                <td>{{ item.member }}</td>
                <td>{{ item.status }}</td>
                <td>{{ item.date }}</td>
            </tr>
            </tbody>
        </v-table>

        <div class="text-center mt-5">
            <v-pagination
                    v-model="page"
                    :length="15"
                    :total-visible="7"
                    size="small"
            ></v-pagination>
        </div>

    </MainLayout>
</template>

<script>
    import MainLayout from "../../../components/MainLayout.vue";

    let data = [];

    for (let i = 0; i <100 ; i++) {
        data.push({
            id: i,
            subject: 'Verify Account',
            information: "Chan Dara | Identification Card | 17893311234",
            member: "Nouen Thary",
            status: "Pending",
            date: '2024-01-12'
        })
    }

    export default {
        name: "RequestBox",
        components: {MainLayout},
        data () {
            return {
                data: data,
                page: 1,
            }
        },
    }
</script>

<style scoped>

</style>