<script>
export default {
  name: 'MenuCollapse',
  props: [
    'data',
  ],
};
</script>

<template>
  <v-expansion-panels rounded="0">
    <v-expansion-panel>

      <v-expansion-panel-title>
        <h4 v-html="data.title"></h4>
        <template v-slot:actions="{ expanded }">
          <v-icon :color="expanded ? 'blue' : ''" :icon="expanded ? 'mdi-menu-up' : 'mdi-menu-down'"></v-icon>
        </template>
      </v-expansion-panel-title>

      <v-expansion-panel-text class="bg-primary">
        <p class="text-content text-white text-three-line" v-html="(data.description)">
        </p>
        <br />
        <router-link :to="data.buttonLabelLink" class="text-decoration-none">
          <v-btn block color="grey-darken-3" size="large" border elevation="0" rounded="xl">
            {{ data.buttonLabel }}
          </v-btn>
        </router-link>
        <br />
      </v-expansion-panel-text>

      <v-expansion-panel-text class="bg-primary" v-for="row in data.menu" v-bind:key="row.title">
        <router-link :to="row.link" class="text-decoration-none">
          <p class="text-content text-white" v-html="row.title"></p>
        </router-link>
      </v-expansion-panel-text>

    </v-expansion-panel>
  </v-expansion-panels>
</template>

<style scoped>

</style>