<template>
  <div>
    <p>Create Exchange Rate</p>
    <v-divider></v-divider>
    <br />
    <div class="d-flex">
      <p class="ma-1">Add a new exchange rate</p>

      <v-btn class="mx-5" icon="mdi-plus" size="small" color="primary"></v-btn>
    </div>

    <br />

    <template v-for="i in list" :key="i">
      <div class="d-flex">
        <div style="width: 20%">
          <v-text-field
            v-model="baseCurrency"
            outlined
            variant="outlined"
            rounded="xl"
            suffix="PST"
            density="compact"
            placeholder="USD"
          ></v-text-field>
        </div>

        <p class="ma-2">to</p>

        <div style="width: 20%">
          <v-text-field
            v-model="targetCurrency"
            clearable
            required
            variant="outlined"
            rounded="xl"
            suffix="PST"
            density="compact"
            :placeholder="i.currency"
          ></v-text-field>
        </div>
        <p class="ma-2"></p>
        <v-btn round color="primary" rounded="xl" class="text-none">
          Update</v-btn
        >
        <p class="ma-2"></p>
        <v-btn round color="red" rounded="xl" class="text-none">Remove</v-btn>
        <v-btn class="mx-4" icon="mdi-plus" size="small" color="green"></v-btn>
      </div>
    </template>

    <div>
      <div class="currency-display">
        <div
          style="
            width: 90%;
            text-align: left;
            display: flex;
            align-items: center;
          "
           @click="toggleSheet"
        >
          <v-avatar
            variant="variant"
            size="36px"
            color="white"
            style="border: 1px solid white"
          >
            <v-img
              alt="John"
              :src="selectedCurrency.flag"
              class="flag"
              contain
              :aspect-ratio="1"
            ></v-img>
          </v-avatar>
          <div class="currency-info ml-3">
            <span
              class="currency-code"
              style="color: white; font-weight: normal; opacity: 1"
            >
              {{ selectedCurrency.code }}</span
            >
            <span
              class="currency-name"
              style="color: white; font-weight: normal; opacity: 0.7"
              >{{ selectedCurrency.name }}</span
            >
          </div>
        </div>
        <div style="width: 10%; text-align: right; margin-right: 10px">
          <span class="currency-code" style="color: white; text-align: right">
            <v-text-field
              v-model.number="toRank"
              model-value="0"
              placeholder="0.00"
              variant="plain"
              reverse
              class="text-white"
            >USD
            </v-text-field> 
          </span>
        </div>
      </div>

      <div v-if="showSheet" class="bottom-sheet">
        <div class="sheet-content">
          <h5>Select Currency</h5>
          <ul class="list-group">
            <li
              v-for="currency in currencies"
              :key="currency.code"
              class="list-group-item currency-item"
              @click="selectCurrency(currency)"
            >
              <img :src="currency.flag" alt="" class="flag" />
              {{ currency.name }} ({{ currency.code }})
            </li>
          </ul>
          <button class="btn btn-secondary" @click="toggleSheet">Close</button>
        </div>
      </div>
    </div>

    <div class="exchange-rates">
      <div class="currency-rate" v-for="rate in rates" :key="rate.code">
        <div class="currency-info">
          <span class="currency-code">{{ rate.code }}</span>
          <span class="currency-name">{{ rate.name }}</span>
        </div>
        <div class="text-right">
          <span class="currency-code">{{ rate.value }} {{ rate.base }}</span
          ><br />
          <span class="currency-name"
            >1 USD = {{ rate.value }} {{ rate.base }}</span
          >
        </div>
      </div>

      <!-- Bottom Sheet/Modal -->

      <p class="note">
        Note: The exchange rates displayed are for informational purposes only
        and may change without prior notice.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "ExchangeTab",
  data() {
    return {
      baseCurrency: "",
      targetCurrency: "",
      list: [
        { id: 1, currency: "KHR", usd: 1, exchange: 1 },
        { id: 1, currency: "THB", usd: 1, exchange: 1 },
        { id: 1, currency: "CHY", usd: 1, exchange: 1 },
        { id: 1, currency: "JPY", usd: 1, exchange: 1 },
      ],
      updatedOn: "24 JAN 2025 at 10:16 AM",
      rates: [
        { code: "KHR", name: "Cambodian Riel", value: "4,013.00", base: "USD" },
        { code: "AUD", name: "Australian Dollar", value: "1.56", base: "USD" },
        { code: "CNY", name: "Chinese Yuan", value: "7.18", base: "USD" },
        { code: "EUR", name: "Euro", value: "0.84", base: "USD" },
        { code: "GBP", name: "British Pound", value: "0.80", base: "USD" },
        { code: "HKD", name: "Hong Kong Dollar", value: "7.80", base: "USD" },
        { code: "THB", name: "Thai Baht", value: "36.23", base: "USD" },
        {
          code: "VND",
          name: "Vietnamese Dong",
          value: "24,725.82",
          base: "USD",
        },
        {
          code: "KRW",
          name: "South Korean Won",
          value: "1,397.82",
          base: "USD",
        },
      ],
      selectedRate: null,
      showSheet: false,
      amount: 1.0,
      selectedCurrency: {
        code: "USD",
        name: "United States Dollar",
        flag: "https://flagcdn.com/us.svg",
      },
      currencies: [
        {
          code: "USD",
          name: "United States Dollar",
          flag: "https://flagcdn.com/us.svg",
        },
        { code: "EUR", name: "Euro", flag: "https://flagcdn.com/eu.svg" },
        {
          code: "GBP",
          name: "British Pound",
          flag: "https://flagcdn.com/gb.svg",
        },
        // Add more currencies and their flags as needed
      ],
    };
  },
  methods: {
    openBottomSheet(rate) {
      this.selectedRate = rate;
      this.showSheet = true;
    },
    closeBottomSheet() {
      this.showSheet = false;
    },
    toggleSheet() {
      this.showSheet = !this.showSheet;
    },
    selectCurrency(currency) {
      this.selectedCurrency = currency;
      this.toggleSheet();
    },
  },
};
</script>
<style scoped>
.exchange-rates {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
h1 {
  font-size: 24px;
  margin-bottom: 10px;
}
.currency-rate {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}
.currency-info {
  display: flex;
  flex-direction: column;
}
.currency-code {
  font-weight: bold;
  margin-bottom: 2px;
  opacity: 0.7;
}
.bottom-sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
}
.sheet-content {
  display: flex;
  flex-direction: column;
}
.currency-option {
  padding: 10px;
  cursor: pointer;
}
.currency-option:hover {
  background-color: #f0f0f0;
}
.note {
  margin-top: 20px;
  font-size: 12px;
  color: #888;
}
.currency-name {
  font-size: 13px;
  color: #666;
  opacity: 0.9;
}

.currency-display {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: rgb(var(--v-theme-primary)) !important;
  cursor: pointer;
  border: 1px solid #ced4da;
  border-radius: 10px;
}

.flag {
  width: 30px;
  height: 20px;
  margin-right: 8px;
}

.bottom-sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-top: 1px solid #ced4da;
  padding: 16px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.sheet-content {
  max-height: 300px;
  overflow-y: auto;
}

.currency-item {
  display: flex;
  align-items: center;
}

.currency-item .flag {
  width: 30px;
  height: 20px;
  margin-right: 8px;
}
</style>
