<script>
import { Path_Feedback_Image, URL_Feedback_Admin } from '@/utils/apiUrl';

export default {
  name: 'MemberFeedback',
  data() {
    return {
      path: Path_Feedback_Image,
      members: [],
    };
  },
  methods: {
    async fetchData() {
      this.axios
        .get(
          URL_Feedback_Admin,
        )
        .then((response) => {
          if (response.data) {
            this.members.push(response.data);
          }
        })
        .catch((error) => {
          console.error('Failed to', error);
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<template>
  <div>
    <!-- {{path }} -->
    <!-- <pre>
    {{ members[0] }}
   </pre> -->
    <h2 class="text-primary text-center text-uppercase">
      Member Feedback
    </h2>
    <br />
    <p class="text-content text-center">
      Our member are happy and Satisfied to our services.
    </p>
    <br />

    <div class="div">

      <div class="d-flex" style="width: 100vw" v-if="members.length > 0">

          <template v-for="row in members[0]" v-bind:key="row.id">
              <v-card class="mx-2" min-width="400" rounded="xl" height="200">
                <v-card-text class="pa-5">
                  <h4 class="font-weight-regular">
                    <span v-html="row.desc" class="flex-ellipsis"></span>
                  </h4>
                </v-card-text>
                <v-card-actions>
                  <v-list-item class="">
                    <VRow>
                      <VCol cols="4">
                        <v-avatar class="elevation-1" color="grey-lighten-3" rounded="100" size="90">
                          <v-avatar rounded="100" size="80">
                            <v-img
                              :src="path + row.photoUser" cover
                             
                            ></v-img>
                          </v-avatar>
                        </v-avatar>
                      </VCol>
                      <VCol cols="8">
                        <v-list-item-title class="text-uppercase text-primary font-weight-bold">
                          {{ row.title }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-orange">@{{ row.nameUser }}</v-list-item-subtitle>
                        <br />
                        <v-list-item-media class="text-uppercase text-grey font-weight-bold">
                          {{ row.job }}
                        </v-list-item-media>
                      </VCol>
                    </VRow>
                  </v-list-item>
                </v-card-actions>
              </v-card>
          </template>

      </div>
    </div>

    <br/>
    <br />
    <br />
    <br />
  </div>
</template>

<style scoped>
.flex-ellipsis {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
}

.div {
  margin:  50px 0;
  width: 100%;
  height: 100px;
  position: relative;
  animation: keyframe 50s;
  animation-direction: alternate;
}

@keyframes keyframe {
  0% { right: 0%;}
  20%{ right : 30%;}
  50%{ right : 0%;}
  100%{ right: 30%;}
}

</style>