<script>
import { Path_ASIA_PACIFIC_IMAGE, URL_ASIA_PACIFIC } from "@/utils/apiUrl";
import FrontendLayout from "@/views/client/components/FrontendLayout.vue";
import axios from "axios";

export default {
  name: "AsiaPacificWebPage",
  components: { FrontendLayout },
  data() {
    return {
      data: {},
      path: Path_ASIA_PACIFIC_IMAGE,
    };
  },
  methods: {
    async fetchData() {
      await axios
        .get(URL_ASIA_PACIFIC)
        .then((response) => {
          this.data = response.data[0];
        })
        .catch((error) => {
          console.error("Failed to", error);
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<template>
  <FrontendLayout>
    <div style="height: 700px; background-color: gainsboro">
      <v-img
        style="height: 700px; width: 100%; background-color: gainsboro"
        cover
        :src="
          data.image1 !== null
            ? path + data.image1
            : 'https://assets-v2.lottiefiles.com/a/d0c1a50a-1171-11ee-bc55-77e80401811a/LgJcg4Igt2.gif'
        "
      >
        <template v-slot:placeholder>
          <v-row align="center" class="fill-height ma-0" justify="center">
            <v-progress-circular
              color="grey-lighten-5"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </template>
        <!--
        <h3 class="text-uppercase text-center text-white py-5">
          {{ data.title }}
        </h3> -->
      </v-img>
    </div>

    <div class="bg-grey-lighten-4">
      <h3 class="text-uppercase text-center text-blue-darken-3 pa-5">
        {{ data.title }}
      </h3>

      <div class="margin-auto">
        <p class="text-content text-justify font-format" v-html="data.desc1"></p>

        <br />

        <v-card min-height="400" rounded="0" elevation="0">
          <v-img
            :src="
              data.image2 !== null
                ? path + data.image2
                : 'https://assets-v2.lottiefiles.com/a/d0c1a50a-1171-11ee-bc55-77e80401811a/LgJcg4Igt2.gif'
            "
          >
            <template v-slot:placeholder>
              <v-row align="center" class="fill-height ma-0" justify="center">
                <v-progress-circular
                  color="grey-lighten-5"
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card>
        <br />
        <h3>{{ data.title }}</h3>
        <br />

        <p class="text-content text-justify font-format" v-html="data.desc2"></p>

        <br />

        <v-card min-height="400" rounded="0" elevation="0">
          <v-img
            :src="
              data.image3 !== null
                ? path + data.image3
                : 'https://assets-v2.lottiefiles.com/a/d0c1a50a-1171-11ee-bc55-77e80401811a/LgJcg4Igt2.gif'
            "
          >
            <template v-slot:placeholder>
              <v-row align="center" class="fill-height ma-0" justify="center">
                <v-progress-circular
                  color="grey-lighten-5"
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card>
        <br />
        <h3>{{ data.title }}</h3>
        <br />

        <p class="text-content text-justify font-format" v-html="data.desc3"></p>
        <br />
      </div>
    </div>
  </FrontendLayout>
</template>

<style scoped>
.font-format {
  font-size: 0.875rem;
  line-height: 25px;
}
</style>
