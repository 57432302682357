<script>
import RowFacts from '@/views/client/projects/components/RowFacts.vue';
import { mapState } from 'vuex';
import { formatDate, formatPrice, getParams, stringToBoolean } from '@/utils/function';
import { COUNT_PROJECT_BY_USER_INVEST, CUSTOMER_INVEST, LOADING_IMAGE } from '@/utils/customerApiUrl';
import ErrorDialog from '@/views/client/components/dialog/ErrorDialog.vue';
import SuccessModel from '@/views/client/components/dialog/SuccessModel.vue';

export default {
  name: 'ProjectFacts',
  components: {
    SuccessModel,
    ErrorDialog,
    RowFacts,
  },
  computed: {
    ...mapState(['profile', 'wallet', 'compensations', 'startRankReferrals']),
  },
  props: ['project', 'fetchData'],
  data() {
    return {
      LOADING_IMAGE: LOADING_IMAGE,
      formatPrice: formatPrice,
      firstRow: [
        'Project Id',
        'Guarantee Holder',
        'Investor Country',
        'Environment Category',
        'Date SPG Disclosed',
        'Board Approval Date',
        'Project Type',
        'Fiscal Year',
      ],
      secondRow: [
        'xxx',
        'xxx',
        'Country',
        'xxx',
        'xxx',
        'xxx',
        'xxx',
        'xxx',
      ],
      dialog: false,
      walletAccount: null,
      form: {
        projectId: 0,
        star_rank_referral_id: 0,
        compensation_id: 0,
        interest_name: '',
        invest_no: '',
        status: 'Pending',
        limit_share: 0,
        price_per_share: 0,
        interest: 0,
        buy_share: 0,
        level_percent: 0,
        contrast: 0,
        total_interest: 0,
        profit: 0,
        total_profit: 0,
        count_number_return_invest: 0,
        total_level_percent: 0,
        project_start: '',
        project_end: '',
        number_send: 0,
        is_crowdfunding: '',
        wallet_account: '',
        numberUserBuy: 0
      },
      loading: false,
      // modal message
      isErrorDialog: false,
      isErrorMessage: '',
      // modal success
      isSuccessDialog: false,
      isSuccessMessage: '',
    };
  },
  methods: {
    isInvestment() {
      let investmentCheck = stringToBoolean(this.project.investmentCheck);
      let soldOut = this.project.project_status.includes('Sold out');
      if (soldOut === true) {
        return true;
      } else if (investmentCheck) {
        return investmentCheck;
      } else if (this.profile.email) {
        return false;
      }
      return true;
    },

    async countProjectByUserInvest() {
      let projectId = sessionStorage.getItem('projectId');
      if(projectId) {
        let params = getParams({
          projectId: projectId,
        });
        await this.axios
          .get(COUNT_PROJECT_BY_USER_INVEST + params)
          .then((response) => {
            // console.log(response);
            let { numberUserBuy } = response.data;
            this.form.numberUserBuy = numberUserBuy;
          })
          .catch((error) => {
            console.error('Failed to', error);
          });
      }
    },

    async investNow() {

      if (this.profile.status.toLowerCase() !== 'done') {
        this.isErrorMessage = 'Your account is not complete document. Please submit your document.';
        this.isErrorDialog = true;
        return;
      }

      this.loading = true;
      let form = {
        ...this.form,
      };
      await this.axios
        .post(CUSTOMER_INVEST, form)
        .then((response) => {
          //console.log("response to", response.data);
          setTimeout(() => {
            this.loading = false;
            this.dialog = false;
            this.fetchData();

            this.form = {
              //projectId: 0,
              star_rank_referral_id: 0,
              compensation_id: 0,
              interest_name: '',
              invest_no: '',
              status: 'Pending',
              limit_share: 0,
              price_per_share: 0,
              interest: 0,
              buy_share: 0,
              level_percent: 0,
              contrast: 0,
              total_interest: 0,
              profit: 0,
              total_profit: 0,
              count_number_return_invest: 0,
              total_level_percent: 0,
              project_start: '',
              project_end: '',
              number_send: 0,
              is_crowdfunding: '',
              wallet_account: '',
            };
            this.walletAccount = '';

            this.isSuccessMessage = response.data.message;
            this.isSuccessDialog = true;
          }, 2000);

          setTimeout(() => {
            this.isSuccessDialog = false;
            this.$store.dispatch('getUserProfile');
          }, 5000);
        })
        .catch((error) => {
          let message = error.response.data.message;
          this.isErrorMessage = message;
          if (Array.isArray(message)) {
            this.isErrorMessage = message[0];
          }
          this.isErrorDialog = true;
          this.loading = false;
          //console.error("Failed to", error.response.data.message);
        });
    },

    onInterestChange(item) {
      //console.log({ ...item });
      this.form.compensation_id = item.id;
      this.form.interest_name = item.interest_name;
      this.form.number_send = item.contrast;

      switch (item.interest_name) {
        case 'Daily':
          this.form.interest = this.project.daily;
          break;
        case 'Weekly':
          this.form.interest = this.project.weekly;
          break;
        case 'Monthly':
          this.form.interest = this.project.monthly;
          break;
        case 'Quarterly':
          this.form.interest = this.project.quarterly;
          break;
        case 'Semi-Annually':
          this.form.interest = this.project.semi_annually;
          break;
        case 'Annually':
          this.form.interest = this.project.semi_annually;
          break;
      }
      this.onCalculate();
    },

    onCalculate() {
      let interest = this.form.interest;
      let level_percent = this.form.level_percent;
      let buy_share = this.form.buy_share;
      let price_per_share = this.form.price_per_share;
      // total
      if (interest > 0 && level_percent > 0) {
        this.form.total_interest = level_percent;
        let profit = (buy_share * price_per_share) * ((interest + level_percent) / 100);
        this.form.profit = profit;
        let totalSent = Math.ceil(this.form.contrast / this.form.number_send);
        this.form.total_profit = profit * totalSent;
      }
    },

    openModal() {
      this.countProjectByUserInvest();
      this.dialog = true;
      let starRank = this.startRankReferrals.filter(i => i.rank_name.includes(this.profile.numberLevel));
      this.form = {
        ...this.form,
        projectId: this.project.id,
        contrast: this.project.contrast,
        is_crowdfunding: this.project.crowdfunding,
        limit_share: this.project.limit_share,
        price_per_share: this.project.price_per_share,
      };
      if (starRank.length > 0) {
        this.form.star_rank_referral_id = starRank[0].id;
        this.form.level_percent = starRank[0].percent_ratio;
      }
    },

    onCloseModalError(dialog) {
      this.isErrorDialog = dialog;
    },

    onCloseModalSuccess(dialog) {
      this.isSuccessDialog = dialog;
    },
  },
  created() {
    //let project = { ...this.project }; console.log(project);
    this.secondRow = [
      this.project.project_id,
      this.project.guarantee_holder,
      this.project.Country.name,
      this.project.environmental_category,
      formatDate(this.project.date_spg_disclosed, 'MMM DD, YYYY'),
      formatDate(this.project.board_approval_date, 'MMM DD, YYYY'),
      this.project.project_type,
      this.project.fiscal_year,
    ];
  },
  watch: {
    walletAccount(wallet) {
      // console.log({ ...wallet });
      this.form.wallet_account = wallet.bankAccount;
    },
    'form.buy_share'() {
      this.onCalculate();
    },
  },
};
</script>

<template>

  <v-card
    class="mx-auto my-12"
    color="light-blue-darken-4"
    rounded="xl"
  >
    <v-img
      height="250"
      :src="project.image_project === null ?  LOADING_IMAGE :project.urlImage"
      cover
      :lazy-src="LOADING_IMAGE"
    ></v-img>

    <v-card-item>
      <v-card-title>Project Facts</v-card-title>
    </v-card-item>

    <template v-for="(row ,index) in firstRow.length" v-bind:key="row">
      <RowFacts :lineHeader="index === 0" :header="firstRow[index]" :columns="secondRow[index]" />
    </template>

    <br />
    <v-card-actions class="mx-2">
      <v-btn
        class="bg-orange"
        block
        size="large"
        rounded="xl"
        variant="outlined"
        @click="openModal()"
        :disabled="isInvestment()"
      >
        <h4>
          Invest Now
        </h4>
      </v-btn>
    </v-card-actions>
    <br />
  </v-card>

  <!--  Modal Form-->
  <v-dialog
    v-model="dialog"
    width="900"
    persistent
  >
    <v-card class="modal-padding" rounded="xl">
<!--            <pre>-->
<!--            {{ project }}-->
      <!--            {{  compensations }}-->
      <!--      {{ wallet }}-->
      <!--      {{ startRankReferrals }}-->
      <!--      {{form}}-->
<!--            </pre>-->
      <div class="d-flex">
        <h2 class="text-primary">
          PROJECT | COUNTRY
        </h2>
        <v-spacer />
        <v-btn @click="dialog=false" elevation="0" color="red" icon="mdi-close" density="comfortable"></v-btn>
      </div>

      <p class="my-3">
        {{ project.project_title }}
      </p>

      <VRow>
        <VCol cols="12" md="4">
          <p class="font-weight-regular">Project Shares:
            <span>{{ formatPrice(project.project_share) }}</span></p>
        </VCol>
        <VCol cols="12" md="4">
          <p class="font-weight-regular">Available Shares: <span>{{ formatPrice(project.limit_share - form.numberUserBuy) }}</span></p>
        </VCol>
        <VCol cols="12" md="4">
          <p class="font-weight-regular">Price Per Shares: <span
            class="text-primary">${{ formatPrice(project.price_per_share) }}</span></p>
        </VCol>
      </VRow>
      <div class="py-2">
        <p class="font-weight-regular">Share Purchase</p>

        <v-row>
          <v-col cols="12" md="6">
            <v-sheet rounded="xl" class="d-flex" color="" height="50" width="100%">
              <v-sheet border class="d-flex align-center justify-center rounded-ts-xl rounded-bs-xl" color="white"
                       width="50">
                <v-icon>mdi-arrow-decision-outline</v-icon>
              </v-sheet>

              <v-sheet border>
                <v-text-field
                  v-model.number="form.buy_share"
                  class="mx-1"
                  density="comfortable"
                  variant="plain"
                  rounded="xl"
                  type="number"
                />
              </v-sheet>

              <v-sheet border height="50" width="50%" class="rounded-te-xl rounded-be-xl">
                <p class="px-4">Total</p>
                <h4 class="text-center">$ {{ formatPrice(form.price_per_share * form.buy_share) }}</h4>
              </v-sheet>

            </v-sheet>
          </v-col>
        </v-row>

      </div>
      <p class="font-weight-regular">Compensation</p>
      <br />
      <VRow>
        <VCol cols="12" md="4">
          <p class="font-weight-regular">Type</p>
          <h4 class="text-center">Short Term</h4>
        </VCol>
        <VCol cols="12" md="4">
          <p class="font-weight-regular">Contract</p>
          <h4 class="text-center">{{ project.contrast }} days</h4>
        </VCol>
        <VCol cols="12" md="4">
          <p class="font-weight-regular">Share Limitations</p>
          <h4 class="text-center">{{ project.limit_share }}</h4>
        </VCol>
      </VRow>
      <br />
      <p class="font-weight-regular py-2">Settlement Interest</p>
      <VRow>
        <template v-for="(row, index) in compensations" v-bind:key="index">
          <VCol cols="12" :md="2">
            <VBtn class="text-none"
                  block
                  rounded="xl"
                  color="primary"
                  @click="onInterestChange(row)"
                  :variant="form.interest_name === row.interest_name ? 'outlined' : 'text'"
                  v-if="form.contrast / row.contrast >= 1"
            >
              {{ row.interest_name }}
            </VBtn>
          </VCol>
        </template>
      </VRow>
      <div class="py-3" />
      <div>
        <div>
          <v-row class="mx-0">
            <v-col cols="6" md="6" class="pa-0">
              <v-sheet border min-height="50" class="pa-2 rounded-ts-xl">
                <p class="text-modal-title">Interest Rate</p>
                <h4 class="text-end">{{ formatPrice(form.interest) }} %</h4>
              </v-sheet>
            </v-col>
            <v-col cols="6" md="6" class="pa-0">
              <v-sheet border min-height="50" class="pa-2 rounded-te-xl">
                <p class="text-modal-title">Total Interest Rate</p>
                <h4 class="text-end">{{ formatPrice(form.total_interest) }} %</h4>
              </v-sheet>
            </v-col>
            <v-col cols="6" md="6" class="pa-0">
              <v-sheet border min-height="50" class="pa-2 rounded-bs-xl">
                <p class="text-modal-title">Profit</p>
                <h4 class="text-end">$ {{ formatPrice(form.profit) }}</h4>
              </v-sheet>
            </v-col>
            <v-col cols="6" md="6" class="pa-0">
              <v-sheet border min-height="50" class="pa-2 rounded-be-xl">
                <p class="text-modal-title">Total Profit</p>
                <h4 class="text-end">$ {{ formatPrice(form.total_profit) }}</h4>
              </v-sheet>
            </v-col>
          </v-row>
        </div>

        <p class="font-weight-regular mt-5">Wallet Account No:</p>

        <v-row>
          <v-col cols="12" md="6">
            <v-sheet rounded="xl" class="d-flex" color="" height="50" width="100%">

              <v-sheet border class="d-flex align-center justify-center rounded-ts-xl rounded-bs-xl" color="white"
                       width="50">
                <v-icon class="">mdi-wallet-outline</v-icon>
              </v-sheet>

              <v-sheet border width="100%" class="rounded-te-xl rounded-be-xl">
                <v-autocomplete
                  density="comfortable"
                  v-model="walletAccount"
                  :items="[
                   // {
                   //    id: 4,
                   //    availableBalance: 40,
                   //    accountBalance: 44,
                   //    bankAccount: '000 000 100',
                   //    walletType: 'personal account',
                   //    pin_code: '4444'
                   //  }
                   ...wallet
                ]"
                  variant="plain"
                  rounded="xl"
                  suffix="USD"
                  menu-icon="mdi-chevron-right"
                  return-object
                  class="ml-2"
                >
                  <template v-slot:chip="{ props, item }">
                    <p v-if="walletAccount" class="mr-2 text-overflow-hidden">{{ item.raw.bankAccount }}</p>
                    <v-chip
                      v-if="walletAccount"
                      color="primary"
                      v-bind="props"
                      :text="item.raw.walletType"
                      variant="flat"
                      density="compact"
                    ></v-chip>
                  </template>

                  <template v-slot:item="{ props, item }">
                    <v-list-item
                      v-bind="props"
                      :title="item.raw.bankAccount"
                      :subtitle="item.raw.walletType"
                    ></v-list-item>
                  </template>

                </v-autocomplete>
              </v-sheet>
            </v-sheet>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="3">
            <VBtn block :loading="loading" color="primary" size="large" rounded="xl" class="text-none"
                  @click="investNow()">
              Invest Now
            </VBtn>
          </v-col>

          <v-col cols="12" md="3">
            <VBtn block color="orange" size="large" rounded="xl" class="text-none" @click="dialog = false">
              Cancel
            </VBtn>
          </v-col>

          <v-col cols="12" md="3">
            <router-link to="/profile?tab=Deposit" class="text-decoration-none">
              <VBtn block variant="outlined" color="green" size="large" rounded="xl" class="text-none">
                Deposit
              </VBtn>
            </router-link>
          </v-col>
        </v-row>

      </div>
    </v-card>
  </v-dialog>
  <!--  Modal Form-->

  <template v-if="isErrorDialog">
    <ErrorDialog :dialog="isErrorDialog" :message="isErrorMessage" :onCloseModal="onCloseModalError" />
  </template>

  <template v-if="isSuccessDialog">
    <SuccessModel :dialog="isSuccessDialog" :message="isSuccessMessage" :onCloseModal="onCloseModalSuccess" />
  </template>

</template>

<style scoped></style>