<template>
  <v-card rounded="0" class="px-10 pa-3">
    <v-row>
      <v-col cols="6" sm="6" md="2">
        <v-img class="mt-2" width="215px" height="40px" :src="state.logo" alt="" />
      </v-col>

      <v-col cols="12" sm="12" md="8" class="hidden"></v-col>

      <v-col cols="6" sm="6" md="2">
        <div class="d-flex justify-end">
          <v-btn variant="text" icon="mdi-close" @click="handleBack"></v-btn>
        </div>
      </v-col>
    </v-row>
  </v-card>

  <div class="margin-center">
    <br />
    <br />
    <h2 class="text-center">Welcome back !</h2>
    <p class="text-center">
      <span> Already have an account </span>
      <router-link
        class="text-decoration-none text-black text-decoration-underline"
        to="/sign-up"
      >
        Sign up ?
      </router-link>
    </p>
    <div v-if="state.error">
      <br />
      <br />
      <v-card class="pa-4" color="grey-lighten-3" elevation="0">
        <v-avatar color="red">
          <v-icon>mdi-close</v-icon>
        </v-avatar>
        <span>
          {{ state.message }}
        </span>
      </v-card>
    </div>
    <br />
    <p class="ma-1">First, enter your email address</p>
    <v-form ref="formLogin">
      <v-text-field
        prepend-inner-icon="mdi-email"
        v-model="state.form.email"
        variant="outlined"
        density="compact"
        autocomplete="off"
        placeholder="Enter your email address"
        v-on:keyup="handleChange"
        type="email"
        :rules="nameRules"
      />
      <v-text-field
        v-model="state.form.password"
        variant="outlined"
        density="compact"
        autocomplete="off"
        placeholder="Enter your password"
        prepend-inner-icon="mdi-lock"
        :append-inner-icon="state.visible ? 'mdi-eye-off' : 'mdi-eye'"
        :rules="nameRulesPass"
        :type="state.visible ? 'text' : 'password'"
        @click:append-inner="state.visible = !state.visible"
      />
      <v-btn
        block
        rounded="xl"
        :disabled="state.form.email == '' || state.form.password == '' ? true : false"
        color="blue"
        elevation="0"
        @click="handleSubmit()"
        :loading="state.loading"
      >
        <p class="text-capitalize">Login</p>
      </v-btn>
    </v-form>
    <p class="my-6">Or login with</p>

    <v-row>
      <v-col cols="12">
        <GoogleSignInButton
          @success="handleLoginSuccess"
          @error="handleLoginError"
          ref="google"
        >
        </GoogleSignInButton>
      </v-col>
    </v-row>

    <br />
    <br />

    <p class="text-center">
      By registering, you accept our
      <router-link
        class="text-decoration-none text-black text-decoration-underline"
        to="/"
      >
        Term of use
      </router-link>
      and
      <router-link
        class="text-decoration-none text-black text-decoration-underline"
        to="/"
      >
        Privacy policy
      </router-link>
    </p>
  </div>
</template>

<script setup>
import { CUSTOMER_SIGN_IN } from '@/utils/customerApiUrl';
import { setLocalStorage, uuid } from '@/utils/function';
import axios from 'axios';
import { onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { decodeCredential } from 'vue3-google-signin';
import { useStore } from 'vuex';
import { setAuthToken } from '@/servicesAxios/axiosInstance';

const store = useStore();

const router = useRouter();

const state = reactive({
  logo: null,
  visible: false,
  form: {
    email: '',
    password: '',
  },
  loading: false,
  customer_login_information: {
    ip: '',
    city: '',
    region: ' ',
    region_code: '',
    country: '',
    country_code: '',
    country_capital: ' ',
    postal: null,
    latitude: 0,
    longitude: 0,
    organization: '',
    browser: '',
  },
  error: false,
  message: 'Something went wrong.',
});

const nameRules = [
  (value) => {
    if (value) return true;
    return 'Email is required.';
  },
  (value) => {
    if (value.length > 0) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || 'Invalid Email address';
    }
  },
];

const nameRulesPass = [
  (value) => {
    if (value) return true;
    return 'Password is required.';
  },
];

const handleChange = (event) => {
  state.form.email = event.target.value;
  state.error = false;
};

const handleBack = () => {
  setLocalStorage('form_login', []);
  router.go(-1);
};

const signIn = async (payload) => {
  state.loading = true;

  await axios
    .post(CUSTOMER_SIGN_IN, payload)
    .then((response) => {
      const { access_token } = response.data;
      if (access_token) {
        setAuthToken(access_token);
        store.dispatch('getUserProfile');
        setTimeout(() => {
          state.loading = false;
          router.push("/profile?" + uuid());
        }, 1000);
      }
    })
    .catch((error) => {
      if (error.response.status === 400) {
        state.message = error.response.data.message;
        state.error = true;
        state.loading = false;
      }
    });
};

const handleSubmit = () => {
  let payload = {
    ...state.form,
    customer_login_information: state.customer_login_information,
    google_id: '0',
  };
  signIn(payload);
};

const handleLoginSuccess = (response) => {
  const { credential } = response;

  let decodedCredential = decodeCredential(credential);

  const payload = {
    ...decodedCredential,
    google_id: decodedCredential.id,
    customer_login_information: state.customer_login_information,
    password: '',
  };
  //console.log(payload);
  signIn(payload);
};

const handleLoginError = (error) => {
  console.error('Login error', error);
};

const userInfo = async () => {
  let userInfo = localStorage.getItem('userInfo');
  let data = JSON.parse(userInfo);
  if (data !== null) {
    const {
      ip,
      city,
      region,
      region_code,
      country_name,
      country_code,
      country_capital,
      country_area,
      latitude,
      longitude,
      org,
    } = data;
    state.customer_login_information = {
      ip: ip,
      city: city,
      region: region,
      region_code: region_code,
      country: country_name,
      country_code: country_code,
      country_capital: country_capital,
      postal: country_area,
      latitude: latitude,
      longitude: longitude,
      organization: org,
      browser: navigator.userAgent,
    };
  }

  if (userInfo === null) {
    fetch('https://ipapi.co/json')
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        const {
          ip,
          city,
          region,
          region_code,
          country_name,
          country_code,
          country_capital,
          country_area,
          latitude,
          longitude,
          org,
        } = data;

        state.customer_login_information = {
          ip: ip,
          city: city,
          region: region,
          region_code: region_code,
          country: country_name,
          country_code: country_code,
          country_capital: country_capital,
          postal: country_area,
          latitude: latitude,
          longitude: longitude,
          organization: org,
          browser: navigator.userAgent,
        };
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

onMounted(() => {
  store.dispatch('getCompanyInfo');
  state.logo = localStorage.getItem('logo') ?? null;
  localStorage.removeItem('client_token');
  userInfo();
  state.form = {
    email: 'admin@gmail.com',
    password: location.host.includes('127.0.0.1') || location.host.includes('localhost') ? '12345678' : '',
    register: false,
  };
});
</script>

<style scoped></style>
