<template>
  <div>
    <v-list
      density="compact"
      class="pa-0"
      v-for="(item, index) in wallet"
      v-bind:key="index"
    >
      <v-list-subheader color="" class="bg-primary pa-4">
        <h4>My Balance</h4>
      </v-list-subheader>

      <v-list-item rounded="xl">
        <v-list-item-action class="my-4">
          <v-card elevation="0" width="160"> Available Balance : </v-card>
          <p>{{ formatPrice(item.availableBalance) }} $</p>
        </v-list-item-action>
        <v-divider />
        <v-list-item-action class="my-4">
          <v-card elevation="0" width="160"> Account Balance :</v-card>
          <p>{{ formatPrice(item.accountBalance) }} $</p>
        </v-list-item-action>
        <v-divider />
        <v-list-item-action class="my-4 pa-0">
          <v-card elevation="0" width="160"> Account Number :</v-card>
          <p class="">{{ formatBankAccount(item.bankAccount) }}</p>
        </v-list-item-action>
        <v-divider class="pa-0" />
        <v-list-item-action class="my-4">
          <v-card elevation="0" width="160"> Wallet Type :</v-card>
          <p class="">
            {{ item.walletType }}
            <v-btn
              v-show="buttonUpdateVisible"
              class="text-none ml-4"
              rounded="xl"
              color="primary"
              size="small"
              @click="showDialog = true"
            >
              Update
            </v-btn>
          </p>
          <v-dialog v-model="showDialog" max-width="500px">
            <v-alert border="top" type="warning" class="bg bg-white" variant="flat" prominent>
              Would you like to update your account to business account?
            </v-alert>
            <v-card>
              <v-card-actions>
                <v-btn color="blue darken-1" text @click="showDialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="updateWalletType"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list-item-action>
        <v-list-item-action class="my-4" v-show="descCrypto !== ''">  
          <v-card elevation="0" width="160"></v-card>
          <p style="font-size: smaller; opacity: 0.5; margin-top: -10px;" v-html="descCrypto"></p>
        </v-list-item-action>

        <v-list-item-action class="my-4">
          <v-card elevation="0" width="160"> Pin Code :</v-card>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-alert
                variant="outlined"
                color="error"
                v-if="message"
                :text="message"
                title="Error"
                border="end"
                density="compact"
                border-color="error"
                type="error"
              ></v-alert>

              <v-alert
                variant="outlined"
                color="success"
                type="success"
                v-if="success"
                :text="success"
                title="Success"
                border="end"
                density="compact"
                border-color="success"
              ></v-alert>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                @paste.prevent
                @keypress="handleChange($event)"
                :disabled="disabled"
                v-model="form.pin_code"
                variant="outlined"
                density="compact"
                autocomplete="off"
                placeholder="Enter your pin code"
                prepend-inner-icon="mdi-lock"
                :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                :rules="nameRulesPass"
                :type="visible ? 'text' : 'password'"
                @click:append-inner="visible = !visible"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-btn
                block
                :loading="loading"
                class="text-none"
                @click="setPinCode()"
              >
                {{ disabled ? " Set Pin Code" : "Update Pin Code" }}
              </v-btn>
            </v-col>
          </v-row>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { formatPrice } from "../../../utils/function";
import { SET_PIN_CODE, WALLET_TYPE } from "../../../utils/customerApiUrl";
import { URL_FEE } from "@/utils/apiUrl";

export default {
  name: "MyWallets",
  computed: {
    ...mapState(["wallet"]),
  },
  data() {
    return {
      formatPrice,
      showDialog: false,
      disabled: true,
      visible: false,
      loading: false,
      message: "",
      success: "",
      form: {
        pin_code: "",
        id: 0,
      },
      userStatus: "",
      buttonUpdateVisible: false,
      descCrypto: "",
    };
  },
  methods: {
    async updateWalletType() {
      this.loading = true;
      await this.axios
        .patch(WALLET_TYPE + this.form.id)
        .then((response) => {
          this.success = response.data.message;
          this.loading = false;
          this.showDialog = false;
          setTimeout(() => {
            this.message = "";
            this.success = "";
            this.$store.dispatch("getUserProfile");
          }, 4000);
        })
        .catch((error) => {
          this.message = error.response.data.message;
          this.loading = false;
        });
    },
    async descriptionCrypto() {
      await this.axios
        .get(URL_FEE)
        .then((response) => {
          this.descCrypto = response.data[0]?.desc_crypto;
        })
        .catch((error) => {
          this.message = error.response.data.message;
          this.loading = false;
        });
    },
    async setPinCode() {
      this.disabled = !this.disabled;

      if (this.disabled) {
        this.loading = true;
        //console.log(this.form);
        this.axios
          .post(SET_PIN_CODE, this.form)
          .then((response) => {
            //console.error("response to", response);
            this.success = response.data.message;
            this.loading = false;
            this.disabled = true;
            this.visible = false;
            setTimeout(() => {
              this.message = "";
              this.success = "";
              this.$store.dispatch("getUserProfile");
            }, 4000);
          })
          .catch((error) => {
            this.message = error.response.data.message;
            this.loading = false;
            //console.error("Failed to", error);
          });
      }
    },
    handleChange(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode;
      let pin = evt.target.value;
      if (pin.length > 5) {
        evt.preventDefault();
      }
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      }
    },
    formatBankAccount(number) {
      if (!number) return "";
      return number.replace(/(\d)(?=(\d{3})+$)/g, "$1 ");
    },
  },
  created() {
    this.descriptionCrypto();
    setTimeout(() => {
      const userProfile = this.$store.getters.getProfile;
      this.userStatus = userProfile.status;
      this.buttonUpdateVisible = userProfile.status === "done" ? true : false;
      this.form = {
        pin_code: this.wallet[0].pin_code,
        id: this.wallet[0].id,
      };
    }, 1000);
  },
};
</script>

<style scoped></style>
