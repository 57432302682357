<script>
import { getParams } from '@/utils/function';
import { DEFAULT_IMAGE, PROJECT_SEARCH } from '@/utils/customerApiUrl';
import { mapState } from 'vuex';

export default {
  name: 'ProjectSelect',
  computed:{
    ...mapState(['country'])
  },
  props:['callbackProject', 'isVariant', 'isTitle','isDensity', 'propertyAll'],
  data(){
    return {
      defaultImage : DEFAULT_IMAGE,
      search: '',
      list: [],
      variant: 'solo',
      title: 'Project Title',
      density: 'compact',
    };
  },
  methods:{
    async searchProjects() {
      this.list = [];
      let params = getParams({
        projectTitle: this.search,
        country: this.country,
        take: 10,
        propertyAll: this.propertyAll,
      });
      await this.axios
        .get(PROJECT_SEARCH + params)
        .then((response) => {
          let { data } = response.data; //console.log(data);
          this.list = data;
        })
        .catch((error) => {
          console.error('Failed to project page ', error);
        });
    },
    onFocus() {
      this.searchProjects();
    },
    onClear(){
      this.search = '';
      this.callbackProject({
        project_title: '',
      });
    },
    onFilterSearch(value){
      this.search = value.target.value;
      this.searchProjects();
    },
    getItemText(item) {
      return `${item.project_title} ${item.project_id}`;
    }
  },
  created() {
    this.variant = this.isVariant;
    this.title = this.isTitle;
    this.density = this.isDensity;
  },
  watch: {
    search: function(value) {
      if(value){
        this.callbackProject(value);
      }
    },
  }
};
</script>

<template>
  <p class="my-2">{{title}}</p>
  <v-autocomplete
    v-model="search"
    :items="list"
    elevation="0"
    rounded="xl"
    placeholder="search ..."
    :density="density"
    :variant="variant"
    menu-icon="mdi-chevron-right"
    hide-details
    @keyup="onFilterSearch"
    @focusin="onFocus"
    :item-title="getItemText"
    return-object
  >
    <template v-slot:chip="{ props, item }">
      <v-chip
        v-if="item.raw.project_title"
        v-bind="props"
        :prepend-avatar="item.raw.urlImage"
        :text="item.raw.project_title"
        closable
        @click:close="onClear()"
      ></v-chip>
    </template>
    <template v-slot:item="{ props, item }">
      <v-list-item v-bind="props"
                   v-if="item.raw.project_title"
                   :title="item.raw.project_title"
                   :subtitle="item.raw.project_id"
                   :prepend-avatar="item.raw.image_project === null ? defaultImage : item.raw.urlImage">
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<style scoped>

</style>