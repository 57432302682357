import { reactive } from 'vue';

const state = reactive({
  menuName: 'Portfolios',
});
const setMenuName = (menu) => {
  state.menuName = menu;
};
export default {
  state,
  setMenuName,
};