<script>
import { mapState } from 'vuex';

export default {
  name: 'CardNews',
  computed: {
    ...mapState([
      "project",
    ]),
  },
  data() {
    return {};
  },
};
</script>

<template>
  <div class="margin-auto">
    <br />
    <VCard class="py-8 elevation-0">
      <VRow>
        <VCol cols="12" md="6">
          <p class="my-10 text-8-line text-content text-control" v-html="project?.description">
          </p>
        </VCol>
        <VCol cols="12" md="6">
          <VCard height="400" rounded="xl" elevation="0">
            <v-img
              src="https://media.istockphoto.com/id/846489122/photo/responsive-web-design-website-wireframe-sketch-layout-on-computer-screen.jpg?s=612x612&w=0&k=20&c=mtPGkXm8Sj9AHcg1pEAF8tAdSpn-gRyb-UJnpsYn8bI="
              cover
            ></v-img>
          </VCard>
        </VCol>
      </VRow>
    </VCard>
  </div>
</template>

<style scoped>
.text-8-line {
  display: -webkit-box;        /* Enable flex container for webkit */
  -webkit-line-clamp: 8;       /* Limit to 3 lines */
  -webkit-box-orient: vertical; /* Set vertical box orientation */
  overflow: hidden;            /* Hide overflowing text */
  text-overflow: ellipsis;     /* Add "..." for truncated text */
}
</style>