<template>
  <v-row>
    <v-col
      cols="12"
      md="3"
      v-for="(row, index) in mainRewards"
      v-bind:key="row.title"
      class="pa-0"
    >
      <v-card
        height="170px"
        class="pa-2 text-center align-content-center"
        elevation="0"
        style="margin: 1px"
      >
        <h4>
          {{ row.title }}
        </h4>
        <h2 :class="index === 0 ? 'text-blue' : 'text-red'">
          $ {{ formatPrice(row.total) }}
        </h2>
      </v-card>
    </v-col>

    <v-col cols="12" md="6">
      <v-row>
        <v-col
          cols="12"
          md="4"
          v-for="row in rewards"
          v-bind:key="row.title"
          class="pa-0"
        >
          <v-card
            height="85px"
            class="pa-2 text-center align-content-center"
            elevation="0"
            style="margin: 1px"
          >
            <h5>
              {{ row.nameLevel }}
            </h5>
            <h4 class="text-red">$ {{ formatPrice(row._sum.amountReward) }}</h4>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <br />
  <h4 class="text-uppercase text-grey">Invitation Rewards</h4>
  <br />
  <InvitationRewards :moneyClaimLevel="moneyClaimLevel" :callBack="callBack"/>
  <br />

  <!--  ListRewards-->
  <v-row>
    <v-col cols="12" md="6">
      <div class="d-flex justify-start">
        <span class="ma-2">Show</span>

        <div>
          <v-select
            menu-icon=""
            v-model="params.take"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            :items="['10', '25', '50', '100', '500', '1000',]"
            class="elevation-0"
            color="blue"
          ></v-select>
        </div>

        <span class="ma-2">Entries</span>
      </div>
    </v-col>

    <v-col cols="12" md="3">
      <v-autocomplete
        placeholder="All Status"
        v-model="params.remark"
        :items="['All', 'pending', 'reject', 'done']"
        density="comfortable"
        rounded="xl"
        variant="outlined"
        class="elevation-0"
        color="blue"
      >
      </v-autocomplete>
    </v-col>

    <v-col cols="12" md="3">
      <div>
        <v-date-input
          prepend-icon=""
          v-model="dateRange"
          density="comfortable"
          rounded="xl"
          variant="outlined"
          class="elevation-0"
          append-inner-icon="mdi-calendar-range"
          placeholder="YYYY/MM/DD - YYYY/MM/DD"
          color="blue"
          multiple="range"
        ></v-date-input>
      </div>
    </v-col>

  </v-row>

  <v-card rounded="0">
    <v-data-table
      density="comfortable"
      hide-default-footer
      :loading="loading"
    >
      <v-card elevation="0" rounded="0" class="pa-5" v-if="transactions.length < 1">
        No record available.
      </v-card>

      <thead>
      <tr class="bg-primary">
        <th v-for="row in headers" v-bind:key="row.key" class="row-none-wrap">
          {{ row.title }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(level,index) in transactions" v-bind:key="index" class="row-none-wrap">

        <td class="td">
          {{ formatDate(level.createdAt) }}
        </td>
        <td class="td text-centers">
          {{ formatDate(level.createdAt, "HH:mm:ss") }} UTC
        </td>
        <td class="td text-centers">
          {{ level.nameLevel }}
        </td>
        <td class="td text-centers">
          {{ formatPrice(level.numberStar) }}
        </td>
        <td class="td text-centers">
          {{ level.currency }} {{ formatPrice(level.amountReward) }}
        </td>
      </tr>
      </tbody>
    </v-data-table>
  </v-card>

  <div class="app-paginate mt-5">
    <v-pagination
      v-model="params.skip"
      :length="totalPages"
      class="d-flex align-center"
      rounded="circle"
      color="primary"
      border
      total-visible="10"
    >
      <template #prev>
        <div class="d-flex">
          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip=1"
            :disabled="1 === totalPages"
            variant="outlined"
            border
            color="primary"
          >
            First
          </VBtn>

          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip--"
            :disabled="params.skip === 1"
            variant="outlined"
            border
            color="primary"
          >
            Previous
          </VBtn>
        </div>
      </template>
      <template #next>
        <div class="d-flex">
          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip++"
            :disabled="params.skip === totalPages"
            variant="outlined"
            border
            color="primary"
          >
            Next
          </VBtn>

          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip = totalPages"
            :disabled="params.skip === totalPages"
            variant="outlined"
            border
            color="primary"
          >
            Last
          </VBtn>
        </div>
      </template>
    </v-pagination>
  </div>
  <!--  ListRewards-->
</template>

<script>
import { formatPrice, formatDate, getParams } from '@/utils/function';
import { REFERRAL } from "@/utils/customerApiUrl";
import InvitationRewards from '@/views/client/profile/referral-rewards/rewards/InvitationRewards.vue';
import moment from 'moment';

let headers = [
  { align: 'start', key: 'date', title: 'Date' },
  { key: 'time', title: 'Time' },
  { key: 'referral_level', title: 'Referral Level' },
  { key: 'referrals', title: 'Referrals' },
  { key: 'rewards', title: 'Rewards' },
];

export default {
  name: "MyRewardsTab",
  components: {
    InvitationRewards,
    // ListRewards
  },
  data() {
    return {
      //
      formatDate: formatDate,
      formatPrice: formatPrice,
      //
      mainRewards: [
        {
          title: " Successful Referrals",
          total: "0",
        },
        {
          title: " Total Rewards",
          total: "0",
        },
      ],
      //
      rewards: [
        {
          nameLevel: "Ordinary Rewards",
          _sum: {
            amountReward: "0",
          },
        },
        {
          nameLevel: "1 Start Rewards",
          _sum: {
            amountReward: "0",
          },
        },
        {
          nameLevel: "2 Start Rewards",
          _sum: {
            amountReward: "0",
          },
        },
        {
          nameLevel: "3 Start Rewards",
          _sum: {
            amountReward: "0",
          },
        },
        {
          nameLevel: "4 Start Rewards",
          _sum: {
            amountReward: "0",
          },
        },
        {
          nameLevel: "5 Start Rewards",
          _sum: {
            amountReward: "0",
          },
        },
      ],
      // models invitations
      invitation_rewards: [
        {
          title: "Ordinary Referrals",
          number: 9,
          listClaim: [
            {
              referral: "5 Refs",
              price: "1",
              claim: true,
            },
            {
              referral: "10 Refs",
              price: "3",
              claim: false,
            },
            {
              referral: "25 Refs",
              price: "9",
              claim: false,
            },
            {
              referral: "50 Refs",
              price: "20",
              claim: false,
            },
            {
              referral: "75 Refs",
              price: "30",
              claim: false,
            },
            {
              referral: "100 Refs",
              price: "50",
              claim: false,
            },
          ],
        },
        {
          title: "1 Start Referrals",
          number: "30",
          listClaim: [
            {
              referral: "5 Refs",
              price: "10",
              claim: false,
            },
            {
              referral: "10 Refs",
              price: "20",
              claim: false,
            },
            {
              referral: "25 Refs",
              price: "150",
              claim: false,
            },
            {
              referral: "50 Refs",
              price: "400",
              claim: false,
            },
            {
              referral: "75 Refs",
              price: "750",
              claim: false,
            },
            {
              referral: "100 Refs",
              price: "1200",
              claim: false,
            },
          ],
        },
        {
          title: "2 Start Referrals",
          number: "0",
          listClaim: [
            {
              referral: "5 Refs",
              price: "100",
              claim: false,
            },
            {
              referral: "10 Refs",
              price: "400",
              claim: false,
            },
            {
              referral: "25 Refs",
              price: "1500",
              claim: false,
            },
            {
              referral: "50 Refs",
              price: "4000",
              claim: false,
            },
            {
              referral: "75 Refs",
              price: "7500",
              claim: false,
            },
            {
              referral: "100 Refs",
              price: "12000",
              claim: false,
            },
          ],
        },
        {
          title: "3 Start Referrals",
          number: "15",
          listClaim: [
            {
              referral: "5 Refs",
              price: "1000",
              claim: false,
            },
            {
              referral: "10 Refs",
              price: "4000",
              claim: false,
            },
            {
              referral: "25 Refs",
              price: "15000",
              claim: false,
            },
            {
              referral: "50 Refs",
              price: "40000",
              claim: false,
            },
            {
              referral: "75 Refs",
              price: "75000",
              claim: false,
            },
            {
              referral: "100 Refs",
              price: "120000",
              claim: false,
            },
          ],
        },
        {
          title: "4 Start Referrals",
          number: "0",
          listClaim: [
            {
              referral: "5 Refs",
              price: "3000",
              claim: false,
            },
            {
              referral: "10 Refs",
              price: "12000",
              claim: false,
            },
            {
              referral: "25 Refs",
              price: "45000",
              claim: false,
            },
            {
              referral: "50 Refs",
              price: "120000",
              claim: false,
            },
            {
              referral: "75 Refs",
              price: "225000",
              claim: false,
            },
            {
              referral: "100 Refs",
              price: "360000",
              claim: false,
            },
          ],
        },
        {
          title: "5 Start Referrals",
          number: "0",
          listClaim: [
            {
              referral: "5 Refs",
              price: "5000",
              claim: false,
            },
            {
              referral: "10 Refs",
              price: "20000",
              claim: false,
            },
            {
              referral: "25 Refs",
              price: "75000",
              claim: false,
            },
            {
              referral: "50 Refs",
              price: "200000",
              claim: false,
            },
            {
              referral: "75 Refs",
              price: "357000",
              claim: false,
            },
            {
              referral: "100 Refs",
              price: "600000",
              claim: false,
            },
          ],
        },
      ],
      //
      row: {},
      nameLevel: "Ordinary Rewards",
      params: {
        take: 10,
        skip: 1,
        remark: null,
        startDate:  "",
        endDate: "",
      },
      transactions: [],
      total: 0,
      totalPages: 0,
      referralClaims: 0,
      moneyClaimLevel: [],
      loading: false,
      //
      dateRange: null,
      //
      headers: headers,
    };
  },
  methods: {
    async fetchData() {
      let params = getParams(this.params);
      this.loading = true;
      this.axios
        .get(REFERRAL + params)
        .then((response) => {
          let { transactions,
            rewards,
            total,
            referralClaims,
            moneyClaimLevel,
            customerReferralReward ,
            totalPages
            //totalPages
          } = response.data;
          ///console.log(response.data.data);
          this.transactions = transactions;
          this.total = total;
          this.referralClaims = referralClaims;
          this.totalPages = totalPages;
          if (rewards) {
            this.rewards = rewards;
            let amountReward = 0;
            for (let reward in rewards) {
              amountReward += rewards[reward]._sum.amountReward;
            }
            this.mainRewards[1].total = amountReward;
          }

          /**
           * calculate find referral not claims
           */
          moneyClaimLevel.forEach((row) => {
            row.listClaim = [
              /**
               * person 1
               */
              {
                referral: row.numberPerson1 + " Refs",
                price: row.moneyClaim1,
                claim: customerReferralReward.filter(x => x.nameLevel === row.nameLevel && x.numberStar === row.numberPerson1).length > 0,
              },
              /**
               * person 2
               */
              {
                referral: row.numberPerson2 + " Refs",
                price: row.moneyClaim2,
                claim: customerReferralReward.filter(x => x.nameLevel === row.nameLevel && x.numberStar === row.numberPerson2).length > 0,
              },
              /**
               * person 3
               */
              {
                referral: row.numberPerson3 + " Refs",
                price: row.moneyClaim3,
                claim: customerReferralReward.filter(x => x.nameLevel === row.nameLevel && x.numberStar === row.numberPerson3).length > 0,
              },
              /**
               * person 4
               */
              {
                referral: row.numberPerson4 + " Refs",
                price: row.moneyClaim4,
                claim: customerReferralReward.filter(x => x.nameLevel === row.nameLevel && x.numberStar === row.numberPerson4).length > 0,
              },
              /**
               * person 5
               */
              {
                referral: row.numberPerson5 + " Refs",
                price: row.moneyClaim4,
                claim: customerReferralReward.filter(x => x.nameLevel === row.nameLevel && x.numberStar === row.numberPerson4).length > 0,
              },
              /**
               * person 6
               */
              {
                referral: row.numberPerson6 + " Refs",
                price: row.moneyClaim6,
                claim: customerReferralReward.filter(x => x.nameLevel === row.nameLevel && x.numberStar === row.numberPerson6).length > 0,
              }
            ];
            //console.log(row);
            //console.log(customerReferralReward);
          });
          this.moneyClaimLevel = moneyClaimLevel;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Failed to", error);
        });
    },

    callBack(){
      this.fetchData();
    }
  },
  created() {
    this.fetchData();
  },
  watch: {
    // filter page
    'params.skip': function() {
      this.fetchData();
    },
    'params.take': function() {
      this.fetchData();
    },
    'params.remark': function(remark) {
      if (remark === 'All'){
        this.params.remark = '';
      }
      this.fetchData();
    },
    'dateRange': function(dateRange) {
      if (dateRange && dateRange.length > 0) {
        let startDate = dateRange[0];
        let endDate = dateRange[dateRange.length - 1];
        this.params.startDate = moment(startDate).format('YYYY-MM-DD');
        this.params.endDate = moment(endDate).format('YYYY-MM-DD');
        this.fetchData();
      }
    },
  },
};
</script>

<style scoped>
</style>
