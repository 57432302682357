<template>
  <div class="margin-auto">
    <br />
    <div class="text-center">
      <h2 class="text-uppercase text-primary">Feature Projects</h2>
      <br />
      <p class="text-content text-control" v-html="project?.description"></p>
    </div>
    <br />

<!--        <pre>-->
<!--          {{featureProject}}-->
<!--        </pre>-->

    <div>
      <VRow>
        <VCol md="4" sm="6" cols="12" v-for="row in featureProject" v-bind:key="row.id">
          <v-card class="ma-1" min-width="" rounded="xl" height="500">
            <v-img
              :src="row?.urlImage"
              height="300"
              lazy-src="https://assets-v2.lottiefiles.com/a/d0c1a50a-1171-11ee-bc55-77e80401811a/LgJcg4Igt2.gif"
              cover
              class="d-flex align-end pa-5z"
            >
              <div class="px-3 card-item-blur">
                <h4 class="text-orange text-uppercase font-weight-regular">
                  {{ (row?.Country?.name) ?? "Country" }}
                </h4>

                <h5
                  class="text-white three-line-title text-control"
                  v-html="trimSpace(row?.project_title)"
                ></h5>
              </div>
            </v-img>

            <div class="d-flex justify-space-around my-2">
              <p>Abbr.text</p>
              <p>|</p>
              <p>
                {{ trimSpace(row?.procurement_category) ?? "Pro Category " }}
              </p>
              <p>|</p>
              <p>
                {{
                  row?.board_approval_date
                    ? formatDate(row?.board_approval_date, "MMM DD, YYYY")
                    : "."
                }}
              </p>
            </div>

            <VDivider />
            <v-card-text>
              <div class="description-height">
                <h4
                  class="font-weight-regular text-three-line text-control"
                  v-html="trimSpace(row?.description_project)"
                ></h4>
              </div>
              <br />
              <router-link to="/projects" class="text-decoration-none">
                <h4 class="text-primary font-weight-bold text-uppercase">
                  Read more
                  <VIcon>mdi-arrow-right</VIcon>
                </h4>
              </router-link>
              <br />
            </v-card-text>
          </v-card>
        </VCol>
        <VCol md="12" sm="12"></VCol>
        <VCol md="4" sm="6">
          <router-link to="/projects" class="text-decoration-none">
            <VBtn
              rounded="xl"
              size="x-large"
              varian="text"
              height="60"
              block
              color="primary"
            >
              <h3>Explore For more</h3>
            </VBtn>
          </router-link>
        </VCol>
      </VRow>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { formatDate } from "@/utils/function";

export default {
  name: "ExploreMore",
  props: ["exploreMores"],
  computed: {
    ...mapState(["project", "featureProject"]),
  },
  data() {
    return {
      formatDate: formatDate,
    };
  },
  methods: {
   trimSpace(text){
     return text ? text.trim() : '...';
   }
  }
};
</script>
<style scoped>
.description-height {
  height: 60px;
}
</style>
