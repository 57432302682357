<script>

import ProjectFacts from '@/views/client/projects/components/ProjectFacts.vue';
import FrontendLayout from '@/views/client/components/FrontendLayout.vue';
import RelatedNews from '@/views/client/projects/components/RelatedNews.vue';
import CardShareLink from '@/views/client/projects/components/CardShareLink.vue';
import { formatDate, formatPrice, formatPriceCurrency, getParams } from '@/utils/function';
import { API_URL_USER, PROJECT_BY_ID } from '@/utils/customerApiUrl';

export default {
  name: 'ProjectDetailsPage',
  components: {
    CardShareLink,
    RelatedNews,
    ProjectFacts,
    FrontendLayout
  },
  data() {
    return {
      country: "",
      formatDate: formatDate,
      formatPrice: formatPrice,
      project: null,
      formatPriceCurrency: formatPriceCurrency,
    };
  },
  methods: {

    async getLocation() {
      await fetch(API_URL_USER)
        .then((response) => response.json())
        .then((data) => {
          this.country = data.country_name ? data.country_name: data.name;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async fetchData() {
      let country = this.country;
      let projectId = sessionStorage.getItem('projectId');
      let investmentCheck = sessionStorage.getItem('invest');
      if(projectId) {
        let params = getParams({
          projectId: projectId,
          country: country,
        });
        this.axios
          .get(PROJECT_BY_ID + params)
          .then((response) => {
            // console.log(response);
            let { data } = response.data;
            this.project = { ...data, investmentCheck: (investmentCheck) };
          })
          .catch((error) => {
            console.error('Failed to', error);
          });
      }
    },

  },

  mounted() {
    this.getLocation();
  },

  created() {
    setTimeout(() => {
      this.fetchData();
    }, 1000);
  },
};
</script>

<template>
  <FrontendLayout>

    <div class="bg-grey-lighten-4">
      <VCard min-height="200" rounded="0">
        <div class="text-center py-5">
          <h3 class="text-orange text-uppercase">
            Project | Country
          </h3>
          <br />
          <h1 class="text-primary">
            {{ project?.procurement_category }}
          </h1>
          <br />
          <h3 class="text-blue font-weight-regular">
            <span class="text-capitalize">$ {{ formatPriceCurrency(project?.all_amount_invest) }}</span> | {{ project?.Sector?.name }} |
            {{ project?.project_brief }} | {{ project?.project_status }}
          </h3>
        </div>
      </VCard>

      <VRow class="margin-auto">
        <VCol cols="12" md="7">
          <h2>
            Project Description
          </h2>
          <br />
          <p v-html="project?.description_project ">
          </p>
          <br />
          <p>
            The Guarantee Was Cancelled On {{ formatDate(project?.date_spg_disclosed, 'MMM DD, YYYY') }}.
          </p>

<!--          <pre>-->
<!--            {{project}}-->
<!--          </pre>-->

        </VCol>

        <VCol cols="12" md="5">
          <template v-if="project">
            <ProjectFacts :project="project" :fetchData="fetchData" />
          </template>

          <RelatedNews />

          <CardShareLink />
        </VCol>
      </VRow>

      <br />
    </div>

  </FrontendLayout>
</template>

<style scoped>

</style>