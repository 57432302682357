<template>
  <ProjectClientComponent title="Projects"/>
</template>

<script>

import ProjectClientComponent from '@/views/client/projects/ProjectClientComponent.vue';

export default {
  name: 'ProjectPage',
  components: { ProjectClientComponent },
};
</script>

<style scoped></style>
