<template>
  <v-card flat>
    <template v-slot:text>
      <v-row>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-select
            v-if="(isAllCountry === 1) | (isAdmin === 1)"
            v-model="countryId"
            :options="countries"
            variant="outlined"
            density="compact"
            prepend-inner-icon="mdi-flag-outline"
            required
            autocomplete="off"
            persistent-placeholder
            placeholder="Choose customer..."
            class="style-chooser"
            :reduce="(country) => country.code"
            @update:modelValue="filterItems"
          >
          </v-select>

          <!-- NOTE: when we use @update.movelValue or :on-change the data return to json 
                                  but else the data return as value normally -->
          <v-select
            v-else
            v-model="countryId"
            :options="countries"
            variant="outlined"
            density="compact"
            prepend-inner-icon="mdi-flag-outline"
            required
            autocomplete="off"
            persistent-placeholder
            placeholder="Choose customer..."
            class="style-chooser"
            disabled
            :reduce="(country) => country.code"
            @update:modelValue="filterItems"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="9" lg="9" xl="9">
          <v-text-field
            v-model="search"
            label="Search block withdraw..."
            prepend-inner-icon="mdi-magnify"
            variant="outlined"
            hide-details
            single-line
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor" location="top">
      {{ snackText }}
    </v-snackbar>
    <v-data-table
      color="#b2d7ef"
      rounded="compact"
      divided
      striped
      hover
      class="elevation-1"
      fixed-header
      :search="search"
      :headers="headers"
      :items="blockWithdraws"
      v-model="blockWithdrawSelected"
      :items-per-page="itemsPerPage"
      v-model:page="page"
      show-select
      :sort-by="[{ key: 'created_at', order: 'desc' }]"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ props }">
            <v-row>
              <v-col cols="6" md="6" lg="6" xl="6" class="d-flex align-center mb-4">
                <v-btn
                  class="mb-2 ml-4 mr-2"
                  color="primary"
                  v-bind="props"
                  prepend-icon="mdi-plus"
                  variant="flat"
                >
                  <div class="text-none font-weight-regular">Add</div>
                </v-btn>
                <v-btn
                  class="mb-2 mr-2"
                  color="error"
                  prepend-icon="mdi-code-block-parentheses"
                  variant="flat"
                  @click="openDialogEditMulti"
                >
                  <div class="text-none font-weight-regular">Block/Unblock</div>
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="text-right d-flex mb-6 flex-row-reverse"
              >
                <v-sheet class="ma-2 pa-2 text-center">
                  <v-select
                    v-model="itemsPerPage"
                    :options="[10, 25, 50, 100, 1000]"
                    hide-details
                    density="compact"
                    variant="outlined"
                    @update:model-value="itemsPerPage = parseInt($event, 10)"
                    style="width: 100px"
                  ></v-select>
                </v-sheet>
                <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-toolbar>
              <v-toolbar-title prepend-icon="mdi-form-textbox">{{
                formTitle
              }}</v-toolbar-title>

              <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
            </v-toolbar>
            <v-divider class="mb-0"></v-divider>
            <v-divider class="mb-0"></v-divider>
            <v-form ref="form" enctype="multipart/form-data" lazy-validation>
              <v-card-text>
                <v-alert
                  border="end"
                  border-color="warning"
                  elevation="2"
                  color="error"
                  v-show="!visible"
                >
                  {{ msgError }}
                </v-alert>
                <v-row class="mt-1">
                  <v-col cols="12" sm="9" md="9" lg="9" xl="9">
                    <v-autocomplete
                      :rules="[() => !!customerId || 'This field is required']"
                      required
                      density="compact"
                      variant="outlined"
                      v-model="customerId"
                      :items="customers"
                      :item-title="'name'"
                      :item-value="'id'"
                      menu-icon="mdi-chevron-right"
                      persistent-placeholder
                      placeholder="Choose customer..."
                      :multiple="editedIndex === -1"
                      multi-line
                      label="Customer"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index < 6">
                          <span>{{ item.title }}</span>
                        </v-chip>
                        <span
                          v-if="index === 6"
                          class="text-grey text-caption align-self-center"
                        >
                          (+{{ customerId.length - 6 }} others)
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-switch
                      v-model="block_withdraw"
                      color="success"
                      :label="isBlock"
                      hide-details
                      required
                      v-bind:false-value="0"
                      v-bind:true-value="1"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <QuillEditor
                      toolbar="essential"
                      contentType="html"
                      style="height: 300px"
                      v-model:content="message_block"
                      theme="snow"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
            </v-form>

            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                text="Cancel"
                @click="close"
                variant="flat"
                color="error"
              ></v-btn>

              <v-btn
                class="text-none"
                color="primary"
                rounded="xl"
                :text="buttonTitle"
                variant="flat"
                @click="save"
                :disabled="isSubmitting"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogEditMulti" max-width="500px">
          <v-card>
            <v-divider class="mb-0"></v-divider>
            <v-divider class="mb-0"></v-divider>
            <v-form ref="form" enctype="multipart/form-data" lazy-validation>
              <v-card-text>
                <v-row class="mt-1">
                  <v-col cols="12">
                    <v-switch
                      v-model="edit_multi_block_withdraw"
                      color="success"
                      :label="isEditMultiBlock"
                      hide-details
                      required
                      v-bind:false-value="0"
                      v-bind:true-value="1"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <QuillEditor
                      toolbar="essential"
                      contentType="html"
                      style="height: 300px"
                      v-model:content="edit_multi_message_block"
                      theme="snow"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
            </v-form>
            <input type="hidden" v-model="hiddenId" />
            <input type="hidden" v-model="customerHiddenId" />
            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                text="Cancel"
                color="error"
                variant="flat"
                @click="closeDialogEditMulti"
              ></v-btn>

              <v-btn
                class="text-none"
                color="blue-darken-1"
                rounded="xl"
                text="Update Multi"
                variant="flat"
                @click="editMulitBlockWithdraw"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- detect when error  -->
      <template v-slot:no-data>
        <v-alert
          v-if="items.length > 0"
          :value="true"
          color="error"
          icon="warning"
          class="text-left"
        >
          No data found.
        </v-alert>
      </template>
      <!-- end detect when error  -->
      <!-- Custom column table -->
      <template v-slot:[`item.Customer.profile`]="{ item }">
        <v-avatar size="64" class="my-1" v-if="item.Customer && item.Customer.profile">
          <v-img :src="path + item.Customer.profile" cover>
            <template v-slot:placeholder>
              <v-row align="center" class="fill-height ma-0" justify="center">
                <v-progress-circular
                  color="grey-lighten-5"
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-avatar>
        <v-avatar
          class="my-1"
          v-else
          image="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
          size="80"
        >
          <template v-slot:placeholder>
            <v-row align="center" class="fill-height ma-0" justify="center">
              <v-progress-circular
                color="grey-lighten-5"
                indeterminate
              ></v-progress-circular>
            </v-row>
          </template>
        </v-avatar>
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        <v-icon icon="mdi-clipboard-text-clock"></v-icon>
        {{ new Date(item.createdAt).toLocaleString() }}
      </template>
      <template v-slot:[`item.message_block`]="{ item }">
        <div
          v-html="
            item.message_block !== null
              ? item.message_block.length > 50
                ? item.message_block.substring(0, 50) + '...'
                : item.message_block
              : ''
          "
        ></div>
      </template>
      <template v-slot:[`item.block_withdraw`]="{ item }">
        <div v-if="item.block_withdraw" class="text-center">
          <v-icon color="success" icon="mdi-checkbox-marked-outline"></v-icon>
        </div>
        <div v-else class="text-center">
          <v-icon color="error" icon="mdi-close-box-outline"></v-icon>
        </div>
      </template>
      <template v-slot:[`item.Customer.email`]="{ item }">
        <div v-if="item.Customer && item.Customer.email" class="text-center">
          <a :href="'mailto:' + item.Customer.email">{{ item.Customer.email }}</a>
        </div>
        <div v-else class="text-center">N/A</div>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ new Date(item.created_at).toLocaleString() }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="ma-2" size="x-small" color="red-lighten-2" @click="editItem(item)">
          <v-icon icon="mdi-pencil" start></v-icon>
          Edit
        </v-btn>
        <!--
          <v-icon title="Edit" class="me-2" size="small" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon title="Delete" size="small" @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            rounded="circle"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </div>
      </template>
      <!-- End custom column table -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import {
  Path_Customer_Image,
  URL_BLOCK_WITHDRAW,
  URL_COUNTRY,
  URL_CUSTOMER_CONFIRMATION,
  URL_UPDATE_MULTI_BLOCK_WITHDRAW,
} from "@/utils/apiUrl";
import { TOKEN_HEADERS } from "@/utils/headerToken";
import { QuillEditor } from "@vueup/vue-quill";
import vSelect from "vue-select";
import { originalCode } from "@/utils/formatNumber";

export default {
  components: {
    QuillEditor,
    vSelect,
  },
  data: () => ({
    path: Path_Customer_Image,
    snack: false,
    dialog: false,
    dialogEditMulti: false,
    visible: true,
    isSubmitting: false,

    items: [],
    customers: [],
    countries: [{ code: 0, label: "-- All --" }],
    blockWithdraws: [],
    blockWithdrawSelected: [],
    customerId: [],

    validation: {
      min: 0,
      max: 10,
      decimal: 10,
    },

    search: null,
    snackColor: null,
    snackText: null,
    msgError: null,
    block_withdraw: 1,
    message_block: null,
    countryId: null,

    edit_multi_block_withdraw: 0,
    edit_multi_message_block: null,

    editedIndex: -1,
    hiddenId: 0,
    customerHiddenId: 0,

    page: 1,
    itemsPerPage: 10,

    isAdmin: originalCode(parseInt(localStorage.getItem("admin"))),
    isAllCountry: originalCode(parseInt(localStorage.getItem("allCountry"))),
    staffCountryId: originalCode(parseInt(localStorage.getItem("countryId"))),
    staffId: originalCode(parseInt(localStorage.getItem("id"))),

    headers: [
      { title: "", key: "actions", sortable: false, align: "center" },
      { title: "", key: "Customer.profile", align: "center" },
      { title: "Customer", key: "Customer.name", align: "center" },
      { title: "Phone", key: "Customer.phone", align: "center" },
      { title: "Email", key: "Customer.email", align: "center" },
      { title: "Block?", key: "block_withdraw", align: "center" },
      { title: "Message", key: "message_block", align: "center" },
      { title: "Create At", key: "created_at", align: "center" },
      { title: "Created by", key: "createdBy.fullName", align: "center" },
      { title: "Updated by", key: "staff.fullName", align: "center" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Block Withdraw" : "Update Block Withdraw";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
    isBlock() {
      return this.block_withdraw === 1 ? "Block" : "No Blocking";
    },
    isEditMultiBlock() {
      return this.edit_multi_block_withdraw === 1 ? "Block" : "No Blocking";
    },
    pageCount() {
      return Math.ceil(this.blockWithdraws.length / this.itemsPerPage);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogEditMulti(val) {
      val || this.closeDialogEditMulti();
    },
  },

  created() {
    this.PopulateCountries();
    this.PopulateCustomers();
    this.filterItems();
  },
  methods: {
    clearContent() {
      this.hiddenId = 0;
      this.customerId = [];
      this.customerHiddenId = 0;
      this.block_withdraw = 1;
      this.message_block = null;
      this.visible = true;
    },

    isAdminOrAllCountryPermission() {
      return (this.isAdmin || this.isAllCountry) === 1 ? true : false;
    },

    async filterItems() {
      var searchCountryId = null;
      if (this.countryId !== 0) {
        searchCountryId = this.countryId === undefined ? null : this.countryId;
      } else {
        if (this.countryId === null) {
          searchCountryId = this.isAdminOrAllCountryPermission()
            ? null
            : this.staffLoginCountryId;
        } else {
          searchCountryId = null;
        }
      }

      this.PopulateblockWithdrawsByCountry(searchCountryId);
    },

    async PopulateblockWithdrawsByCountry(countryId) {
      var staffId = this.isAdminOrAllCountryPermission() ? 0 : this.staffId;
      await axios
        .get(URL_BLOCK_WITHDRAW + staffId, { headers: TOKEN_HEADERS })
        .then((res) => {
          this.blockWithdraws = res.data.filter((item) => {
            const itemCountryId = item.createdBy.country.id;
            const matches = !countryId || itemCountryId === countryId; //searchCountryId == countryId;
            return matches;
          });

          this.items.push(this.blockWithdraws);
        });
    },

    editItem(item) {
      this.editedIndex = this.blockWithdraws.indexOf(item);
      this.hiddenId = item.id;
      //this.editedItem = Object.assign({}, item);
      //this.customerId = item.Customer.name;
      //this.customerId = item.customerId;
      this.customerId = [item.customerId];
      this.customerHiddenId = item.customerId;
      this.block_withdraw = item.block_withdraw;
      this.message_block = item.message_block;

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.blockWithdraws.indexOf(item);
      this.hiddenId = item.id;

      this.dialogEditMulti = true;
    },

    editMulitBlockWithdraw() {
      this.blockWithdrawSelected.forEach(async (key) => {
        const blockWithdrawId = parseInt(key);
        const block = this.edit_multi_block_withdraw;

        const data = {
          block_withdraw: parseInt(block),
          message_block: this.edit_multi_message_block,
        };

        await axios
          .patch(URL_UPDATE_MULTI_BLOCK_WITHDRAW + blockWithdrawId, data, {
            headers: TOKEN_HEADERS,
          })
          .then((response) => {
            const message =
              block === 1 ? response.data.message : "Record has been unblocked.";
            this.snakeMessage("success", message);
            this.filterItems();
            this.edit_multi_block_withdraw = 0;
            this.edit_multi_message_block = null;
          });
      });

      this.blockWithdrawSelected = [];
      this.closeDialogEditMulti();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    closeDialogEditMulti() {
      this.dialogEditMulti = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    openDialogEditMulti() {
      this.dialogEditMulti =
        this.blockWithdrawSelected.length > 0
          ? true
          : this.snakeMessage("warning", "Block withdraw at least select one record.");
    },

    async submitData() {
      if (this.customerId.length === 0) {
        this.visible = false;
        this.msgError = "Please choose customer!!!!!";
        return false;
      }

      var countryId = null;
      const block = this.block_withdraw;
      const data = {
        block_withdraw: parseInt(block),
        message_block: this.message_block,
      };

      if (this.editedIndex > -1) {
        //edit

        data.customerId = parseInt(this.customerId);
        const id = this.hiddenId;
        const url = URL_BLOCK_WITHDRAW + id;
        data.updated_by = this.staffId;

        await axios
          .patch(url, data, { headers: TOKEN_HEADERS })
          .then((response) => {
            this.snakeMessage("success", response.data.message);
            this.clearContent();
            this.dialog = false;
          })
          .catch(
            (error) => {
              this.visible = false;
              if (error.response.data.statusCode === 400) {
                //this.snakeMessage("error", error.response.data.message);
                this.msgError = error.response.data.message;
              } else if (error.request) {
                // The request was made but no response was received
                //this.snakeMessage("error", error.request);
                this.msgError = error.request;
                // Something happened in setting up the request that triggered an Error
                //this.snakeMessage("error", error.message);
                this.msgError = error.message;
              }

              this.dialog = true;
            }
            //console.error("record updated was an error!", error)
          );

        //this.close();
      } // create new
      else {
        this.customerId.forEach(async (key) => {
          data.customerId = key;
          data.staffCreatedId = this.staffId;

          await axios
            .post(URL_BLOCK_WITHDRAW, data, { headers: TOKEN_HEADERS })
            .then((response) => {
              this.snakeMessage("success", response.data.message);
              this.clearContent();
              this.dialog = false;
            })
            .catch(
              (error) => {
                this.visible = false;
                if (error.response.data.statusCode === 400) {
                  //this.snakeMessage("error", error.response.data.message);
                  this.msgError = error.response.data.message;
                } else if (error.request) {
                  // The request was made but no response was received
                  //this.snakeMessage("error", error.request);
                  this.msgError = error.request;
                  // Something happened in setting up the request that triggered an Error
                  //this.snakeMessage("error", error.message);
                  this.msgError = error.message;
                }

                this.dialog = true;
              }
              //console.error("record updated was an error!", error)
            );

          countryId = this.isAdminOrAllCountryPermission() ? null : this.staffCountryId;
          this.PopulateblockWithdrawsByCountry(countryId);
        });
      }

      //this.clearContent();
      //this.PopulateblockWithdraws();
      countryId = this.isAdminOrAllCountryPermission() ? null : this.staffCountryId;
      this.PopulateblockWithdrawsByCountry(countryId);
    },

    async save() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        if (this.isSubmitting) return; // Prevent multiple submissions
        this.isSubmitting = true;

        try {
          // Send your form data to the server
          await this.submitData();
        } catch (error) {
          console.error(error);
        } finally {
          this.isSubmitting = false; // Re-enable the button after the request
        }
        //this.dialog = false;
      }
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },

    PopulateCustomers() {
      let codeCountry = "-- All --";
      axios
        .get(URL_CUSTOMER_CONFIRMATION + `?status=All&countryCode=${codeCountry}`, {
          headers: TOKEN_HEADERS,
        })
        .then((response) => {
          response.data.forEach((cus) => {
            this.customers.push({
              id: cus.id,
              name: cus.name,
            });
          });
        });
    },

    formatNumber(e) {
      if (e > this.validation.max) {
        return this.validation.max;
      } else if (e < this.validation.min) {
        return this.validation.min;
      } else if (
        Math.round(e * this.validation.decimal) / this.validation.decimal !==
        e
      ) {
        return this.lastValue;
      } else {
        this.lastValue = e;
        return e;
      }
    },

    PopulateCountries() {
      axios.get(URL_COUNTRY, { headers: TOKEN_HEADERS }).then((response) => {
        response.data.forEach((country) => {
          this.countries.push({
            label: country.name,
            code: country.id,
          });
        });

        this.countryId = this.isAdminOrAllCountryPermission() ? 0 : this.staffCountryId;
      });
    },
  },
};
</script>
<style>
@import "vue-select/dist/vue-select.css";
@import "@vueup/vue-quill/dist/vue-quill.snow.css";

.style-chooser .vs__clear,
.style-chooser {
  fill: #394066;
  height: 46px;
}
</style>
