<script>
import { CUSTOMER_INVEST_TRANSACTION } from '@/utils/customerApiUrl';
import { formatDate, formatPrice, getParams } from '@/utils/function';
import moment from 'moment/moment';

export default {
  name: 'FundDetailsTab',
  data(){
    return {
      formatPrice: formatPrice,
      formatDate : formatDate,
      dateRange: null,
      params: {
        take: 10,
        skip: 1,
        projectId: "",
        startDate: "",
        endDate: "",
        status: "",
        type: '',
        search : '',
      },
      loading: false,
      totalPages: 0,
      total: 0,
      data: [],
      headers: [
        { align: 'start', key: 'id', sortable: false, title: 'Transaction'},
        { key: 'status', title: 'Status' },
        { key: 'amount', title: 'Amount' },
      ],
    };
  },
  methods: {

    async fetchData() {
      this.loading = true;
      let params = getParams(this.params);
      await this.axios
        .get(CUSTOMER_INVEST_TRANSACTION + params)
        .then((response) => {
          let { data, total, totalPages } = response.data;
          ///console.log(response.data.data);
          this.data = data;
          this.total = total;
          this.totalPages = totalPages;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Failed to", error);
        });
    },
  },
  created() {
    this.fetchData();
  },
  watch:{
    // filter page
    'params.skip': function() {
      this.fetchData();
    },
    'params.take': function() {
      this.fetchData();
    },
    'params.status': function(status) {
      if(status.includes('All')){
        this.params.status = '';
      }
      this.fetchData();
    },
    'params.search': function(search) {
      this.params.search = search;
      this.fetchData();
    },
    'dateRange': function(dateRange) {
      if (dateRange && dateRange.length > 0) {
        let startDate = dateRange[0];
        let endDate = dateRange[dateRange.length - 1];
        this.params.startDate = moment(startDate).format('YYYY-MM-DD');
        this.params.endDate = moment(endDate).format('YYYY-MM-DD');
        this.fetchData();
      }
    },
  }
};
</script>

<template>
<!--    <pre>-->
<!--      {{data[0]}}-->
<!--    </pre>-->
  <v-card class="pa-5" rounded="0" min-height="140" elevation="0" border>
    <v-row>
      <v-col cols="12" md="3">
        <p class="ma-2">Choose Date Range</p>

        <div>
          <v-date-input
            prepend-icon=""
            v-model="dateRange"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            class="elevation-0"
            append-inner-icon="mdi-calendar-range"
            placeholder="YYYY/MM/DD - YYYY/MM/DD"
            color="blue"
            multiple="range"
          ></v-date-input>
        </div>

      </v-col>

      <v-col cols="12" md="3">
        <p class="ma-2">Select Transition</p>

        <div>
          <v-autocomplete
            menu-icon="mdi-chevron-right"
            v-model="params.type"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            :items="[
              'All',
              'Transfer',
              'Deposit',
              'Withdraw',
              'Referral Rewards',
              'Interest',
              'Return Principle',
              'Investment',
              'Crowdfunding Investment',
              'Crowdfunding Interest',
              'Fee',
              'Exchange'
            ]"
            class="elevation-0"
          ></v-autocomplete>
        </div>

      </v-col>


      <v-col cols="12" md="2">
        <p class="ma-2">Select Status</p>

        <div>
          <v-autocomplete
            menu-icon="mdi-chevron-right"
            v-model="params.status"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            :items="[
              'All',
              'Successful',
              'Fail',
              'Pending',
            ]"
            class="elevation-0"
            color="blue"
          ></v-autocomplete>
        </div>

      </v-col>

    </v-row>
  </v-card>

  <br/>

  <v-row>
    <v-col cols="12" md="2">
      <div class="d-flex justify-start">
        <span class="ma-2">Show</span>

        <div>
          <v-select
            menu-icon=""
            v-model="params.take"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            :items="['10', '25', '50', '100', '500', '1000',]"
            class="elevation-0"
            color="blue"
          ></v-select>
        </div>

        <span class="ma-2">Entries</span>
      </div>
    </v-col>
    <v-col cols="12" md="10">
      <div class="d-flex justify-end">
        <span class="ma-2">Search</span>

        <div style="width: 30%">
          <v-text-field
            placeholder="search ..."
            append-inner-icon="mdi-magnify"
            width="100%"
            menu-icon=""
            v-model="params.s"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            class="elevation-0"
            color="blue"
          ></v-text-field>
        </div>
      </div>
    </v-col>
  </v-row>

  <v-card rounded="0">
    <v-data-table
      density="comfortable"
      hide-default-footer
      :loading="loading"
    >
      <v-card elevation="0" rounded="0" class="pa-5"  v-if="data.length < 1">
        No record available.
      </v-card>

      <thead>
      <tr class="bg-primary">
        <th v-for="row in headers" v-bind:key="row.key" class="row-none-wrap">
          {{row.title}}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="row in data" v-bind:key="row.id" class="row-none-wrap">
        <td>
          <div class="d-flex pa-4">
            <v-avatar color="blue">
              {{row.Project.project_title[0]}}
            </v-avatar>

            <div class="px-4">
              <h3>{{row.Project.project_id}}</h3>
              <span>
                {{formatDate(row.project_start, 'YYYY-MM-DD HH:mm A')}}
              </span>
            </div>

            <v-avatar color="green" size="30">
              <v-icon>mdi-arrow-top-right-thick</v-icon>
            </v-avatar>
          </div>
        </td>
        <td class="text-green">
          <h3>
            {{row.status}}
          </h3>
        </td>
        <td class="text-green">
          <h3>
            {{formatPrice(row.total_profit)}} USD
          </h3>
        </td>
      </tr>
      </tbody>
    </v-data-table>
  </v-card>

  <div class="app-paginate mt-5">
    <v-pagination
      v-model="params.skip"
      :length="totalPages"
      class="d-flex align-center"
      rounded="circle"
      color="primary"
      border
      total-visible="10"
    >
      <template #prev>
        <div class="d-flex">
          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip=1"
            :disabled="1 === totalPages"
            variant="outlined"
            border
            color="primary"
          >
            First
          </VBtn>

          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip--"
            :disabled="params.skip === 1"
            variant="outlined"
            border
            color="primary"
          >
            Previous
          </VBtn>
        </div>
      </template>
      <template #next>
        <div class="d-flex">
          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip++"
            :disabled="params.skip === totalPages"
            variant="outlined"
            border
            color="primary"
          >
            Next
          </VBtn>

          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip = totalPages"
            :disabled="params.skip === totalPages"
            variant="outlined"
            border
            color="primary"
          >
            Last
          </VBtn>
        </div>
      </template>
    </v-pagination>
  </div>

</template>

<style scoped>

</style>