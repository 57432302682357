<script>
import FrontendLayout from '@/views/client/components/FrontendLayout.vue';
import CardNews from '@/views/client/home/components/CardNews.vue';
import CalculateProfit from '@/views/client/home/components/CalculateProfit.vue';
import ExploreMore from '@/views/client/home/components/ExploreMore.vue';
import MemberFeedback from '@/views/client/home/components/MemberFeedback.vue';
import CardTotal from '@/views/client/home/components/CardTotal.vue';
import ValueOfContacts from '@/views/client/home/components/ValueOfContacts.vue';
import { HOME } from '@/utils/customerApiUrl';
import { formatPriceCurrency } from '@/utils/function';

export default {
  name: 'HomeView',
  components: {
    ValueOfContacts,
    CardNews,
    CardTotal,
    MemberFeedback,
    CalculateProfit,
    ExploreMore,
    FrontendLayout,
  },
  data() {
    return {
      exploreMores: [
        {
          id: 1,
        },
        {
          id: 2,
        },
        {
          id: 3,
        },
      ],
      // data for contracts
      contracts: {
        sectors: [
          'Goods',
          'Non-Consulting Service',
          'Consultant Service',
          'Civil Works',
        ],
        sectors2: [],
        valueOfContract: 0,
        numberOfContract: 0,
        inputContrastBySectors: {},
        data: [],
      },
    };
  },
  methods: {
    async fetchData() {
      this.axios
        .get(
          HOME,
        )
        .then((response) => {
          if (response) {
            //console.log(response);
            // sector
            let { sectors, valueOfContract, numberOfContract, inputContrastBySectors } = response.data;
            this.contracts.sectors = sectors;
            if (sectors.length > 10) {
              let countSector = Math.ceil(sectors.length / 2);
              const group1 = sectors.slice(0, countSector);
              const group2 = sectors.slice(countSector);
              this.contracts.sectors = group1;
              this.contracts.sectors2 = group2;
            }
            // sector
            this.contracts.numberOfContract = formatPriceCurrency(numberOfContract ?? 0);
            this.contracts.inputContrastBySectors = inputContrastBySectors;
            this.contracts.valueOfContract = formatPriceCurrency(valueOfContract ?? 0);
            this.contracts.data = {
              count: 1,
              datasets: [
                {
                  backgroundColor: ['#3F51B5', '#2196F3', '#BBDEFB', '#E8EAF6', '#4CAF50', '#FFEB3B', '#FF5722', '#795548', '#039BE5'],
                  data: inputContrastBySectors,
                },
              ],
            };
          }
        })
        .catch((error) => {
          console.error('Failed to', error);
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<template>
  <FrontendLayout>
    <CalculateProfit />
    <ValueOfContacts :contracts="contracts" />
    <CardNews />
    <VCard class="elevation-0" rounded="0" color="grey-lighten-4">
      <br />
      <CardTotal />
      <ExploreMore :exploreMores="exploreMores" />
      <MemberFeedback />
    </VCard>
  </FrontendLayout>
</template>

<style scoped>

</style>