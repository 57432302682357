<template>
  <v-row class="overflow-y-auto row-container ma-0">
    <v-col cols="12" md="6" lg="6" xl="6">
      <v-alert
        border="top"
        type="success"
        color="success"
        variant="outlined"
        prominent
        icon="mdi-access-point"
        class="d-flex justify-center"
      >
        <v-card width="300px" max-width="auto">
          <v-card-title
            class="text-uppercase font-weight-black"
            style="font-size: smaller"
            >Online</v-card-title
          >
          <v-card-text class="text-capitalize text-overline text-decoration-underline">
            <span style="font-size: large">{{ totalOnline }}</span>
          </v-card-text>
        </v-card>
      </v-alert>
    </v-col>

    <v-col cols="12" md="6" lg="6" xl="6">
      <v-alert
        border="top"
        type="success"
        color="error"
        variant="outlined"
        prominent
        icon="mdi-access-point-off"
        class="d-flex justify-center"
      >
        <v-card width="300px" max-width="auto">
          <v-card-title
            class="text-uppercase font-weight-black"
            style="font-size: smaller"
            >Offline</v-card-title
          >
          <v-card-text class="text-capitalize text-overline text-decoration-underline">
            <span style="font-size: large">{{ totalOffline }}</span>
          </v-card-text>
        </v-card>
      </v-alert>
    </v-col>
    <v-col cols="12" style="padding: 0px">
      <v-card flat>
        <template v-slot:text>
          <v-row>
            <v-col cols="12" md="6" lg="6" xl="6">
              <v-select
                v-if="(isAllCountry === 1) | (isAdmin === 1)"
                v-model="searchCountryId"
                :items="countries"
                :item-title="'name'"
                :item-value="'id'"
                label="Country"
                variant="outlined"
                prepend-inner-icon="mdi-flag-outline"
                required
                persistent-placeholder
                placeholder="Choose country..."
                return-object
                single-line
                clearable
                @update:modelValue="filterItems"
              >
              </v-select>
              <!-- NOTE: when we use @update.movelValue or :on-change the data return to json 
                                  but else the data return as value normally -->
              <v-select
                v-else
                v-model="searchCountryId"
                :items="countries"
                :item-title="'name'"
                :item-value="'id'"
                label="Country"
                variant="outlined"
                prepend-inner-icon="mdi-flag-outline"
                required
                persistent-placeholder
                placeholder="Choose country..."
                return-object
                single-line
                disabled
                clearable
                @update:modelValue="filterItems"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" lg="6" xl="6">
              <v-text-field
                v-model="search"
                label="Search staff..."
                prepend-inner-icon="mdi-magnify"
                variant="outlined"
                hide-details
                single-line
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor" location="top">
          {{ snackText }}
        </v-snackbar>
        <v-data-table
          color="#b2d7ef"
          rounded="compact"
          divided
          striped
          hover
          class="elevation-1"
          fixed-header
          :search="search"
          :headers="headers"
          :items="staffs"
          :items-per-page="itemsPerPage"
          v-model:page="page"
          :sort-by="[{ key: 'lastName', order: 'asc' }]"
        >
          <template v-slot:top>
            <v-dialog
              v-model="dialog"
              persistent
              fullscreen
              transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ props }">
                <v-row>
                  <v-col cols="6" md="6" lg="6" xl="6" class="d-flex align-center mb-4">
                    <v-btn
                      class="mb-2 ml-4"
                      color="primary"
                      v-bind="props"
                      prepend-icon="mdi-plus"
                      variant="flat"
                    >
                      <div class="text-none font-weight-regular">Add</div>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="6"
                    md="6"
                    lg="6"
                    xl="6"
                    class="text-right d-flex mb-6 flex-row-reverse"
                  >
                    <v-sheet class="ma-2 pa-2 text-center">
                      <v-select
                        v-model="itemsPerPage"
                        :items="[10, 25, 50, 100, 1000]"
                        hide-details
                        density="compact"
                        variant="outlined"
                        @update:model-value="itemsPerPage = parseInt($event, 10)"
                        style="width: 100px"
                      ></v-select>
                    </v-sheet>
                    <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
                  </v-col>
                </v-row>
              </template>
              <v-card>
                <v-toolbar>
                  <v-toolbar-title prepend-icon="mdi-form-textbox">{{
                    formTitle
                  }}</v-toolbar-title>

                  <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
                </v-toolbar>
                <v-divider class="mb-0"></v-divider>
                <v-divider class="mb-0"></v-divider>
                <v-form ref="form" enctype="multipart/form-data" lazy-validation>
                  <v-card-text>
                    <v-alert
                      border="end"
                      border-color="warning"
                      elevation="2"
                      color="error"
                      v-show="!visible"
                    >
                      {{ msgError }}
                    </v-alert>
                    <v-row>
                      <v-col cols="12" md="2" lg="2" xl="2">
                        <v-card
                          variant="variant"
                          class="mx-auto"
                          elevation="16"
                          max-width="344"
                        >
                          <v-img
                            :src="
                              previewImage === null
                                ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                                : previewImage
                            "
                            aspect-ratio="1"
                            cover
                            @click="triggerFileInput"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                align="center"
                                class="fill-height ma-0"
                                justify="center"
                              >
                                <v-progress-circular
                                  color="grey-lighten-5"
                                  indeterminate
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-card>

                        <v-file-input
                          v-model.trim="profileImg"
                          accept="image/*"
                          label="Avatar"
                          placeholder="Pick an avatar"
                          prepend-icon="mdi-camera"
                          @change="uploadImage"
                          hide-input
                          class="mt-2"
                          ref="fileInput"
                          style="display: none"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="12" sm="12" md="10" lg="10" xl="10">
                        <v-row>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <input type="hidden" v-model="hiddenId" />
                            <input type="hidden" v-model="hdProfileImage" />
                            <v-text-field
                              v-model.trim="firstName"
                              :rules="[() => !!firstName || 'This field is required']"
                              label="First Name"
                              placeholder="First Name"
                              variant="outlined"
                              prepend-inner-icon="mdi-account"
                              required
                              id="txbfirstName"
                              autocomplete="off"
                              density="compact"
                              persistent-placeholder
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                              v-model.trim="lastName"
                              :rules="[() => !!lastName || 'This field is required']"
                              label="Last Name"
                              placeholder="Last Name"
                              variant="outlined"
                              prepend-inner-icon="mdi-account"
                              required
                              autocomplete="off"
                              density="compact"
                              persistent-placeholder
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                              v-model.trim="fullName"
                              :rules="[() => !!fullName || 'This field is required']"
                              label="Full Name"
                              placeholder="Full Name"
                              variant="outlined"
                              prepend-inner-icon="mdi-account"
                              required
                              autocomplete="off"
                              density="compact"
                              persistent-placeholder
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-checkbox
                              v-model="editedItem.active"
                              :rules="[(v) => !!v || 'You must agree to continue!']"
                              label="Active?"
                              color="success"
                              v-bind:false-value="0"
                              v-bind:true-value="1"
                              required
                              type="checkbox"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-autocomplete
                              ref="gender"
                              v-model="gender"
                              :items="sex"
                              :rules="[() => !!gender || 'This field is required']"
                              label="-- Choose gender --"
                              placeholder="-- Choose gender --"
                              variant="outlined"
                              density="compact"
                              prepend-inner-icon="mdi-filter-variant"
                              required
                              autocomplete="off"
                              persistent-placeholder
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                              v-model.trim="phone"
                              :rules="[() => !!phone || 'This field is required']"
                              label="Phone"
                              placeholder="Phone"
                              variant="outlined"
                              prepend-inner-icon="mdi-phone"
                              required
                              autocomplete="off"
                              density="compact"
                              persistent-placeholder
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                              v-model.trim="dob"
                              :rules="[() => !!dob || 'This field is required']"
                              label="Date of birth"
                              placeholder="Date of birth"
                              variant="outlined"
                              required
                              autocomplete="off"
                              density="compact"
                              type="Date"
                              persistent-placeholder
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                              v-model.trim="editedItem.email"
                              :rules="emailRules"
                              label="Email"
                              placeholder="Email"
                              variant="outlined"
                              prepend-inner-icon="mdi-email"
                              required
                              autocomplete="off"
                              density="compact"
                              persistent-placeholder
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                              v-model.trim="city"
                              :rules="[() => !!city || 'This field is required']"
                              label="City"
                              placeholder="City"
                              variant="outlined"
                              prepend-inner-icon="mdi-city"
                              required
                              autocomplete="off"
                              density="compact"
                              persistent-placeholder
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-select
                              v-if="(isAllCountry === 1) | (isAdmin === 1)"
                              v-model="editedItem.countryId"
                              :items="items"
                              :item-title="'name'"
                              :item-value="'id'"
                              :rules="[(v) => !!v || 'Country is required']"
                              label="Country"
                              variant="outlined"
                              density="compact"
                              prepend-inner-icon="mdi-flag-outline"
                              required
                              autocomplete="off"
                              persistent-placeholder
                            >
                            </v-select>
                            <v-select
                              v-else
                              v-model="editedItem.countryId"
                              :items="items"
                              :item-title="'name'"
                              :item-value="'id'"
                              :rules="[(v) => !!v || 'Country is required']"
                              label="Country"
                              variant="outlined"
                              density="compact"
                              prepend-inner-icon="mdi-flag-outline"
                              required
                              autocomplete="off"
                              persistent-placeholder
                              disabled
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                              v-model.trim="idCardName"
                              label="ID Card Name"
                              placeholder="ID Card name"
                              variant="outlined"
                              prepend-inner-icon="mdi-card-account-details-outline"
                              required
                              autocomplete="off"
                              density="compact"
                              persistent-placeholder
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                              v-model.trim="idCardNumber"
                              label="ID Card Number"
                              placeholder="ID Card Number"
                              variant="outlined"
                              prepend-inner-icon="mdi-card-account-details-outline"
                              required
                              autocomplete="off"
                              density="compact"
                              persistent-placeholder
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <v-textarea
                              clear-icon="mdi-close-circle"
                              label="Address"
                              rows="3"
                              v-model="address"
                              clearable
                              variant="outlined"
                              density="compact"
                              required
                            ></v-textarea>
                          </v-col>

                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                              v-show="editedIndex > -1 ? false : true"
                              v-model.trim="password"
                              :rules="editedIndex > -1 ? [] : passwordRules"
                              label="Password"
                              placeholder="Password"
                              variant="outlined"
                              prepend-inner-icon="mdi-lock"
                              :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                              :type="visible ? 'text' : 'password'"
                              @click:append-inner="visible = !visible"
                              required
                              autocomplete="off"
                              density="compact"
                              persistent-placeholder
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                            <v-text-field
                              v-show="editedIndex > -1 ? false : true"
                              v-model.trim="confirmPassword"
                              :rules="[confirmPasswordRules, matchingPasswords]"
                              label="Confirm Password"
                              placeholder="Confirm Password"
                              variant="outlined"
                              prepend-inner-icon="mdi-lock"
                              :append-inner-icon="
                                visibleConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'
                              "
                              :type="visibleConfirmPassword ? 'text' : 'password'"
                              @click:append-inner="
                                visibleConfirmPassword = !visibleConfirmPassword
                              "
                              required
                              autocomplete="off"
                              density="compact"
                              persistent-placeholder
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider class="mt-1"></v-divider>
                </v-form>

                <v-card-actions class="my-2 d-flex justify-end">
                  <v-spacer></v-spacer>
                  <v-btn
                    class="text-none"
                    color="error"
                    rounded="xl"
                    text="Cancel"
                    variant="flat"
                    @click="close"
                  ></v-btn>

                  <v-btn
                    class="text-none"
                    color="primary"
                    rounded="xl"
                    :text="buttonTitle"
                    variant="flat"
                    @click="save"
                    :disabled="isSubmitting"
                  ></v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <input type="hidden" v-model="hiddenId" />
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogResetPassword"
              transition="dialog-bottom-transition"
              fullscreen
            >
              <v-card>
                <v-toolbar>
                  <v-btn icon="mdi-close" @click="dialogResetPassword = false"></v-btn>

                  <v-toolbar-title>Reset Password</v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-toolbar-items>
                    <v-btn text="Reset" variant="text" @click="resetPassword"></v-btn>
                  </v-toolbar-items>
                </v-toolbar>

                <v-list lines="two" subheader>
                  <v-form
                    ref="formResetPassword"
                    enctype="multipart/form-data"
                    lazy-validation
                  >
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model.trim="resetPass"
                            :rules="resetPasswordRules"
                            label="Password"
                            placeholder="Password"
                            variant="outlined"
                            prepend-inner-icon="mdi-lock"
                            :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                            :type="visible ? 'text' : 'password'"
                            @click:append-inner="visible = !visible"
                            required
                            autocomplete="off"
                            density="compact"
                            persistent-placeholder
                          ></v-text-field>
                          <input type="hidden" v-model="hdStaffId" />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-form>
                </v-list>
              </v-card>
            </v-dialog>
          </template>
          <!-- detect when error  -->
          <template v-slot:no-data>
            <v-alert
              v-if="items.length > 0"
              :value="true"
              color="error"
              icon="warning"
              class="text-left"
            >
              No data found.
            </v-alert>
          </template>
          <!-- end detect when error  -->
          <!-- Custom column table -->
          <template v-slot:[`item.imgProfile`]="{ item }">
            <v-avatar size="64" class="my-1" v-if="item.imgProfile">
              <v-img alt="Avatar" :src="path + item.imgProfile" cover>
                <div
                  :class="item.online === 1 ? 'dot online-green' : 'dot offline-red'"
                ></div>
                <template v-slot:placeholder>
                  <v-row align="center" class="fill-height ma-0" justify="center">
                    <v-progress-circular
                      color="grey-lighten-5"
                      indeterminate
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
            <v-avatar class="my-1" v-else size="64">
              <v-img
                alt="Avatar"
                src="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
                cover
              >
                <div
                  :class="item.online === 1 ? 'dot online-green' : 'dot offline-red'"
                ></div>
                <template v-slot:placeholder>
                  <v-row align="center" class="fill-height ma-0" justify="center">
                    <v-progress-circular
                      color="grey-lighten-5"
                      indeterminate
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
          </template>

          <!--
      <template v-slot:[`item.imgProfile`]="{ item }">
        <div v-if="item.imgProfile" class="text-center">
          <v-avatar size="80" class="my-1">
            <v-img alt="Avatar" :src="path + item.imgProfile" cover></v-img>
          </v-avatar>
        </div>
        <div v-else class="text-center">
          <v-avatar size="80" class="my-1">
            <v-img
              alt="Avatar"
              src="https://static.thenounproject.com/png/4595376-200.png"
            ></v-img>
          </v-avatar>
        </div>
      </template> -->
          <template v-slot:[`item.gender`]="{ item }">
            <div v-if="item.gender" class="text-center">
              {{ item.gender }}
            </div>
            <div v-else class="text-center">N/A</div>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <a :href="'mailto:' + item.email">{{ item.email }}</a>
          </template>
          <template v-slot:[`item.active`]="{ item }">
            <div v-if="item.active" class="text-center">
              <v-icon color="success" icon="mdi-eye-check-outline"></v-icon>
            </div>
            <div v-else class="text-center">
              <v-icon color="error" icon="mdi-eye-remove-outline"></v-icon>
            </div>
          </template>
          <!--
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              size="small"
              v-if="item.status === 'Pending'"
              prepend-icon="mdi-account-clock-outline"
              color="info"
            >
              {{ item.status }}
            </v-chip>
            <v-chip
              size="small"
              v-if="item.status === 'Approved'"
              prepend-icon="mdi-account-check-outline"
              color="success"
            >
              {{ item.status }}
            </v-chip>
          </template>
          -->
          <template v-slot:[`item.createAt`]="{ item }">
            {{ new Date(item.createAt).toLocaleString() }}
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ item.updatedAt !== null ? new Date(item.updatedAt).toLocaleString() : "" }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="admin === 1"
              class="me-2"
              title="Reset password"
              color="error"
              size="small"
              @click="editResetPassword(item)"
            >
              mdi-lock-reset
            </v-icon>
            <v-icon
              v-if="admin === 1"
              title="Edit"
              class="me-2"
              color="primary"
              size="medium"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <!-- <v-icon title="Delete" size="small" @click="deleteItem(item)"> mdi-delete </v-icon> -->
          </template>
          <template v-slot:bottom>
            <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                rounded="circle"
                :length="pageCount"
                :total-visible="7"
              ></v-pagination>
            </div>
          </template>
          <!-- End custom column table -->
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import {
  URL_STAFF,
  URL_COUNTRY,
  URL_UPLOAD_STAFF_IMAGE,
  URL_RESET_PASSWORD,
  URL_STAFF_COUNTRY_PERMISSION,
  URL_GET_STAFF_BY_PERMISSION,
  Path_Staff_Image,
} from "@/utils/apiUrl";
import { IMAGE_TOKEN_HEADERS, TOKEN_HEADERS } from "@/utils/headerToken";
import moment from "moment";
import { formatNumber, originalCode } from "@/utils/formatNumber";
import { getFileExtension, getFileSizeInMB } from "@/utils/reusableFunctions";

export default {
  data: () => ({
    path: Path_Staff_Image,
    snack: false,
    dialog: false,
    dialogDelete: false,
    dialogResetPassword: false,
    visible: true,
    visibleConfirmPassword: false,
    isSubmitting: false,

    sex: ["Male", "Female"],
    items: [],
    staffs: [],
    countries: ["-- All --"],

    search: null,
    snackColor: null,
    snackText: null,
    msgError: null,
    firstName: null,
    lastName: null,
    fullName: null,
    gender: null,
    previewImage: null,
    profileImg: null,
    phone: null,
    email: null,
    country: null,
    checkbox: 1,
    dob: null,
    city: null,
    address: null,
    idCardName: null,
    idCardNumber: null,
    password: null,
    confirmPassword: null,
    hdProfileImage: null,
    imageUrl: null,
    resetPass: null,
    searchCountryId: null,

    editedIndex: -1,
    hiddenId: 0,
    hdStaffId: 0,
    isAdmin: 0,
    isAllCountry: 0,
    totalOnline: 0,
    totalOffline: 0,

    page: 1,
    itemsPerPage: 10,

    staffCountryId: originalCode(parseInt(localStorage.getItem("countryId"))),
    admin: originalCode(parseInt(localStorage.getItem("admin"))),
    allCountry: originalCode(parseInt(localStorage.getItem("allCountry"))),
    countryId: originalCode(parseInt(localStorage.getItem("countryId"))),
    staffLoginId: originalCode(parseInt(localStorage.getItem("id"))),

    emailRules: [
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "email must be valid",
      (v) => (v && v.length <= 100) || "Full name must be less than 100 characters",
    ],
    passwordRules: [
      (v) =>
        (v &&
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/.test(
            v
          )) ||
        "Minimum 6 characters, One capital latter, Special charater, Number",
      (v) => (v && v.length <= 50) || "Password must be less than 50 characters",
    ],
    resetPasswordRules: [
      (v) =>
        (v &&
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/.test(
            v
          )) ||
        "Minimum 6 characters, One capital latter, Special charater, Number",
      (v) => (v && v.length <= 50) || "Password must be less than 50 characters",
    ],
    confirmPasswordRules: [
      (v) =>
        (v &&
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/.test(
            v
          )) ||
        "Minimum 6 characters, One capital latter, Special charater, Number",
      (v) => (v && v.length <= 50) || "Confirm password must be less than 50 characters",
    ],

    headers: [
      { title: "", key: "actions", sortable: false, align: "center" },
      { title: "Photo", key: "imgProfile", align: "center" },
      { title: "Staff", key: "fullName", align: "center" },
      { title: "Sex", key: "gender", align: "center" },
      { title: "Email", key: "email", align: "center" },
      { title: "Country", key: "country.name", align: "center" },
      { title: "Phone", key: "phone", align: "center" },
      { title: "Address", key: "address", align: "center" },
      { title: "Active", key: "active", align: "center" },
      { title: "Created at", key: "createAt", align: "center" },
      { title: "Created by", key: "createdBy", align: "center" },
      { title: "Updated at", key: "updatedAt", align: "center" },
      { title: "Updated by", key: "updatedBy", align: "center" },
    ],

    editedItem: {
      email: "",
      countryId: "",
      active: 1,
      status: "Pending",
    },
    defaultItem: {
      email: "",
      countryId: "",
      active: 1,
      status: "Pending",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Staff" : "Update Staff";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
    pageCount() {
      return Math.ceil(this.staffs.length / this.itemsPerPage);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogResetPassword(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.getStaffCountryAndPermission();
    this.populateStaffs();
    this.populateAllCountries();
    this.PopulateCountries();
  },
  methods: {
    clearContent() {
      this.hiddenId = 0;
      this.firstName = "";
      this.lastName = "";
      this.fullName = "";
      this.gender = "";
      this.phone = "";
      this.dob = "";
      this.editedItem.email = "";
      this.city = "";
      this.countryId = this.staffCountryId;
      this.idCardName = "";
      this.idCardNumber = "";
      this.address = "";
      this.password = "";
      this.confirmPassword = "";
      this.previewImage = null;
      this.visible = true;
    },
    matchingPasswords: function () {
      if (this.password === this.confirmPassword) {
        return true;
      } else {
        return "Passwords does not match.";
      }
    },
    isAdminOrAllCountryPermission() {
      return (this.admin || this.allCountry) === 1 ? true : false;
    },
    async filterItems() {
      if (this.countryId === null) {
        this.populateStaffs();
      } else {
        var url = null;

        if (this.admin === 1 || this.allCountry === 1) {
          url = URL_GET_STAFF_BY_PERMISSION + 1 + "/country/" + this.countryId; // is permission admin or allcountry is true
        } else {
          url = URL_GET_STAFF_BY_PERMISSION + 0 + "/country/" + this.countryId;
        }

        await axios.get(url, { headers: TOKEN_HEADERS }).then((res) => {
          const searchCountryId = this.searchCountryId.id;
          this.staffs = res.data.filter((item) => {
            const itemCountry = item.countryId;
            const matchesCountry = !searchCountryId || itemCountry === searchCountryId;

            //alert(startDate + ", " + itemDate + ", " + matchesDateRange);
            //const matchesSearch = !search || item.name.toLowerCase().includes(search);

            return matchesCountry;
          });

          this.items.push(this.staffs);
        });
      }
    },
    async populateStaffs() {
      var url = null;

      if (this.admin === 1 || this.allCountry === 1) {
        url = URL_GET_STAFF_BY_PERMISSION + 1 + "/country/" + this.staffCountryId; // is permission admin or allcountry is true
      } else {
        url = URL_GET_STAFF_BY_PERMISSION + 0 + "/country/" + this.staffCountryId;
      }

      await axios.get(url, { headers: TOKEN_HEADERS }).then((res) => {
        this.staffs = res.data;
        const online = res.data.filter((item) => item.online == 1).length;
        const offline = res.data.filter((item) => item.online == 0).length;

        this.totalOnline = formatNumber(online);
        this.totalOffline = formatNumber(offline);
      });
    },

    editItem(item) {
      this.editedIndex = this.staffs.indexOf(item);
      this.hiddenId = item.id;
      this.editedItem = Object.assign({}, item);
      this.firstName = item.firstName;
      this.lastName = item.lastName;
      this.fullName = item.fullName;
      this.gender = item.gender;
      this.phone = item.phone;
      this.dob = moment(item.dob).format("YYYY-MM-DD");
      this.city = item.city;
      this.idCardName = item.idCardName;
      this.idCardNumber = item.idCardNumber;
      this.address = item.address;
      this.previewImage =
        item.imgProfile === null
          ? "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
          : this.path + item.imgProfile;
      this.hdProfileImage = item.imgProfile;

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.staffs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.hiddenId = item.id;
      this.dialogDelete = true;
    },

    editResetPassword(item) {
      this.hdStaffId = item.id;
      this.dialogResetPassword = true;
    },

    resetPassword() {
      const id = this.hdStaffId;
      const url = URL_RESET_PASSWORD + id;
      const data = {
        password: this.resetPass,
      };

      axios
        .patch(url, data, { headers: TOKEN_HEADERS })
        .then((response) => {
          this.snakeMessage("success", response.data.message);
        })
        .catch((error) => {
          if (error.response.status === 400) {
            console.log(error.response.data.message); // This will log the error message
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });

      this.resetPass = "";
      this.dialogResetPassword = false;
    },

    deleteItemConfirm() {
      //delete from arraylist
      this.staffs.splice(this.editedIndex, 1);
      //delete from DB
      const id = this.hiddenId;
      const url = URL_STAFF + id;
      axios
        .delete(url, { headers: TOKEN_HEADERS })
        .then(function () {
          this.snakeMessage("error", "Data has been deleted.");
        })
        .catch((error) => console.error("record deleted was an error!", error));

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async submitData() {
      const data = {
        fullName: this.fullName,
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.phone,
        email: this.editedItem.email,
        imgProfile: this.hdProfileImage,
        countryId: this.editedItem.countryId,
        active: this.editedItem.active,
        gender: this.gender,
        dob: this.dob,
        city: this.city,
        staffCountry: "",
        address: this.address,
        idCardName: this.idCardName,
        idCardNumber: parseInt(this.idCardNumber),
        status: "Approved",
      };

      if (this.editedIndex > -1) {
        // edit
        data.updatedAt = new Date();
        data.updatedBy = this.staffLoginId;
        const id = this.hiddenId;
        const url = URL_STAFF + id;
        Object.assign(this.staffs[this.editedIndex], this.editedItem);

        await axios
          .patch(url, data, { headers: TOKEN_HEADERS })
          .then(() => {
            this.snakeMessage("success", "Data has been updated.");
            this.clearContent();
            this.dialog = false;
          })
          .catch(
            (error) => {
              this.visible = false;

              if (error.response.data.statusCode === 400) {
                //this.snakeMessage("error", error.response.data.message);
                this.msgError = error.response.data.message;
              } else if (error.request) {
                // The request was made but no response was received
                //this.snakeMessage("error", error.request);
                this.msgError = error.request;
              } else {
                // Something happened in setting up the request that triggered an Error
                //this.snakeMessage("error", error.message);
                this.msgError = error.message;
              }

              this.dialog = true;
              return false;
            }
            //console.error("record updated was an error!", error)
          );

        this.close();
      } // create new
      else {
        data.password = this.password;
        data.createBy = this.staffLoginId;
        await axios
          .post(URL_STAFF, data, { headers: TOKEN_HEADERS })
          .then(() => {
            this.snakeMessage("success", "Data has been saved.");
            this.clearContent();
            this.dialog = false;
          })
          .catch(
            (error) => {
              this.visible = false;

              if (error.response.data.statusCode === 400) {
                //this.snakeMessage("error", error.response.data.message);
                this.msgError = error.response.data.message;
              } else if (error.request) {
                // The request was made but no response was received
                //this.snakeMessage("error", error.request);
                this.msgError = error.request;
              } else {
                // Something happened in setting up the request that triggered an Error
                //this.snakeMessage("error", error.message);
                this.msgError = error.message;
              }

              this.dialog = true;
              return false;
            }
            //console.error("record updated was an error!", error)
          );
      }

      //this.clearContent();
      //this.dialog = false;
      this.populateStaffs();
      //this.close();
    },

    async save() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        if (this.isSubmitting) return; // Prevent multiple submissions
        this.isSubmitting = true;

        try {
          // Send your form data to the server
          await this.submitData();
        } catch (error) {
          console.error(error);
        } finally {
          this.isSubmitting = false; // Re-enable the button after the request
        }
      }
    },

    async populateAllCountries() {
      await axios.get(URL_COUNTRY).then((res) => {
        res.data.forEach((country) => {
          this.items.push({
            id: country.id,
            name: country.name,
          });
        });
      });
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    async uploadImage(e) {
      const file = e.target.files[0];
      if (file) {
        var obj = this.invalidFileSizeOrFileExtension(file);
        if (obj.isTrue) {
          this.visible = false;
          this.msgError = obj.msg;
          return;
        }

        this.visible = true;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.previewImage = e.target.result;
          console.log(this.previewImage);
        };

        let data = new FormData();
        data.append("file", e.target.files[0]);

        await axios
          .post(URL_UPLOAD_STAFF_IMAGE, data, { headers: IMAGE_TOKEN_HEADERS })
          .then((response) => {
            this.hdProfileImage = response.data.filename;
            console.log("Image has been uploaded.");
          });
      }
    },

    invalidFileSizeOrFileExtension(file) {
      let isTrue = false;
      let msg = null;
      const fileSize = getFileSizeInMB(file);
      const fileExtension = getFileExtension(file);

      if (fileSize >= 6) {
        // 6MB
        isTrue = true;
        msg = "File size is biggest 6MB.";
      }

      if (fileExtension === null) {
        isTrue = true;
        msg = "File extension must be jpg, jpeg, png, bmp";
      }

      return { isTrue, msg };
    },

    async getStaffCountryAndPermission() {
      const staffId = originalCode(parseInt(localStorage.getItem("id")));
      axios
        .get(URL_STAFF_COUNTRY_PERMISSION + staffId, { headers: TOKEN_HEADERS })
        .then((response) => {
          this.isAdmin = response.data.permission.admin;
          this.isAllCountry = response.data.permission.allCountry;
          this.countryIdFromDb = response.data.countryId;
          this.editedItem.countryId = this.countryIdFromDb;
        });
    },

    async PopulateCountries() {
      await axios.get(URL_COUNTRY, { headers: TOKEN_HEADERS }).then((response) => {
        response.data.forEach((country) => {
          this.countries.push({
            id: country.id,
            name: country.name,
          });
        });

        this.searchCountryId = this.isAdminOrAllCountryPermission()
          ? "-- All --"
          : this.staffCountryId;
      });
    },
  },
};
</script>
<style scoped>
.dot {
  position: relative;
  /** top: 39px; */
  right: -44px;
  width: 15px;
  height: 15px;

  border-radius: 50%;
}
.online-green {
  background-color: green;
}
.offline-red {
  background-color: red;
}
</style>
