<template>
  <v-tabs v-model="tab" color="blue" grow bg-color="white">
    <v-tab :text="tab1" class="text-none text-start"></v-tab>
    <v-tab :text="tab2" class="text-none"></v-tab>
  </v-tabs>
  <br />
  <v-tabs-window v-model="tab">
    <v-tabs-window-item :value="tab1">
      <NoticesTab/>
    </v-tabs-window-item>
    <v-tabs-window-item :value="tab2">
      <FundDetailsTab/>
    </v-tabs-window-item>
  </v-tabs-window>
</template>

<script>

import NoticesTab from '@/views/client/profile/transactions/NoticesTab.vue';
import FundDetailsTab from '@/views/client/profile/transactions/FundDetailsTab.vue';

let tab = "transactions_tab";

export default {
  name: "TransactionPage",
  components: { FundDetailsTab, NoticesTab },
  data() {
    return {
      tab1: 'NOTICES',
      tab2: 'FUND DETAILS',
      tab: null,
    };
  },
  created() {
    let defaultTab = localStorage.getItem(tab);
    this.tab = defaultTab ?? 1;
  },
  watch: {
    tab(val) {
      this.tab = val;
      localStorage.setItem(tab, val);
    },
  },
};
</script>

<style scoped></style>
