<template>
  <div class="my-app-bar bg-primary">
    <div>
      <p class="main-title font-weight-bold">
        {{ company.companyName }}
      </p>
      <v-btn class="icon-nav" variant="text" @click="nav = !nav">
        <v-icon size="35" v-if="!nav">mdi-menu</v-icon>
        <v-icon size="35" v-else>mdi-window-close</v-icon>
      </v-btn>
    </div>

    <div class="d-flex">
      <router-link to="/sign-in" class="pt-1 text-decoration-none hide" v-if="!login">
        <p class="text-white">Login</p>
      </router-link>

      <router-link to="/sign-up" class="pt-1 text-decoration-none hide" v-if="!login">
        <p class="ml-10 text-white">Register</p>
      </router-link>

      <v-btn class="pt-1 text-none hide" variant="text" @click="logOut()" v-if="login">
        <p class="text-white">Log out</p>
      </v-btn>

      <v-btn variant="text" class="hide" icon="mdi-facebook" size="small"></v-btn>

      <v-btn variant="text" class="hide" icon="mdi-linkedin" size="small"></v-btn>

      <v-btn variant="text" class="hide" icon="mdi-twitter" size="small"></v-btn>

      <v-btn variant="text" class="hide" icon="mdi-youtube" size="small"></v-btn>

      <v-btn variant="text" class="hide" icon="mdi-whatsapp" size="small"></v-btn>

      <div class="icon-nav my-2">
        <v-badge color="error" :content="notification" class="mx-2" v-if="login">
          <v-icon size="large" color="white" @click="openDrawer()"> mdi-bell</v-icon>
        </v-badge>
      </div>

      <router-link to="/">
        <v-img class="icon-nav" height="50px" width="200px" :src="company.logo" />
      </router-link>
    </div>
  </div>

  <v-layout class="my-layout">
    <v-main>
      <template v-if="nav">
        <div class="my-nav-collapse">
          <MobileScreen
            :aboutUsData="aboutUs"
            :contactData="contact"
            :projectData="project"
            :ourProcessData="ourProcess"
          />
        </div>
      </template>

      <nav class="navs">
        <div class="items hide">
          <div class="logo">
            <router-link to="/">
              <v-img style="height: 40px; width: 215px" :src="company.logo" alt="" />
            </router-link>
          </div>
        </div>

        <div class="items hide">
          <router-link to="/covid">
            <v-btn color="red font-format" size="small" rounded="0">Covid-19</v-btn>
          </router-link>
        </div>

        <div class="items hide">
          <a class="text-decoration-none" href="#">
            <router-link
              to="/asia-pacific-web"
              class="text-decoration-none text-black font-format"
            >
              Asia Pacific
            </router-link>
          </a>
          <div class="all-subs hide">
            <div class="ma-5">
              <v-row>
                <v-col cols="12" md="2">
                  <v-img
                    class="img-border"
                    width="200"
                    height="160"
                    rounded="xl"
                    :src="aboutUs.image"
                    cover
                  ></v-img>
                </v-col>
                <v-col cols="12" md="4">
                  <p
                    class="text-start text-white pa-3 three-line font-format text-control"
                    v-html="truncateDescription(aboutUs.description)"
                  ></p>
                </v-col>
                <v-col cols="12" md="3">
                  <div class="border-end">
                    <br /><br /><br /><br />
                    <router-link to="/asia-pacific-web">
                      <v-btn
                        width="50%"
                        color="white"
                        variant="outlined"
                        border
                        rounded="xl"
                        density="default"
                      >
                        See More
                      </v-btn>
                    </router-link>
                  </div>
                </v-col>
                <v-col cols="12" md="3">
                  <p class="ma-1">
                    <router-link
                      to="/asia-pacific-web"
                      class="text-decoration-none text-white font-format"
                    >
                      Asia pacific
                    </router-link>
                  </p>

                  <p class="ma-1">
                    <router-link
                      to="/annual-report"
                      class="text-decoration-none text-white font-format"
                    >
                      Annual Report
                    </router-link>
                  </p>

                  <p class="ma-1">
                    <router-link
                      to="/political-risk-insurances"
                      class="text-decoration-none text-white font-format"
                    >
                      Political risk & Insurance
                    </router-link>
                  </p>

                  <p class="ma-1">
                    <router-link
                      to="/events"
                      class="text-decoration-none text-white font-format"
                    >
                      Events
                    </router-link>
                  </p>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <div class="items hide">
          <a class="text-decoration-none" href="#">
            <router-link
              to="/projects"
              class="text-decoration-none text-black font-format"
            >
              Projects
            </router-link>
          </a>
          <div class="all-subs">
            <div class="ma-5">
              <v-row>
                <v-col cols="12" md="2">
                  <v-img
                    class="img-border"
                    width="200"
                    height="160"
                    rounded="xl"
                    :src="project.image"
                    cover
                  ></v-img>
                </v-col>
                <v-col cols="12" md="4">
                  <p
                    class="text-start text-white pa-3 three-line font-format text-control"
                    v-html="truncateDescription(project.description)"
                  ></p>
                </v-col>
                <v-col cols="12" md="3">
                  <div class="border-end">
                    <br /><br /><br /><br />
                    <router-link to="/projects">
                      <v-btn
                        width="50%"
                        color="white"
                        variant="outlined"
                        border
                        rounded="xl"
                        density="default"
                      >
                        See More
                      </v-btn>
                    </router-link>
                  </div>
                </v-col>
                <v-col cols="12" md="3">
                  <p class="ma-1">
                    <router-link
                      to="/newest-project"
                      class="text-decoration-none text-white font-format"
                    >
                      Newest Projects
                    </router-link>
                  </p>

                  <p class="ma-1">
                    <router-link
                      to="/feature-projects"
                      class="text-decoration-none text-white font-format"
                    >
                      Featured Projects
                    </router-link>
                  </p>

                  <p class="ma-1">
                    <router-link
                      to="/closing-soon"
                      class="text-decoration-none text-white font-format"
                    >
                      Closing Soon
                    </router-link>
                  </p>

                  <p class="ma-1">
                    <router-link
                      to="/crowd-funding"
                      class="text-decoration-none text-white font-format"
                    >
                      Crowdfunding
                    </router-link>
                  </p>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <div class="items hide">
          <a class="text-decoration-none" href="#">
            <router-link
              to="/contact-us"
              class="text-decoration-none text-black font-format"
            >
              Contact
            </router-link>
          </a>
          <div class="all-subss"></div>
        </div>

        <div class="items hide">
          <a class="text-decoration-none" href="#">
            <router-link
              to="/our-process"
              class="text-decoration-none text-black font-format"
            >
              Our Process
            </router-link>
          </a>
          <div class="all-subs">
            <div class="ma-5">
              <v-row>
                <v-col cols="12" md="2">
                  <v-img
                    class="img-border"
                    width="200"
                    height="160"
                    rounded="xl"
                    :src="ourProcess.image"
                    cover
                  ></v-img>
                </v-col>
                <v-col cols="12" md="4">
                  <p
                    class="text-start text-white pa-3 three-line font-format text-control"
                    v-html="truncateDescription(ourProcess.desc)"
                  ></p>
                </v-col>
                <v-col cols="12" md="3">
                  <div class="border-end">
                    <br /><br /><br /><br />
                    <router-link to="/our-process">
                      <v-btn
                        width="50%"
                        color="white"
                        variant="outlined"
                        border
                        rounded="xl"
                        density="default"
                      >
                        See More
                      </v-btn>
                    </router-link>
                  </div>
                </v-col>
                <v-col cols="12" md="3">
                  <p class="ma-1" v-for="row in ourProcessMenu" v-bind:key="row.title">
                    <router-link
                      :to="row.link"
                      class="text-decoration-none text-white font-format"
                    >
                      {{ row.title }}
                    </router-link>
                  </p>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <div class="items hide">
          <div class="d-flex">
            <v-badge color="red" :content="notification" class="mx-2" v-if="login">
              <v-icon size="large" color="primary" @click="openDrawer()">
                mdi-bell
              </v-icon>
            </v-badge>

            <router-link
              to="/profile"
              class="text-decoration-none text-black"
              v-if="login"
            >
              <VIcon size="small" class="mx-2">
                <v-icon color="primary">mdi-account</v-icon>
              </VIcon>
            </router-link>
          </div>
        </div>
      </nav>

      <div>
        <slot />
      </div>

      <div>
        <v-navigation-drawer v-model="drawer" temporary location="right" width="400">
          <DrawerNotification :closeDrawer="closeDrawer" :notificationCount="notification" :drawer="drawer"/>
        </v-navigation-drawer>
      </div>

      <FooterPage />
    </v-main>
  </v-layout>
</template>

<script>
import FooterPage from './FooterPage';
import store from '../../../store/customerStore';
import { mapState } from 'vuex';
import DrawerNotification from '@/views/client/components/DrawerNotification.vue';
import MobileScreen from '@/views/client/components/MobileScreen.vue';

export default {
  name: 'FrontendLayout',
  components: { MobileScreen, DrawerNotification, FooterPage },
  computed: {
    ...mapState([
      'login',
      'profile',
      'company',
      'aboutUs',
      'contact',
      'project',
      'ourProcess',
      'notification',
    ]),
  },
  data() {
    return {
      nav: false,
      drawer: null,
      about: [],
      ourProcessMenu: [
        {
          title: 'Our Impact',
          link: '/our-impact',
        },
        // {
        //   title: " Asia Pacific",
        //   link: "/asia-pacific-web",
        // },
        {
          title: 'About us',
          link: '/about-us',
        },
        {
          title: 'Small Investment Program',
          link: '/small-investment-program',
        },
        {
          title: 'Currency',
          link: '/currency-web',
        },
      ],
    };
  },
  methods: {
    async logOut() {
      setTimeout(() => {
        //this.$router.push("/projects");
        localStorage.removeItem('client_token');
        localStorage.clear();
        store.commit('SET_PROFILE', {});
        store.commit('SET_LOGIN', false);
        location.href = '/projects';
      }, 1000);
    },

    closeDrawer() {
      this.drawer = null;
    },

    async openDrawer() {
      this.drawer = true;
    },

    truncateDescription(desc) {
      if (desc) {
        return desc.length > 200 ? desc.substring(0, 300) + '...' : desc;
      }
    },
  },

  beforeCreate() {
    this.$store.dispatch('getCompanyInfo');
    //this.$store.dispatch("getUserProfile");
  },

  created() {
    this.$store.dispatch('getUserProfile');
    //this.$store.dispatch("getBankType");
  },
};
</script>

<style scoped>
.img-border {
  border: white 2px solid;
}

.border-end {
  border-right: white 1px solid;
}
</style>
