<template>
  <v-card flat>
    <template v-slot:text>
      <v-row>
        <v-col cols="12" sm="3" md="3" lg="3" xl="3">
          <label class="text-button">Country</label>
          <v-select
            v-model="searchCountryId"
            :items="countries"
            :item-title="'name'"
            :item-value="'id'"
            variant="outlined"
            prepend-inner-icon="mdi-filter-variant"
            density="compact"
            required
            persistent-placeholder
            placeholder="Choose country..."
            return-object
            :disabled="disabledCountry == 0"
            @update:modelValue="filterItems"
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="3" md="3" lg="3" xl="3">
          <label class="text-button">Period</label>
          <v-autocomplete
            v-model="searchNewOrOld"
            :items="newOrOldItems"
            placeholder="-- Choose new or old --"
            density="compact"
            variant="outlined"
            prepend-inner-icon="mdi-filter-variant"
            required
            autocomplete="off"
            persistent-placeholder
            @update:modelValue="filterItems"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="3" md="3" lg="3" xl="3">
          <label class="text-button">Project type</label>
          <v-autocomplete
            v-model="searchProjectType"
            :items="projectTypeItems"
            placeholder="-- Choose new or old --"
            density="compact"
            variant="outlined"
            prepend-inner-icon="mdi-filter-variant"
            required
            autocomplete="off"
            persistent-placeholder
            @update:modelValue="filterItems"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="3" md="3" lg="3" xl="3">
          <label class="text-button">Project status</label>
          <v-autocomplete
            v-model="searchProjectStatus"
            :items="searchProjectStatusItem"
            placeholder="-- Choose project status --"
            density="compact"
            variant="outlined"
            prepend-inner-icon="mdi-filter-variant"
            required
            autocomplete="off"
            persistent-placeholder
            @update:modelValue="filterItems"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3" lg="3" xl="3" style="margin-top: -30px">
          <label class="text-button">From</label>
          <v-text-field
            v-model.trim="start"
            density="compact"
            variant="outlined"
            autocomplete="off"
            hide-details
            single-line
            type="Date"
            @change="filterItems"
            persistent-placeholder
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" lg="3" xl="3" style="margin-top: -30px">
          <label class="text-button">To</label>
          <v-text-field
            v-model.trim="end"
            variant="outlined"
            density="compact"
            autocomplete="off"
            hide-details
            single-line
            type="Date"
            @change="filterItems"
            persistent-placeholder
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3" lg="3" xl="3" style="margin-top: -30px">
          <label class="text-button">Search</label>
          <v-text-field
            v-model="search"
            label="Search project..."
            prepend-inner-icon="mdi-magnify"
            density="compact"
            variant="outlined"
            hide-details
            single-line
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor" location="top">
      {{ snackText }}
    </v-snackbar>
    <v-data-table
      color="#b2d7ef"
      rounded="compact"
      divided
      striped
      hover
      class="elevation-1"
      fixed-header
      :search="search"
      :headers="headers"
      :items="projectCollection"
      :items-per-page="itemsPerPage"
      v-model:page="page"
      :sort-by="[{ key: 'created_at', order: 'desc' }]"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ props }">
            <v-row>
              <v-col cols="6" md="6" lg="6" xl="6" class="d-flex align-center mb-4">
                <v-btn
                  class="mb-2 ml-4"
                  color="primary"
                  v-bind="props"
                  prepend-icon="mdi-plus"
                  variant="flat"
                >
                  <div class="text-none font-weight-regular">Add</div>
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="text-right d-flex mb-6 flex-row-reverse"
              >
                <v-sheet class="ma-2 pa-2 text-center">
                  <v-select
                    v-model="itemsPerPage"
                    :items="[10, 25, 50, 100, 1000]"
                    hide-details
                    density="compact"
                    variant="outlined"
                    @update:model-value="itemsPerPage = parseInt($event, 10)"
                    style="width: 100px"
                  ></v-select>
                </v-sheet>
                <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-toolbar>
              <v-toolbar-title prepend-icon="mdi-form-textbox">{{
                formTitle
              }}</v-toolbar-title>

              <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
            </v-toolbar>
            <v-divider class="mb-0"></v-divider>
            <v-divider class="mb-0"></v-divider>
            <v-form ref="form" enctype="multipart/form-data" lazy-validation>
              <v-card-text>
                <v-alert
                  border="end"
                  border-color="warning"
                  elevation="2"
                  color="error"
                  v-show="!visibleMsg"
                >
                  {{ msgError }}
                </v-alert>
                <v-row class="mt-1">
                  <v-col cols="12" sm="9" md="9" lg="9" xl="9">
                    <v-row>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-select
                          v-if="
                            (isStaffLoginAllCountry === 1) | (isStaffLoginAdmin === 1)
                          "
                          v-model="country_id"
                          :items="countries"
                          :item-title="'name'"
                          :item-value="'id'"
                          label="Country"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-filter-variant"
                          required
                          persistent-placeholder
                          placeholder="Choose country..."
                          return-object
                          :disabled="disabled == 0"
                        >
                        </v-select>
                        <!--NOTE: when we use @update.movelValue or :on-change the data return to json 
                                  but else the data return as value normally -->
                        <v-select
                          v-else
                          v-model="country_id"
                          :items="countries"
                          :item-title="'name'"
                          :item-value="'id'"
                          :rules="[() => !!country_id || 'This field is required']"
                          label="Country"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-filter-variant"
                          required
                          persistent-placeholder
                          placeholder="Choose country..."
                          return-object
                          :disabled="disabled == 0"
                          @update:modelValue="handleCountryChange"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-select
                          v-model="sector_id"
                          :items="sectors"
                          :rules="[() => !!sector_id || 'This field is required']"
                          :item-title="'name'"
                          :item-value="'id'"
                          label="Sector"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-filter-variant"
                          required
                          persistent-placeholder
                          placeholder="Choose sector..."
                          return-object
                          :disabled="disabledSector == 0"
                          @update:modelValue="handleSectorChange"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-autocomplete
                          v-model="project_status"
                          :items="projectStatusItem"
                          :rules="[() => !!project_status || 'This field is required']"
                          label="-- Choose project status --"
                          placeholder="-- Choose project status --"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-filter-variant"
                          required
                          autocomplete="off"
                          persistent-placeholder
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-autocomplete
                          ref="gender"
                          v-model="crowdfunding"
                          :items="crowdfundingTypes"
                          :rules="[() => !!crowdfunding || 'This field is required']"
                          label="-- Choose crowd founding --"
                          placeholder="-- Choose crowd --"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-filter-variant"
                          required
                          autocomplete="off"
                          persistent-placeholder
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                        <input type="hidden" v-model="hiddenId" />
                        <v-text-field
                          v-model.trim="project_title"
                          :rules="[() => !!project_title || 'This field is required']"
                          label="Project Title"
                          placeholder="Project Title"
                          variant="outlined"
                          prepend-inner-icon="mdi-subtitles-outline"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                        <v-switch
                          v-model="project_type"
                          color="success"
                          :label="isProjectTypeAcitve"
                          hide-details
                          v-bind:false-value="0"
                          v-bind:true-value="1"
                          required
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" sm="9" md="9" lg="9" xl="9">
                        <v-text-field
                          v-model.trim="project_brief"
                          :rules="[() => !!project_brief || 'This field is required']"
                          label="Project Brief"
                          placeholder="Project Brief"
                          variant="outlined"
                          prepend-inner-icon="mdi-subtitles-outline"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-text-field
                          v-model.trim="project_id"
                          :rules="[() => !!project_id || 'This field is required']"
                          label="Project ID"
                          placeholder="Project ID"
                          variant="outlined"
                          prepend-inner-icon="mdi-subtitles-outline"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-text-field
                          v-model.trim="guarantee_holder"
                          label="Guarantee Holder"
                          placeholder="Guarantee Holder"
                          variant="outlined"
                          prepend-inner-icon="mdi-subtitles-outline"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-text-field
                          v-model.trim="investor_country"
                          label="Investor Country"
                          placeholder="Investor Country"
                          variant="outlined"
                          prepend-inner-icon="mdi-subtitles-outline"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-text-field
                          v-model.trim="environmental_category"
                          label="Environmental Category"
                          placeholder="Environmental Category"
                          variant="outlined"
                          prepend-inner-icon="mdi-subtitles-outline"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-text-field
                          v-model.trim="date_spg_disclosed"
                          label="Date SPG Disclosed"
                          placeholder="Date SPG Disclosed"
                          variant="outlined"
                          prepend-inner-icon="mdi-clipboard-text-clock-outline"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          clearable
                          type="Date"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-text-field
                          v-model.trim="board_approval_date"
                          label="Board Approval Date"
                          placeholder="Board Approval Date"
                          variant="outlined"
                          prepend-inner-icon="mdi-clipboard-text-clock-outline"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          clearable
                          type="Date"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-text-field
                          v-model.trim="notice_no"
                          label="Notice No."
                          placeholder="Notice No."
                          variant="outlined"
                          prepend-inner-icon="mdi-subtitles-outline"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-text-field
                          v-model.trim="notice_type"
                          label="Notice Type"
                          placeholder="Notice Type"
                          variant="outlined"
                          prepend-inner-icon="mdi-subtitles-outline"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-text-field
                          v-model.trim="notice_status"
                          label="Notice Status"
                          placeholder="Notice Status"
                          variant="outlined"
                          prepend-inner-icon="mdi-subtitles-outline"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-text-field
                          v-model.trim="borrower_bid_reference"
                          label="Borrower Bid Reference"
                          placeholder="Borrower Bid Reference"
                          variant="outlined"
                          prepend-inner-icon="mdi-subtitles-outline"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-text-field
                          v-model.trim="procurement_category"
                          label="Procurement Category"
                          placeholder="Procurement Category"
                          variant="outlined"
                          prepend-inner-icon="mdi-subtitles-outline"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-text-field
                          v-model.trim="procurement_method"
                          label="Procurement Method"
                          placeholder="Procurement Method"
                          variant="outlined"
                          prepend-inner-icon="mdi-subtitles-outline"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-text-field
                          v-model.trim="language_of_notice"
                          label="Language of Notice"
                          placeholder="Language of Notice"
                          variant="outlined"
                          prepend-inner-icon="mdi-subtitles-outline"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                        <v-text-field
                          v-model.number.trim="all_amount_invest"
                          label="Amount Invest"
                          placeholder="Amount Invest"
                          variant="outlined"
                          prepend-inner-icon="mdi-cash"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          type="number"
                          :step="0.1"
                          v-model="value"
                          :formatter="formatNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                        <v-text-field
                          v-model.number.trim="fiscal_year"
                          label="Fiscal Year"
                          placeholder="Fiscal Year"
                          variant="outlined"
                          prepend-inner-icon="mdi-cash"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          type="number"
                          :step="0.1"
                          v-model="value"
                          :formatter="formatNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                        <v-text-field
                          v-model.number.trim="project_share"
                          label="Project Share"
                          placeholder="Project Share"
                          variant="outlined"
                          prepend-inner-icon="mdi-cash"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          type="number"
                          :step="0.1"
                          v-model="value"
                          :formatter="formatNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                        <v-text-field
                          v-model.number.trim="price_per_share"
                          label="Price Per Share"
                          placeholder="Price Per Share"
                          variant="outlined"
                          prepend-inner-icon="mdi-cash"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          type="number"
                          :step="0.1"
                          v-model="value"
                          :formatter="formatNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                        <v-text-field
                          v-model.number.trim="limit_share"
                          label="Limit Share"
                          placeholder="Limit Share"
                          variant="outlined"
                          prepend-inner-icon="mdi-cash"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          type="number"
                          :step="0.1"
                          v-model="value"
                          :formatter="formatNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                        <v-text-field
                          v-model.number.trim="contrast"
                          label="Contrast"
                          placeholder="Contrast"
                          variant="outlined"
                          prepend-inner-icon="mdi-cash"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          type="number"
                          :step="0.1"
                          v-model="value"
                          :formatter="formatNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                        <v-text-field
                          v-model.number.trim="daily"
                          label="Daily"
                          placeholder="Daily"
                          variant="outlined"
                          prepend-inner-icon="mdi-cash"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          type="number"
                          :step="0.1"
                          v-model="value"
                          :formatter="formatNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                        <v-text-field
                          v-model.number.trim="weekly"
                          label="Weekly"
                          placeholder="Weekly"
                          variant="outlined"
                          prepend-inner-icon="mdi-cash"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          type="number"
                          :step="0.1"
                          v-model="value"
                          :formatter="formatNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                        <v-text-field
                          v-model.number.trim="monthly"
                          label="Monthly"
                          placeholder="Monthly"
                          variant="outlined"
                          prepend-inner-icon="mdi-cash"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          type="number"
                          :step="0.1"
                          v-model="value"
                          :formatter="formatNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                        <v-text-field
                          v-model.number.trim="quarterly"
                          label="Quarterly"
                          placeholder="Quarterly"
                          variant="outlined"
                          prepend-inner-icon="mdi-cash"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          type="number"
                          :step="0.1"
                          v-model="value"
                          :formatter="formatNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                        <v-text-field
                          v-model.number.trim="semi_annually"
                          label="Semi Annually"
                          placeholder="Semi Annually"
                          variant="outlined"
                          prepend-inner-icon="mdi-cash"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          type="number"
                          :step="0.1"
                          v-model="value"
                          :formatter="formatNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                        <v-text-field
                          v-model.number.trim="annually"
                          label="Annually"
                          placeholder="Annually"
                          variant="outlined"
                          prepend-inner-icon="mdi-cash"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          type="number"
                          :step="0.1"
                          v-model="value"
                          :formatter="formatNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-label class="font-weight-thin x-small">Notice Details</v-label>
                        <QuillEditor
                          toolbar="essential"
                          contentType="html"
                          style="height: 150px"
                          v-model:content="notice_details"
                          theme="snow"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-label class="font-weight-thin x-small"
                          >Project Description</v-label
                        >
                        <QuillEditor
                          toolbar="essential"
                          contentType="html"
                          style="height: 150px"
                          v-model:content="description_project"
                          theme="snow"
                        />
                      </v-col>

                      <input type="hidden" v-model="hd_image_project" />
                      <input type="hidden" v-model="hd_map_image" />
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-row>
                      <v-col cols="12">
                        <v-card
                          variant="variant"
                          class="mx-auto"
                          elevation="16"
                          subtitle="Image Project"
                        >
                          <v-img
                            :src="
                              previousImageProject === null
                                ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                                : previousImageProject
                            "
                            aspect-ratio="1"
                            color="surface-variant"
                            height="370"
                            contain
                            @click="triggerFileInputImageProject"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                align="center"
                                class="fill-height ma-0"
                                justify="center"
                              >
                                <v-progress-circular
                                  color="grey-lighten-5"
                                  indeterminate
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-card>

                        <v-file-input
                          v-model.trim="image_project"
                          accept="image/png, image/jpeg, image/bmp"
                          label="Avatar"
                          placeholder="Pick an avatar"
                          prepend-icon="mdi-camera"
                          @change="uploadImageProject"
                          hide-input
                          class="mt-2"
                          ref="fileInputImageProject"
                          style="display: none"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="12">
                        <v-card
                          variant="variant"
                          class="mx-auto"
                          elevation="16"
                          subtitle="Map Image"
                        >
                          <v-img
                            :src="
                              previousMapImage === null
                                ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                                : previousMapImage
                            "
                            aspect-ratio="1"
                            color="surface-variant"
                            height="370"
                            contain
                            @click="triggerFileInputMapImage"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                align="center"
                                class="fill-height ma-0"
                                justify="center"
                              >
                                <v-progress-circular
                                  color="grey-lighten-5"
                                  indeterminate
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-card>

                        <v-file-input
                          v-model.trim="map_image"
                          accept="image/png, image/jpeg, image/bmp"
                          label="Avatar"
                          placeholder="Pick an avatar"
                          prepend-icon="mdi-camera"
                          @change="uploadMapImage"
                          hide-input
                          class="mt-2"
                          ref="fileInputMapImage"
                          style="display: none"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
            </v-form>

            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                color="error"
                text="Cancel"
                @click="close"
                variant="flat"
              ></v-btn>

              <v-btn
                class="text-none"
                color="primary"
                rounded="xl"
                :text="buttonTitle"
                variant="flat"
                @click="save"
                :disabled="isSubmitting"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <input type="hidden" v-model="hiddenId" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- detect when error  -->
      <template v-slot:no-data>
        <v-alert
          v-if="items.length > 0"
          :value="true"
          color="error"
          icon="warning"
          class="text-left"
        >
          No data found.
        </v-alert>
      </template>
      <!-- end detect when error  -->
      <!-- Custom column table -->
      <template v-slot:[`item.image_project`]="{ item }">
        <div v-if="item.image_project" class="text-center">
          <v-avatar size="80" class="my-1">
            <v-img alt="Avatar" :src="path + item.image_project" cover></v-img>
          </v-avatar>
        </div>
        <div v-else class="text-center">
          <v-avatar size="80" class="my-1">
            <v-img
              alt="Avatar"
              src="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
              cover
            >
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular
                    color="grey-lighten-5"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </div>
      </template>
      <template v-slot:[`item.map_image`]="{ item }">
        <div v-if="item.map_image" class="text-center">
          <v-avatar size="80" class="my-1">
            <v-img alt="Avatar" :src="path + item.map_image" cover></v-img>
          </v-avatar>
        </div>
        <div v-else class="text-center">
          <v-avatar size="80" class="my-1">
            <v-img
              alt="Avatar"
              src="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
              cover
            >
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular
                    color="grey-lighten-5"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </div>
      </template>
      <template v-slot:[`item.Country.name`]="{ item }">
        <div v-html="item.Country.name"></div>
      </template>
      <template v-slot:[`item.Sector.name`]="{ item }">
        <div v-html="item.Sector.name"></div>
      </template>
      <template v-slot:[`item.ProjectStatus.status`]="{ item }">
        <div v-html="item.ProjectStatus.status"></div>
      </template>
      <template v-slot:[`item.Compensation.interest_name`]="{ item }">
        <div v-html="item.ProjectStatus.status"></div>
      </template>
      <template v-slot:[`item.notice_details`]="{ item }">
        <div
          v-html="
            item.notice_details !== null
              ? item.notice_details.length > 50
                ? item.notice_details.substring(0, 50) + '...'
                : item.notice_details
              : ''
          "
        ></div>
      </template>
      <template v-slot:[`item.description_project`]="{ item }">
        <div
          v-html="
            item.description_project !== null
              ? item.description_project.length > 50
                ? item.description_project.substring(0, 50) + '...'
                : item.description_project
              : ''
          "
        ></div>
      </template>
      <template v-slot:[`item.business_get_commission`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.business_get_commission) }}
      </template>
      <template v-slot:[`item.project_share`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.project_share) }}
      </template>
      <template v-slot:[`item.limit_share`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.limit_share) }}
      </template>
      <template v-slot:[`item.contrast`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.contrast) }}
      </template>
      <template v-slot:[`item.daily`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.daily) }}
      </template>
      <template v-slot:[`item.weekly`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.weekly) }}
      </template>
      <template v-slot:[`item.monthly`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.monthly) }}
      </template>
      <template v-slot:[`item.quarterly`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.quarterly) }}
      </template>
      <template v-slot:[`item.semi_annually`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.semi_annually) }}
      </template>
      <template v-slot:[`item.annually`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.annually) }}
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ new Date(item.created_at).toLocaleString() }}
      </template>
      <template v-slot:[`item.update_time`]="{ item }">
        {{ item.update_time !== null ? new Date(item.update_time).toLocaleString() : "" }}
      </template>
      <template v-slot:[`item.Staff.fullName`]="{ item }">
        {{ item.Staff !== null ? item.Staff.fullName : "" }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="ma-2" size="x-small" color="red-lighten-2" @click="editItem(item)">
          <v-icon icon="mdi-pencil" start></v-icon>
          Edit
        </v-btn>
        <!--
                <v-icon title="Edit" class="me-2" size="small" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon title="Delete" size="small" @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            rounded="circle"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </div>
      </template>
      <!-- End custom column table -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import {
  Path_PROJECT_IMAGE,
  URL_COUNTRY,
  URL_PROJECT,
  URL_SECTOR,
  URL_UPLOAD_PROJECT_IMAGE,
} from "@/utils/apiUrl";
import { IMAGE_TOKEN_HEADERS, TOKEN_HEADERS } from "@/utils/headerToken";
import { originalCode, formatNumber } from "@/utils/formatNumber";
import { QuillEditor } from "@vueup/vue-quill";
import moment from "moment";
import { getFileExtension, getFileSizeInKB } from "@/utils/reusableFunctions";

export default {
  components: {
    QuillEditor,
  },
  data: () => ({
    path: Path_PROJECT_IMAGE,
    snack: false,
    dialog: false,
    dialogDelete: false,
    visible: false,
    visibleMsg: true,
    isSubmitting: false,

    items: [],
    projectCollection: [],
    countries: [{ id: 0, name: "-- All --" }],
    sectors: [],
    crowdfundingTypes: ["Crowdfunding", "Normal"],
    projectStatusItem: ["Active", "Crowdfunding", "Normal", "Proposed", "Sold out"],

    search: null,
    snackColor: null,
    snackText: null,
    msgError: null,
    disabled: null,

    /** variables search */

    searchProjectStatusItem: [
      "-- All --",
      "Active",
      "Crowdfunding",
      "Normal",
      "Proposed",
      "Sold out",
    ],
    newOrOldItems: ["-- All --", "New", "Old"],
    projectTypeItems: ["-- All --", "Active", "Disactive"],
    searchCountryId: null,
    searchProjectStatus: "-- All --",
    searchNewOrOld: "-- All --",
    searchProjectType: "-- All --",
    start: null,
    end: null,

    /**  end */

    country_id: null,
    sector_id: null,
    project_status: null,
    project_title: null,
    image_project: null,
    project_brief: null,
    description_project: null,
    all_amount_invest: null,
    map_image: null,
    project_id: null,
    guarantee_holder: null,
    investor_country: null,
    environmental_category: null,
    date_spg_disclosed: null,
    board_approval_date: null,
    project_type: 1,
    fiscal_year: null,
    notice_no: null,
    notice_type: null,
    notice_status: null,
    borrower_bid_reference: null,
    procurement_category: null,
    procurement_method: null,
    notice_details: null,
    language_of_notice: null,
    project_share: null,
    price_per_share: null,
    limit_share: null,
    contrast: null,
    daily: null,
    weekly: null,
    monthly: null,
    quarterly: null,
    semi_annually: null,
    annually: null,
    crowdfunding: null,

    previousImageProject: null,
    previousMapImage: null,
    hd_image_project: null,
    hd_map_image: null,

    editedIndex: -1,
    hiddenId: 0,
    disabledCountry: null,
    disabledSector: null,

    page: 1,
    itemsPerPage: 10,

    staffLoginId: originalCode(parseInt(localStorage.getItem("id"))),
    isStaffLoginAllCountry: originalCode(parseInt(localStorage.getItem("allCountry"))),
    isStaffLoginAdmin: originalCode(parseInt(localStorage.getItem("admin"))),
    staffLoginCountryId: originalCode(parseInt(localStorage.getItem("countryId"))),

    headers: [
      { title: "", key: "actions", sortable: false, align: "center" },
      { title: "Project Image", key: "image_project", align: "center" },
      { title: "Map Image", key: "map_image", align: "center" },
      { title: "Project Title", key: "project_title", align: "center" },
      { title: "Project Brief", key: "project_brief", align: "center" },
      { title: "Country", key: "Country.name", align: "center" },
      { title: "Sector", key: "Sector.name", align: "center" },
      { title: "Project Type", key: "project_type", align: "center" },
      { title: "Project Status", key: "project_status", align: "center" },
      { title: "Crowd Funding", key: "crowdfunding", align: "center" },
      { title: "Amount Invest", key: "all_amount_invest", align: "center" },
      { title: "Project ID", key: "project_id", align: "center" },
      { title: "Project Type", key: "project_type", align: "center" },
      { title: "Fiscal Year", key: "fiscal_year", align: "center" },
      { title: "Project Share", key: "project_share", align: "center" },
      { title: "Limit Share", key: "limit_share", align: "center" },
      { title: "Contrast", key: "contrast", align: "center" },
      { title: "Daily", key: "daily", align: "center" },
      { title: "Weekly", key: "weekly", align: "center" },
      { title: "Monthly", key: "monthly", align: "center" },
      { title: "Quarterly", key: "quarterly", align: "center" },
      { title: "Semi Annually", key: "semi_annually", align: "center" },
      { title: "Annually", key: "annually", align: "center" },
      { title: "Project Desc", key: "description_project", align: "center" },
      { title: "Notice Details", key: "notice_details", align: "center" },

      { title: "Create at", key: "created_at", align: "center" },
      { title: "Updated at", key: "update_time", align: "center" },
      { title: "Updated by", key: "Staff.fullName", align: "center" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Project" : "Update Project";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
    isProjectTypeAcitve() {
      return this.project_type === 0 ? "Disactive" : "Active";
    },
    pageCount() {
      return Math.ceil(this.projectCollection.length / this.itemsPerPage);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.PopulateCountries();
    this.PopulateSectors();
    //this.populateprojectCollection();
    this.filterItems();
  },
  methods: {
    clearContent() {
      this.hiddenId = 0;
      //this.country_id = null;
      this.sector_id = null;
      this.project_status = null;
      this.project_title = null;
      this.project_brief = null;
      this.description_project = null;
      this.all_amount_invest = null;
      this.project_id = null;
      this.guarantee_holder = null;
      this.investor_country = null;
      this.environmental_category = null;
      this.date_spg_disclosed = null;
      this.board_approval_date = null;
      this.project_type = 1;
      this.fiscal_year = null;
      this.notice_no = null;
      this.notice_type = null;
      this.notice_status = null;
      this.borrower_bid_reference = null;
      this.procurement_category = null;
      this.procurement_method = null;
      this.notice_details = null;
      this.language_of_notice = null;
      this.project_share = null;
      this.price_per_share = null;
      this.limit_share = null;
      this.contrast = null;
      this.daily = null;
      this.weekly = null;
      this.monthly = null;
      this.quarterly = null;
      this.semi_annually = null;
      this.annually = null;
      this.crowdfunding = null;
      this.previousImageProject = null;
      this.previousMapImage = null;
      this.disabled =
        (this.isStaffLoginAdmin || this.isStaffLoginAllCountry) === 1 ? 1 : 0;
      this.disabledSector = 1;

      this.visibleMsg = true;
    },

    PopulateCountries() {
      axios.get(URL_COUNTRY, { headers: TOKEN_HEADERS }).then((response) => {
        response.data.forEach((country) => {
          this.countries.push({
            id: country.id,
            name: country.name,
          });
        });

        if ((this.isStaffLoginAdmin || this.isStaffLoginAllCountry) === 1) {
          this.disabledCountry = 1;
          this.searchCountryId = 0;
        } else {
          this.disabledCountry = 0;
          this.searchCountryId = this.staffLoginCountryId;
          this.disabled = 0;
        }

        this.country_id = this.staffLoginCountryId;
      });
    },

    PopulateSectors() {
      axios.get(URL_SECTOR, { headers: TOKEN_HEADERS }).then((response) => {
        response.data.forEach((sector) => {
          this.sectors.push({
            id: sector.id,
            name: sector.name,
          });
        });
      });
    },

    async filterItems() {
      var searchCountryId = null;

      if (this.searchCountryId) {
        searchCountryId =
          this.searchCountryId.id === undefined ? null : this.searchCountryId.id;
      } else {
        if (this.searchCountryId === null) {
          if ((this.isStaffLoginAdmin || this.isStaffLoginAllCountry) === 1) {
            searchCountryId = null;
          } else {
            searchCountryId = this.staffLoginCountryId;
          }
        } else {
          searchCountryId = this.staffLoginCountryId;
        }
      }

      this.populateprojectByCountryId(searchCountryId);
    },

    async populateprojectByCountryId(countryId) {
      var newOrOld = this.searchNewOrOld === "-- All --" ? null : this.searchNewOrOld;

      const today = new Date();
      const last7DaysAgoDate = new Date(today);
      last7DaysAgoDate.setDate(today.getDate() - 7);
      const last7Days = last7DaysAgoDate.toLocaleDateString();
      //alert(last7DaysAgoDate.toLocaleDateString())\

      var projectType =
        this.searchProjectType === "-- All --" ? null : this.searchProjectType;
      var projectStatus =
        this.searchProjectStatus === "-- All --" ? null : this.searchProjectStatus;
      var startDate = this.start ? new Date(this.start).toLocaleDateString() : null;
      var endDate = this.end ? new Date(this.end).toLocaleDateString() : null;

      /** const linkUrl =
        URL_CUSTOMER_CONFIRMATION +
        `?status=${this.status}&countryCode=${this.countryId}`; */
      await axios
        .get(URL_PROJECT, {
          headers: TOKEN_HEADERS,
        })
        .then((res) => {
          this.projectCollection = res.data.filter((item) => {
            //const itemNewOrOldDate = new Date(item.created_at).toLocaleDateString();
            var matchNewOrOld = null;
            const itemDate = new Date(item.created_at).toLocaleDateString();
            const itemCountry = item.country_id;
            const itemProjectType = item.project_type;
            const itemProjectStatus = item.project_status;

            const matchesCountry = !countryId || itemCountry === countryId;

            if (newOrOld !== null) {
              if (newOrOld === "New") {
                matchNewOrOld = !last7Days || itemDate >= last7Days;
              } else {
                matchNewOrOld = !last7Days || itemDate <= last7Days;
              }
            } else {
              matchNewOrOld = true;
            }

            const matchesProjectType = !projectType || itemProjectType === projectType;
            const matchesProjectStatus =
              !projectStatus || itemProjectStatus === projectStatus;
            const matchesDateRange =
              (!startDate || itemDate >= startDate) && (!endDate || itemDate <= endDate);
            //alert(startDate + ", " + itemDate + ", " + matchesDateRange);
            //const matchesSearch = !search || item.name.toLowerCase().includes(search);

            return (
              matchesCountry &&
              matchNewOrOld &&
              matchesProjectType &&
              matchesProjectStatus &&
              matchesDateRange
            );
          });

          this.items.push(this.projectCollection);
          this.visible = res.data.length !== 0 ? true : false;
        });
    },

    editItem(item) {
      this.editedIndex = this.projectCollection.indexOf(item);
      this.hiddenId = item.id;
      this.disabled = 0;
      this.disabledSector = 0;

      this.editedItem = Object.assign({}, item);
      this.country_id = item.country_id;
      this.sector_id = item.sector_id;
      this.project_status = item.project_status;
      this.project_title = item.project_title;
      this.project_brief = item.project_brief;
      this.description_project = item.description_project;
      this.all_amount_invest = item.all_amount_invest;
      this.project_id = item.project_id;
      this.guarantee_holder = item.guarantee_holder;
      this.investor_country = item.investor_country;
      this.environmental_category = item.environmental_category;
      this.date_spg_disclosed = moment(item.date_spg_disclosed).format("YYYY-MM-DD");
      this.board_approval_date = moment(item.board_approval_date).format("YYYY-MM-DD");
      this.project_type = item.project_type === "Active" ? 1 : 0;
      this.fiscal_year = item.fiscal_year;
      this.notice_no = item.notice_no;
      this.notice_type = item.notice_type;
      this.notice_status = item.notice_status;
      this.borrower_bid_reference = item.borrower_bid_reference;
      this.procurement_category = item.procurement_category;
      this.procurement_method = item.procurement_method;
      this.notice_details = item.notice_details;
      this.language_of_notice = item.language_of_notice;
      this.project_share = item.project_share;
      this.price_per_share = item.price_per_share;
      this.limit_share = item.limit_share;
      this.contrast = item.contrast;
      this.daily = item.daily;
      this.weekly = item.weekly;
      this.monthly = item.monthly;
      this.quarterly = item.quarterly;
      this.semi_annually = item.semi_annually;
      this.annually = item.annually;
      this.crowdfunding = item.crowdfunding;

      this.previousImageProject =
        item.image_project === null
          ? "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
          : this.path + item.image_project;
      this.hd_image_project = item.image_project;

      this.previousMapImage =
        item.map_image === null
          ? "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
          : this.path + item.map_image;
      this.hd_map_image = item.map_image;

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.projectCollection.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.hiddenId = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //delete from arraylist
      this.projectCollection.splice(this.editedIndex, 1);
      //delete from DB
      const id = this.hiddenId;
      const url = URL_PROJECT + id;
      axios
        .delete(url, { headers: TOKEN_HEADERS })
        .then(function () {
          this.snakeMessage("error", "Data has been deleted.");
        })
        .catch((error) => console.error("record deleted was an error!", error));

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async submitData() {
      if (parseInt(this.limit_share) > parseInt(this.project_share)) {
        this.visibleMsg = false;
        this.msgError =
          "Limit share cannot bigger than project share. Please check again!!!!!";
        return;
      }

      if (this.daily > this.weekly) {
        this.visibleMsg = false;
        this.msgError = "Daily cannot bigger than Weekly. Please check again!!!!!";
        return;
      }
      if (this.weekly > this.monthly) {
        this.visibleMsg = false;
        this.msgError = "Weekly cannot bigger than Monthly. Please check again!!!!!";
        return;
      }
      if (this.monthly > this.quarterly) {
        this.visibleMsg = false;
        this.msgError = "Monthly cannot bigger than Quarterly. Please check again!!!!!";
        return;
      }
      if (this.quarterly > this.semi_annually) {
        this.visibleMsg = false;
        this.msgError =
          "Quarterly cannot bigger than Semi Annually. Please check again!!!!!";
        return;
      }
      if (this.semi_annually > this.annually) {
        this.visibleMsg = false;
        this.msgError =
          "Semi Annually cannot bigger than Annually. Please check again!!!!!";
        return;
      }

      const data = {
        country_id: parseInt(this.country_id),
        sector_id: parseInt(this.sector_id),
        project_status: this.project_status,
        project_title: this.project_title,
        image_project: this.hd_image_project,
        project_brief: this.project_brief,
        description_project: this.description_project,
        all_amount_invest:
          this.all_amount_invest === null ? 0 : parseFloat(this.all_amount_invest),
        map_image: this.hd_map_image,
        project_id: this.project_id,
        guarantee_holder: this.guarantee_holder,
        investor_country: this.investor_country,
        environmental_category: this.environmental_category,
        date_spg_disclosed: new Date(this.date_spg_disclosed),
        board_approval_date: new Date(this.board_approval_date),
        project_type: this.project_type === 0 ? "Disactive" : "Active",
        fiscal_year: this.fiscal_year === null ? null : this.fiscal_year.toString(),
        notice_no: this.notice_no,
        notice_type: this.notice_type,
        notice_status: this.notice_status,
        borrower_bid_reference: this.borrower_bid_reference,
        procurement_category: this.procurement_category,
        procurement_method: this.procurement_method,
        notice_details: this.notice_details,
        language_of_notice: this.language_of_notice,
        project_share: this.project_share === null ? 0 : parseFloat(this.project_share),
        price_per_share:
          this.price_per_share === null ? 0 : parseFloat(this.price_per_share),
        limit_share: this.limit_share === null ? 0 : parseFloat(this.limit_share),
        contrast: this.contrast === null ? 0 : parseFloat(this.contrast),
        daily: this.daily === null ? 0 : parseFloat(this.daily),
        weekly: this.weekly === null ? 0 : parseFloat(this.weekly),
        monthly: this.monthly === null ? 0 : parseFloat(this.monthly),
        quarterly: this.quarterly === null ? 0 : parseFloat(this.quarterly),
        semi_annually: this.semi_annually === null ? 0 : parseFloat(this.semi_annually),
        annually: this.annually === null ? 0 : parseFloat(this.annually),
        crowdfunding: this.crowdfunding,
      };

      if (this.editedIndex > -1) {
        // edit
        data.update_time = new Date();
        data.user_update = this.staffLoginId;
        const id = this.hiddenId;
        const url = URL_PROJECT + id;
        Object.assign(this.projectCollection[this.editedIndex], this.editedItem);

        await axios
          .patch(url, data, { headers: TOKEN_HEADERS })
          .then((response) => {
            this.snakeMessage("success", response.data.message);
            this.clearContent();
            this.dialog = false;
          })
          .catch(
            (error) => {
              this.visibleMsg = false;

              if (error.response.data.statusCode === 400) {
                //this.snakeMessage("error", error.response.data.message);
                this.msgError = error.response.data.message;
              } else if (error.request) {
                // The request was made but no response was received
                //this.snakeMessage("error", error.request);
                this.msgError = error.request;
                // Something happened in setting up the request that triggered an Error
                //this.snakeMessage("error", error.message);
                this.msgError = error.message;
              }

              this.dialog = true;
              return false;
            }
            //console.error("record updated was an error!", error)
          );
      } // create new
      else {
        await axios
          .post(URL_PROJECT, data, { headers: TOKEN_HEADERS })
          .then((response) => {
            this.snakeMessage("success", response.data.message);
            this.clearContent();
            this.dialog = false;
          })
          .catch(
            (error) => {
              this.visibleMsg = false;

              if (error.response.data.statusCode === 400) {
                //this.snakeMessage("error", error.response.data.message);
                this.msgError = error.response.data.message;
              } else if (error.request) {
                // The request was made but no response was received
                //this.snakeMessage("error", error.request);
                this.msgError = error.request;
                // Something happened in setting up the request that triggered an Error
                //this.snakeMessage("error", error.message);
                this.msgError = error.message;
              }

              this.dialog = true;
              return false;
            }
            //console.error("record updated was an error!", error)
          );
      }

      //this.clearContent();
      //this.populateprojectCollection();
      var countryId =
        (this.isStaffLoginAdmin || this.isStaffLoginAllCountry) === 1
          ? null
          : this.staffLoginCountryId;
      this.populateprojectByCountryId(countryId);
    },

    async save() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        if (this.isSubmitting) return; // Prevent multiple submissions
        this.isSubmitting = true;

        try {
          // Send your form data to the server
          await this.submitData();
        } catch (error) {
          console.error(error);
        } finally {
          this.isSubmitting = false; // Re-enable the button after the request
        }
        //this.dialog = false;
        //this.close();
      }
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },

    triggerFileInputImageProject() {
      this.$refs.fileInputImageProject.click();
    },

    uploadImageProject(e) {
      const file = e.target.files[0];
      if (file) {
        var obj = this.invalidFileSizeOrFileExtension(file);
        if (obj.isTrue) {
          this.visibleMsg = false;
          this.msgError = obj.msg;
          return;
        }

        this.visibleMsg = true;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.previousImageProject = e.target.result;
          console.log(this.previousImageProject);
        };

        let data = new FormData();
        data.append("file", e.target.files[0]);

        axios
          .post(URL_UPLOAD_PROJECT_IMAGE, data, {
            headers: IMAGE_TOKEN_HEADERS,
          })
          .then((response) => {
            this.hd_image_project = response.data.filename;
            console.log("Image has been uploaded.");
          });
      }
    },

    triggerFileInputMapImage() {
      this.$refs.fileInputMapImage.click();
    },

    uploadMapImage(e) {
      const file = e.target.files[0];
      if (file) {
        var obj = this.invalidFileSizeOrFileExtension(file);
        if (obj.isTrue) {
          this.visibleMsg = false;
          this.msgError = obj.msg;
          return;
        }

        this.visibleMsg = true;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.previousMapImage = e.target.result;
          console.log(this.previousMapImage);
        };

        let data = new FormData();
        data.append("file", e.target.files[0]);

        axios
          .post(URL_UPLOAD_PROJECT_IMAGE, data, {
            headers: IMAGE_TOKEN_HEADERS,
          })
          .then((response) => {
            this.hd_map_image = response.data.filename;
            console.log("Image has been uploaded.");
          });
      }
    },

    invalidFileSizeOrFileExtension(file) {
      let isTrue = false;
      let msg = null;
      const fileSize = getFileSizeInKB(file);
      const fileExtension = getFileExtension(file);

      if (fileSize >= 400) {
        // 400KB
        isTrue = true;
        msg = "File size is biggest 400KB.";
      }

      if (fileExtension === null) {
        isTrue = true;
        msg = "File extension must be jpg, jpeg, png, bmp";
      }

      return { isTrue, msg };
    },

    formatNumber(e) {
      if (e > this.validation.max) {
        return this.validation.max;
      } else if (e < this.validation.min) {
        return this.validation.min;
      } else if (
        Math.round(e * this.validation.decimal) / this.validation.decimal !==
        e
      ) {
        return this.lastValue;
      } else {
        this.lastValue = e;
        return e;
      }
    },

    convertNumber1000To1K1M1B(value) {
      return formatNumber(value);
    },

    handleProjectStatusChange(newValue) {
      if (newValue) {
        this.project_status_id = newValue.id; // Update old ID with new selection
      }
    },

    handleCompensationChange(newValue) {
      if (newValue) {
        this.compensation_id = newValue.id; // Update old ID with new selection
      }
    },

    handleCountryChange(newValue) {
      if (newValue) {
        this.country_id = newValue.id; // Update old ID with new selection
      }
    },

    handleSectorChange(newValue) {
      if (newValue) {
        this.sector_id = newValue.id; // Update old ID with new selection
      }
    },
  },
};
</script>
