<template>
  <div>
    <v-btn
      variant="text"
      @click="checkAuth"
      icon="mdi-facebook"
      color="blue-darken-3"
      size="small"
    ></v-btn>

    <v-btn
      variant="text"
      @click="checkAuth"
      icon="mdi-linkedin"
      color="blue-darken-3"
      size="small"
    ></v-btn>

    <v-btn
      variant="text"
      @click="checkAuth"
      icon="mdi-twitter"
      color="blue-darken-3"
      size="small"
    ></v-btn>

    <v-btn
      variant="text"
      @click="checkAuth"
      icon="mdi-youtube"
      color="blue-darken-3"
      size="small"
    ></v-btn>

    <v-btn
      variant="text"
      @click="checkAuth"
      icon="mdi-whatsapp"
      color="blue-darken-3"
      size="small"
    ></v-btn>

    <div class="text-center">
      <v-bottom-sheet v-model="visible">
        <v-card
          class="text-center"
          height="auto"
          style="background-color: #e0e0e0"
        >
          <v-card-title>
            <h4 class="mt-3">Share your code</h4>
          </v-card-title>
          <v-divider class="mb-0"></v-divider>
          <v-divider class="mb-0"></v-divider>
          <v-divider class="mb-0"></v-divider>
          <v-card-text>
            <v-row justify="center" class="my-3">
              <v-col cols="4" class="text-center">
                <v-btn
                  icon
                  @click="copyCode"
                  size="x-large"
                  class="rounded-circle mb-3"
                >
                  <v-icon large>mdi-content-copy</v-icon>
                </v-btn>
                <p>Copy code</p>
              </v-col>
              <v-col cols="4" class="text-center">
                <v-btn
                  icon
                  @click="shareLink"
                  size="x-large"
                  class="rounded-circle mb-3"
                >
                  <v-icon large>mdi-share-variant</v-icon>
                </v-btn>

                <p>Share Link</p>
              </v-col>
              <v-col cols="4" class="text-center">
                <v-btn
                  icon
                  @click="shareQR"
                  size="x-large"
                  class="rounded-circle mb-3"
                >
                  <v-icon large>mdi-qrcode</v-icon>
                </v-btn>
                <p>Share QR</p>
              </v-col>
            </v-row>
            <div
              class="text-center py-5"
              style="border-radius: 5px; background-color: #fafafa"
            >
              <div>
                <v-p
                  class="text-h5 text-red font-weight-black"
                  style="letter-spacing: 3px !important"
                  v-model="code"
                >
                  {{ code }}
                </v-p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-bottom-sheet>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    visible: false,
    code: null,
    token: null,
    referralLink: null,
    urlToShare: "https://example.com", // URL to share
    linkToShare: null, //"Check this out!", // Text to share
    qrToShare: null, // QR to share
  }),
  created() {
    this.token = localStorage.getItem("client_token");
    setTimeout(() => {
      const userProfile = this.$store.getters.getProfile;
      this.code = userProfile.myReferral;
      this.linkToShare = userProfile.referralLink;
      this.qrToShare = userProfile.referralLink;
    }, 2000);
    //this.$store.dispatch("getUserProfile");
  },
  methods: {
    async checkAuth() {
      if (this.token !== null) {
        this.visible = true;
      } else {
        this.visible = false;
        this.$router.push({ path: "/sign-in" });
      }
    },
    async shareLink() {
      if (this.token !== null) {
        const shareData = {
          title: "Share link",
          text: "Check this out!",
          url: this.linkToShare,
        };
        try {
          await navigator.share(shareData);
        } catch (err) {
          console.log(err);
        }
      } else {
        this.$router.push({ path: "/sign-in" });
      }
    },
    async shareQR() {
      if (this.token !== null) {
        const shareData = {
          title: "Share QR code",
          text: "Check this out!",	
          url: this.qrToShare,
        };
        try {
          await navigator.share(shareData);
        } catch (err) {
          console.log(err);
        }
      } else {
        this.$router.push({ path: "/sign-in" });
      }
    },
    async copyCode() {
      if (this.token !== null) {
        await navigator.clipboard.writeText(this.code);
      } else {
        this.$router.push({ path: "/sign-in" });
      }
    },
  },
};
</script>
