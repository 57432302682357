<template>
  <v-card flat>
    <template v-slot:text>
      <v-text-field
        v-model="search"
        label="Search sector..."
        prepend-inner-icon="mdi-magnify"
        variant="outlined"
        hide-details
        single-line
        clearable
      ></v-text-field>
    </template>
    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
      location="top"
    >
      {{ snackText }}
    </v-snackbar>
    <v-data-table
      color="#b2d7ef"
      rounded="compact"
      divided
      striped
      hover
      class="elevation-1"
      fixed-header
      :search="search"
      :headers="headers"
      :items="sectors"
      :items-per-page="itemsPerPage"
      v-model:page="page"
      :sort-by="[
        { key: 'createAt', order: 'desc' },
        { key: 'name', order: 'asc' },
      ]"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ props }">
            <v-row>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="d-flex align-center mb-4"
              >
                <v-btn
                  class="mb-2 ml-4"
                  color="primary"
                  v-bind="props"
                  prepend-icon="mdi-plus"
                  variant="flat"
                >
                  <div class="text-none font-weight-regular">Add</div>
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="text-right d-flex mb-6 flex-row-reverse"
              >
                <v-sheet class="ma-2 pa-2 text-center">
                  <v-select
                    v-model="itemsPerPage"
                    :items="[10, 25, 50, 100, 1000]"
                    hide-details
                    density="compact"
                    variant="outlined"
                    @update:model-value="itemsPerPage = parseInt($event, 10)"
                    style="width: 100px"
                  ></v-select>
                </v-sheet>
                <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-toolbar>
              <v-toolbar-title prepend-icon="mdi-form-textbox">{{
                formTitle
              }}</v-toolbar-title>

              <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
            </v-toolbar>
            <v-divider class="mb-0"></v-divider>
            <v-divider class="mb-0"></v-divider>
            <v-form ref="form" enctype="multipart/form-data" lazy-validation>
              <v-card-text>
                <v-alert
                  border="end"
                  border-color="warning"
                  elevation="2"
                  color="error"
                  v-show="!visible"
                >
                  {{ msgError }}
                </v-alert>
                <v-row class="mt-1">
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <input type="hidden" v-model="hiddenId" />
                    <v-text-field
                      v-model.trim="name"
                      :rules="[() => !!name || 'This field is required']"
                      label="Sector name"
                      placeholder="Sector name"
                      variant="outlined"
                      prepend-inner-icon="mdi-form-textbox"
                      required
                      autocomplete="off"
                      density="compact"
                      clearable
                      persistent-placeholder
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-text-field
                      v-model.trim="slug"
                      :rules="[() => !!slug || 'This field is required']"
                      label="Slug"
                      placeholder="Slug"
                      variant="outlined"
                      prepend-inner-icon="mdi-slash-forward-box"
                      required
                      autocomplete="off"
                      density="compact"
                      clearable
                      persistent-placeholder
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      clear-icon="mdi-close-circle"
                      label="Description"
                      rows="3"
                      v-model="descr"
                      clearable
                      variant="outlined"
                      density="compact"
                      required
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
            </v-form>

            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                text="Cancel"
                @click="close"
                variant="flat"
                color="error"
              ></v-btn>

              <v-btn
                class="text-none"
                color="primary"
                rounded="xl"
                :text="buttonTitle"
                variant="flat"
                @click="save"
                :disabled="isSubmitting"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <input type="hidden" v-model="hiddenId" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                color="blue-darken-1"
                variant="text"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- detect when error  -->
      <template v-slot:no-data>
        <v-alert
          v-if="items.length > 0"
          :value="true"
          color="error"
          icon="warning"
          class="text-left"
        >
          No data found.
        </v-alert>
      </template>
      <!-- end detect when error  -->
      <!-- Custom column table -->

      <template v-slot:[`item.createAt`]="{ item }">
        {{ new Date(item.createAt).toLocaleString() }}
      </template>
      <template v-slot:[`item.staffUpdateDate`]="{ item }">
        {{
          item.staffUpdateDate !== null
            ? new Date(item.staffUpdateDate).toLocaleString()
            : ""
        }}
      </template>
      <template v-slot:[`item.staff.fullName`]="{ item }">
        {{ item.staff.fullName }}
      </template>
      <template v-slot:[`item.updatedBy.fullName`]="{ item }">
        {{ item.updatedBy != null ? item.updatedBy.fullName : "" }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="ma-2"
          size="x-small"
          color="red-lighten-2"
          @click="editItem(item)"
        >
          <v-icon icon="mdi-pencil" start></v-icon>
          Edit
        </v-btn>
        <!--
        <v-icon title="Edit" class="me-2" size="small" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        
        <v-icon title="Delete" size="small" @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            rounded="circle"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </div>
      </template>
      <!-- End custom column table -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import { URL_SECTOR } from "@/utils/apiUrl";
import { TOKEN_HEADERS } from "@/utils/headerToken";
import moment from "moment";
import { originalCode } from "@/utils/formatNumber";

export default {
  data: () => ({
    snack: false,
    dialog: false,
    dialogDelete: false,
    visible: true,
    isSubmitting: false,

    items: [],
    sectors: [],

    search: null,
    snackColor: null,
    snackText: null,
    msgError: null,
    name: null,
    slug: null,
    descr: null,
    staffLogin: parseInt(originalCode(localStorage.getItem("id"))),

    editedIndex: -1,
    hiddenId: 0,

    page: 1,
    itemsPerPage: 10,

    headers: [
      { title: "", key: "actions", sortable: false, align: "center" },
      { title: "Sector name", key: "name", align: "center" },
      { title: "Slug", key: "slug", align: "center" },
      { title: "Description", key: "descr", align: "center" },
      { title: "Create at", key: "createAt", align: "center" },
      { title: "Created by", key: "staff.fullName", align: "center" },
      { title: "Date updated", key: "staffUpdateDate", align: "center" },
      { title: "Updated by", key: "updatedBy.fullName", align: "center" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Sector" : "Update Sector";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
    pageCount() {
      return Math.ceil(this.sectors.length / this.itemsPerPage);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.PopulateSectors();
  },
  methods: {
    clearContent() {
      this.hiddenId = 0;
      this.name = "";
      this.slug = "";
      this.descr = "";
      this.visible = true;
    },

    async PopulateSectors() {
      await axios.get(URL_SECTOR, { headers: TOKEN_HEADERS }).then((res) => {
        this.sectors = res.data;
        this.items.push(res.data);
      });
    },

    editItem(item) {
      this.editedIndex = this.sectors.indexOf(item);
      this.hiddenId = item.id;
      this.editedItem = Object.assign({}, item);
      this.name = item.name;
      this.slug = item.slug;
      this.descr = item.descr;

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.sectors.indexOf(item);
      this.hiddenId = item.id;

      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //delete from arraylist
      this.sectors.splice(this.editedIndex, 1);
      //delete from DB
      const id = this.hiddenId;
      const url = URL_SECTOR + id;
      axios
        .delete(url, { headers: TOKEN_HEADERS })
        .then(function (response) {
          this.snakeMessage("error", response.data.message);
          this.closeDelete();
        })
        .catch((error) => console.error("record deleted was an error!", error));

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    async submitData() {
      const data = {
        name: this.name,
        slug: this.slug,
        descr: this.descr,
        createdBy: this.staffLogin,
      };

      if (this.editedIndex > -1) {
        // edit
        const id = this.hiddenId;
        const url = URL_SECTOR + id;
        data.staffUpdateId = this.staffLogin;
        data.staffUpdateDate = moment(new Date());

        await axios
          .patch(url, data, { headers: TOKEN_HEADERS })
          .then((response) => {
            this.snakeMessage("success", response.data.message);
            this.clearContent();
            this.dialog = false;
          })
          .catch(
            (error) => {
              this.visible = false;

              if (error.response.data.statusCode === 400) {
                //this.snakeMessage("error", error.response.data.message);
                this.msgError = error.response.data.message;
              } else if (error.request) {
                // The request was made but no response was received
                //this.snakeMessage("error", error.request);
                this.msgError = error.request;
                // Something happened in setting up the request that triggered an Error
                //this.snakeMessage("error", error.message);
                this.msgError = error.message;
              }

              this.dialog = true;
              return false;
            }
            //console.error("record updated was an error!", error)
          );

        this.close();
      } // create new
      else {
        await axios
          .post(URL_SECTOR, data, { headers: TOKEN_HEADERS })
          .then((response) => {
            this.snakeMessage("success", response.data.message);
            this.clearContent();
            this.dialog = false;
          })
          .catch(
            (error) => {
              this.visible = false;

              if (error.response.data.statusCode === 400) {
                //this.snakeMessage("error", error.response.data.message);
                this.msgError = error.response.data.message;
              } else if (error.request) {
                // The request was made but no response was received
                //this.snakeMessage("error", error.request);
                this.msgError = error.request;
                // Something happened in setting up the request that triggered an Error
                //this.snakeMessage("error", error.message);
                this.msgError = error.message;
              }

              this.dialog = true;
              return false;
            }
            //console.error("record updated was an error!", error)
          );
      }

      //this.clearContent();
      this.PopulateSectors();
    },

    async save() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        if (this.isSubmitting) return; // Prevent multiple submissions
        this.isSubmitting = true;

        try {
          // Send your form data to the server
          await this.submitData();
        } catch (error) {
          console.error(error);
        } finally {
          this.isSubmitting = false; // Re-enable the button after the request
        }
        //this.dialog = false;
      }
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },
  },
};
</script>
