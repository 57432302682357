<template>
  <v-row>
    <v-col cols="12" md="6" sm="12" class="my-row">
      <v-card color="white" rounded="0" class="row-box">
        <div class="card-first">
          <router-link to="/" class="text-decoration-none text-black">
            <v-btn icon="mdi-arrow-left" rounded="0" variant="text"></v-btn>
          </router-link>
        </div>
      </v-card>
    </v-col>

    <v-col cols="12" md="6" sm="12" class="my-row">
      <v-card color="white" rounded="0" class="card-height">
        <div class="ma-10">
          <form>
            <p class="text-capitalizes text-right">
              Already have an account?
              <router-link to="/sign-in" class="text-decoration-none">
                <span class="text-blue-darken-4">Login</span>
              </router-link>
            </p>

            <br />

            <h2 class="text-capitalize text-center text-blue-darken-4">
              Create An Account
            </h2>

            <br />
            <br />

            <v-row class="mx-1">
              <v-text-field
                color="primary"
                label="Email"
                placeholder="Email"
                variant="underlined"
                autocomplete="off"
              ></v-text-field>

              <v-text-field
                color="primary"
                label="Phone"
                placeholder="Phone"
                variant="underlined"
                autocomplete="off"
              ></v-text-field>
            </v-row>

            <br />

            <p class="text-uppercase mb-2">Full Name</p>
            <v-text-field
              rounded="xl"
              variant="outlined"
              :counter="10"
              label="Full Name"
              required
              density="compact"
              v-model="form.username"
              autocomplete="off"
            ></v-text-field>

            <p class="text-uppercase mb-2">Email</p>
            <v-text-field
              rounded="xl"
              variant="outlined"
              :counter="10"
              label="E-mail"
              required
              density="compact"
              v-model="form.email"
              autocomplete="off"
            ></v-text-field>

            <p class="text-uppercase mb-2">Password</p>
            <v-text-field
              variant="outlined"
              label="Password"
              required
              rounded="xl"
              density="compact"
              :append-inner-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append-inner="showPassword()"
              :type="show ? 'text' : 'password'"
              v-model="form.password"
              autocomplete="off"
            ></v-text-field>

            <br />

            <router-link to="/dashboard" class="text-decoration-none">
              <v-btn class="me-4" block color="blue-darken-4" rounded="xl"> Login </v-btn>
            </router-link>

            <br />
            <br />

            <p class="text-capitalizes text-center">
              By signing up, you agree to our
              <router-link to="/sign-up" class="text-decoration-none">
                <span class="text-blue-darken-4">term and policy</span>
              </router-link>
            </p>
          </form>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SignInPage",
  data: () => ({
    form: {
      username: "",
      password: "",
      email: "",
    },
    show: false,
  }),
  methods: {
    showPassword() {
      this.show = !this.show;
    },
  },
};
</script>

<style scoped>
.card-first {
  height: 100vh;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  background-image: url("https://images.ctfassets.net/wdjnw2prxlw8/6HRSjw4NJnvoQEKuDF9BsM/25bd19e9dfbfa9be0137096f74c454fa/glass_buildings_view_from_street_to_sky.jpg");
}

.my-row {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 600px) {
  .row-box {
    display: none;
  }

  .card-height {
    height: 100vh;
  }
}
</style>
