<template>
  <div v-if="login">
      <HomeView/>
  </div>
  <div v-else>
    <ProjectPage/>
  </div>
</template>

<script>
import HomeView from '@/views/client/home/HomeView.vue';
import ProjectPage from '@/views/client/projects/ProjectPage.vue';

export default {
  name: 'HomePage',
  data(){
    return {
      login: false,
    };
  },
  created() {
    this.login = !!localStorage.getItem('client_token');
  },
  components: { ProjectPage, HomeView },
};
</script>

<style scoped></style>
