<script>
import FrontendLayout from '@/views/client/components/FrontendLayout.vue';
import CardNews from '@/views/client/covid/components/CardNews.vue';
import { mapState } from 'vuex';
import { getParams } from '@/utils/function';
import { API_URL_USER, PROJECT } from '@/utils/customerApiUrl';

const exploreMores = [
  {
    id: 1,
    image: 'https://s30383.pcdn.co/wp-content/uploads/2024/08/Learning-Design-Level-1-Microcredential.jpg',
  },
  {
    id: 2,
    image: 'https://launcherimpact.org/wp-content/uploads/2024/02/woman-standing-up-in-front-of-colleagues-during-meeting-7644066.jpg.webp',
  },
  {
    id: 3,
    image: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRloX3kR1SbYzJahrcg3KxEIIfsDJ0P9LKPlgP7X1dddci6zbPU',
  },
];

export default {
  name: "CovidPage",
  components:{
    FrontendLayout,
    CardNews
  },
  computed: {
    ...mapState([
      "covid",
    ]),
  },
  data(){
    return {
      exploreMores: exploreMores,
      params: {
        projectStatus: '',
        sector: '',
        skip: 1,
        take: 9,
        country: '',
      },
      total: 10,
      totalPages: 1,
    };
  },
  methods:{
    async getLocation() {
      await fetch(API_URL_USER)
        .then((response) => response.json())
        .then((data) => {
          this.params.country = data.country_name ? data.country_name: data.name;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async fetchData() {
      this.exploreMores = [];
      let params = getParams(this.params);
      this.axios
        .get(PROJECT + params)
        .then((response) => {
          let { data, total , totalPages} = response.data;
          this.exploreMores = data;
          this.totalPages = totalPages;
          this.total = total;
        })
        .catch((error) => {
          console.error('Failed to', error);
        });
    },
  },
  mounted() {
    this.getLocation();
  },
  created() {
    setTimeout(() => {
      this.fetchData();
    }, 1000);
  },
  watch: {
    // filter page
    'params.skip': function() {
      this.fetchData();
    },
  }
}

</script>

<template>
  <FrontendLayout>

    <div class="bg-grey-lighten-4">
      <h2 class="text-uppercase text-center text-blue-darken-3 pa-5">
        COVID-19 Response
      </h2>

      <div class="margin-auto">
        <p class="text-content text-justify" v-html="covid?.description">
        </p>

        <router-link to="/covid-details" class="text-decoration-none text-center">
          <h2 class="text-primary font-weight-bold text-uppercase">
            Read more
            <VIcon>mdi-arrow-right</VIcon>
          </h2>
        </router-link>

        <h2 class="text-uppercase text-blue-darken-3 py-5">
          COVID-19 Response Events and news
        </h2>
      </div>

<!--      <pre>-->
<!--        {{exploreMores[0]}}-->
<!--      </pre>-->

      <CardNews :exploreMores="exploreMores" />

<!--      <div class="d-flex justify-center">-->
<!--        <VBtn width="100" size="" rounded="xl" class="ma-2 text-none hide" variant="tonal" >-->
<!--          First-->
<!--        </VBtn>-->

<!--        <VBtn width="100" size="" rounded="xl" class="ma-2 text-none hide" variant="tonal" >-->
<!--          Previous-->
<!--        </VBtn>-->

<!--        <v-pagination-->
<!--          v-model="params.page"-->
<!--          :length="total"-->
<!--          rounded="circle"-->
<!--          prev-icon=""-->
<!--          next-icon=""-->
<!--        ></v-pagination>-->

<!--        <VBtn width="100" size="" rounded="xl" class="ma-2 text-none hide" variant="tonal" >-->
<!--          Next-->
<!--        </VBtn>-->

<!--        <VBtn width="100" size="" rounded="xl" class="ma-2 text-none hide" variant="tonal" >-->
<!--          Last-->
<!--        </VBtn>-->
<!--      </div>-->

      <div class="app-paginate">
        <v-pagination
          v-model="params.skip"
          :length="totalPages"
          class="d-flex align-center"
          rounded="circle"
          color="primary"
          border
          total-visible="10"
        >
          <template #prev>
            <div class="d-flex">
              <VBtn
                width="100"
                rounded="xl"
                class="ma-2 text-none hide"
                @click="params.skip=1"
                :disabled="1 === totalPages"
                variant="outlined"
                border
                color="primary"
              >
                First
              </VBtn>

              <VBtn
                width="100"
                rounded="xl"
                class="ma-2 text-none hide"
                @click="params.skip--"
                :disabled="params.skip === 1"
                variant="outlined"
                border
                color="primary"
              >
                Previous
              </VBtn>
            </div>
          </template>
          <template #next>
            <div class="d-flex">
              <VBtn
                width="100"
                rounded="xl"
                class="ma-2 text-none hide"
                @click="params.skip++"
                :disabled="params.skip === totalPages"
                variant="outlined"
                border
                color="primary"
              >
                Next
              </VBtn>

              <VBtn
                width="100"
                rounded="xl"
                class="ma-2 text-none hide"
                @click="params.skip = totalPages"
                :disabled="params.skip === totalPages"
                variant="outlined"
                border
                color="primary"
              >
                Last
              </VBtn>
            </div>
          </template>
        </v-pagination>
      </div>

      <br /><br /><br />

    </div>
  </FrontendLayout>
</template>

<style scoped>

</style>