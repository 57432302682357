<script>
import MenuCollapse from '@/views/client/components/MenuCollapse.vue';
import { mapState } from 'vuex';

export default {
  name: 'MobileScreen',
  components: { MenuCollapse },
  computed: {
    ...mapState([
      "login",
    ]),
  },
  props: [
    'aboutUsData',
    'contactData',
    'projectData',
    'ourProcessData',
  ],
  data() {
    return {
      // about us
      about: {
        title: 'About',
        description: this.aboutUsData.description,
        buttonLabel: 'Search More',
        buttonLabelLink: '/about-us',
        menu: [
          {
            title: 'About Us',
            link: '/about-us',
          },
          {
            title: 'Annual Report',
            link: '/annual-report',
          },
          {
            title: 'Political risk & Insurance',
            link: '/population-risk-and-insurances',
          },
          {
            title: 'Events',
            link: '/events',
          },
        ],
      },
      // project
      project: {
        title: 'Project',
        description: this.projectData.description,
        buttonLabel: 'Search More',
        buttonLabelLink: '/projects',
        menu: [
          {
            title: 'Newest Projects',
            link: '/newest-project',
          },
          {
            title: 'Featured Projects',
            link: '/featured-projects',
          },
          {
            title: 'Closing Soon',
            link: '/closing-soon',
          },
          {
            title: 'Crowdfunding',
            link: '/closing-soon',
          },
        ],
      },
      // contact
      contact: {
        title: 'Contact',
        description: this.contactData.desc,
        buttonLabel: 'Search More',
        buttonLabelLink: '/contact-us',
        menu: [],
      },
      // our process
      ourProcess: {
        title: 'Our Process',
        description: this.ourProcessData?.desc,
        buttonLabel: 'Search More',
        buttonLabelLink: '/our-process',
        menu: [
          {
            title: 'Our Impact',
            link: '/our-impact',
          },
          {
            title: 'Asia Pacific',
            link: '/asia-Pacific-web',
          },
          {
            title: 'Small Investment Program',
            link: '/small-investment-program',
          },
          {
            title: 'Currency',
            link: '/currency-web',
          },
        ],
      },
    };
  },
};
</script>

<template>
  <div style="padding: 6px 0"></div>
  <v-card block elevation="0" rounded="0" height="40" class="ma-2">
    <v-text-field color="blue" variant="outlined" density="compact" rounded="xl" append-inner-icon="mdi-magnify" />
  </v-card>

  <MenuCollapse :data="about" />
  <MenuCollapse :data="project" />
  <MenuCollapse :data="contact" />
  <MenuCollapse :data="ourProcess" />

  <router-link to="covid" class="text-decoration-none">
    <v-card height="50" rounded="0" elevation="0">
      <h4 class="text-start text-capitalize pa-4 px-6">Covid</h4>
    </v-card>
  </router-link>

  <router-link to="sign-in" class="text-decoration-none" v-if="!login">
    <v-btn block color="blue" elevation="0" rounded="0" height="50">
      <h4 class="text-uppercase"><v-icon>mdi-login</v-icon> Login </h4>
    </v-btn>
  </router-link>

  <router-link to="projects" class="text-decoration-none" v-if="login">
    <v-btn block color="red" elevation="0" rounded="0" height="50">
      <h4 class="text-uppercase"><v-icon>mdi-logout</v-icon> Logout</h4>
    </v-btn>
  </router-link>

  <router-link to="profile" class="text-decoration-none">
    <v-btn block color="blue" elevation="0" rounded="0" height="50">
      <h4 class="text-uppercase"><v-icon>mdi-account</v-icon> Profile</h4>
    </v-btn>
  </router-link>


<!--    <v-card block color="primary" elevation="0" rounded="0" height="60" width="50%">-->

<!--    </v-card>-->
<!--  </div>-->

<!--  <div class="d-flex justify-space-between">-->
<!--    <v-card class="text-center pt-3 d-flex justify-center" block color="primary" elevation="0" rounded="0" height="60"-->
<!--            width="100%">-->
<!--      <h4 class="mt-2">Follow US </h4>-->

<!--      <v-btn variant="text" class="" icon="mdi-facebook" size="small"></v-btn>-->

<!--      <v-btn variant="text" class="" icon="mdi-linkedin" size="small"></v-btn>-->

<!--      <v-btn variant="text" class="" icon="mdi-twitter" size="small"></v-btn>-->

<!--      <v-btn variant="text" class="" icon="mdi-youtube" size="small"></v-btn>-->

<!--      <v-btn variant="text" class="" icon="mdi-whatsapp" size="small"></v-btn>-->

<!--    </v-card>-->
<!--  </div>-->

</template>

<style scoped>

</style>