<script>
import { formatDate, getParams } from '@/utils/function';
import { DEFAULT_IMAGE, SEARCH_INVEST_NO } from '@/utils/customerApiUrl';

export default {
  name: 'SearchInvestSelect',
  props: ['callbackInvest', 'isVariant', 'isTitle', 'isDensity'],
  data() {
    return {
      defaultImage: DEFAULT_IMAGE,
      search: '',
      list: [],
      variant: 'sold',
      title: 'Search Title',
      density: 'compact',
      formatDate: formatDate,
    };
  },
  methods: {
    async searchData() {
      this.list = [];
      let params = getParams({
        invest_no: this.search,
        take: 10,
      });
      await this.axios
        .get(SEARCH_INVEST_NO + params)
        .then((response) => {
          let { data } = response.data; //console.log(data);
          this.list = data;
        })
        .catch((error) => {
          console.error('Failed to ', error);
        });
    },
    onFocus() {
      this.searchData();
    },
    onClear() {
      this.search = '';
      this.callbackInvest({
        invest_no: '',
      });
    },
    onFilterSearch(value) {
      this.search = value.target.value;
      this.searchData();
    },
    getItemText(item) {
      return `${item.invest_no}`;
    },
  },
  created() {
    this.variant = this.isVariant;
    this.title = this.isTitle;
    this.density = this.isDensity;
  },
  watch: {
    search: function(value) {
      if (value) {
        this.callbackInvest(value);
      }
    },
  },
};
</script>

<template>
  <p class="my-2">{{ title }}</p>
  <v-autocomplete
    v-model="search"
    :items="list"
    elevation="0"
    rounded="xl"
    placeholder="search ..."
    :density="density"
    :variant="variant"
    menu-icon="mdi-chevron-right"
    hide-details
    @keyup="onFilterSearch"
    @focusin="onFocus"
    :item-title="getItemText"
    return-object
  >
    <template v-slot:chip="{ props, item }">
      <v-chip
        v-if="item.raw.invest_no"
        v-bind="props"
        :text=" '#' + item.raw.invest_no"
        closable
        @click:close="onClear()"
      ></v-chip>
    </template>
    <template v-slot:item="{ props, item }">
      <v-list-item v-bind="props"
                   v-if="item.raw.invest_no"
                   :title=" '#' + item.raw.invest_no"
                   :subtitle="formatDate(item.raw.project_start, 'YYYY-MM-DD HH:mm A')"
      >
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<style scoped>

</style>