<script>
import { LOADING_IMAGE } from '@/utils/customerApiUrl';

export default {
  name: 'CardRelated',
  props:['item'],
  data(){
    return {
      LOADING_IMAGE: LOADING_IMAGE
    };
  }
};
</script>

<template>
<!--  <pre>-->
<!--    {{item}}-->
<!--  </pre>-->
  <v-card rounded="xl" variant="outlined" class="my-5">
    <div class="d-flex">
      <v-avatar
        rounded="0"
        size="180"
      >
        <v-img  :src="item.image_project === null ?  LOADING_IMAGE :item.urlImage" :lazy-src="LOADING_IMAGE"></v-img>
      </v-avatar>

      <div class="pa-1">
        <v-card-title class="text-uppercase text-orange">
          <h5> {{item?.investor_country ?? 'Country' }} </h5>
        </v-card-title>

        <h3 class="px-4">
          <p class="one-line-title" v-html="item.project_title"/>
          <div class="mt-5"/>
          <VDivider />
          <div class="mt-3"/>
          <router-link to="/projects" class="text-decoration-none">
            <h5 class="text-primary font-weight-bold text-uppercase">
              Read more
              <VIcon>mdi-arrow-right</VIcon>
            </h5>
          </router-link>
        </h3>
      </div>
    </div>
  </v-card>
</template>

<style scoped>
</style>