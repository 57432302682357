<script>
import { formatPrice } from '@/utils/function';
import { REFERRAL } from '@/utils/customerApiUrl';

export default {
  name: 'InvitationRewards',
  props: ['moneyClaimLevel', 'callBack'],
  data(){
    return {
      loading: false,
      dialog: false,
      formatPrice: formatPrice
    };
  },
  methods:{
    onClaim(row, nameLevel) {
      this.dialog = true;
      this.row = row;
      this.nameLevel = nameLevel;
    },
    handleConfirm() {
      // console.log(this.row);
      this.loading = true;
      let data = {
        rankStar: parseFloat(this.row.referral),
        numberStar: parseFloat(this.row.referral),
        numberReferralAll: parseFloat(this.row.referral),
        amountReward: parseFloat(this.row.price),
        nameLevel: this.nameLevel,
      };
      this.axios
        .post(REFERRAL, data)
        .then((response) => {
          //   console.log(response.data.data);
          let { data } = response;
          if (data) {
            console.log(data);
          }
          this.loading = false;
          this.dialog = false;
          this.callBack();
        })
        .catch((error) => {
          this.loading = false;
          this.dialog = false;
          console.error("Failed to", error);
        });
    },
  }
};
</script>

<template>
<!--    <pre>-->
<!--      {{moneyClaimLevel[0]}}-->
<!--    </pre>-->
  <v-row>
    <v-col
      cols="12"
      md="6"
      v-for="row in moneyClaimLevel"
      v-bind:key="row.nameLevel"
    >
      <v-card rounded="xl" height="" class="pa-3">
        <h4 class="my-2">{{ row.nameLevel }} {{ 0 }}</h4>

        <v-row class="">
          <v-col
            v-for="item in row.listClaim"
            v-bind:key="item"
            cols="4"
            md="2"
          >
            <p class="text-right text-grey text-small">
              {{ item.referral }}
            </p>
            <p class="text-small">
             $ {{ formatPrice(item.price) }}
            </p>
            <v-btn
              class="my-2 text-white text-none"
              elevation="1"
              rounded="xl"
              size="x-small"
              color="orange"
              block
              @click="onClaim(item, row.nameLevel)"
             v-if="!item.claim"
            >Claim
            </v-btn>

             <v-btn
              v-if="item.claim"
              disabled
              class="my-2 text-white text-none"
              rounded="xl"
              size="x-small"
              color="grey"
              block
              >Claim
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>

  <div class="text-center pa-4">
    <v-dialog v-model="dialog" width="auto" persistent>
      <v-card max-width="600">
        <v-card-text>
          <p>Are your sure you want to claim ?</p>
        </v-card-text>

        <v-card-actions>
          <v-btn
            class="ms-auto text-none"
            text="cancel"
            @click="dialog = false"
          ></v-btn>

          <v-btn
            class="ms-auto text-none"
            text="Ok"
            @click="handleConfirm()"
            :loading="loading"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<style scoped>
.text-small {
  font-size: 12px;
}
</style>