<script>
import { Path_Small_Investment_Image, URL_SMALL_INVESTMENT } from "@/utils/apiUrl";
import FrontendLayout from "@/views/client/components/FrontendLayout.vue";
import axios from "axios";

export default {
  name: "SmallInvestmentProgramPage",
  components: { FrontendLayout },
  data() {
    return {
      data: {},
      path: Path_Small_Investment_Image,
    };
  },
  methods: {
    async fetchData() {
      await axios
        .get(URL_SMALL_INVESTMENT)
        .then((response) => {
          this.data = response.data[0];
        })
        .catch((error) => {
          console.error("Failed to", error);
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<template>
  <FrontendLayout>
    <div style="height: 700px; background-color: gainsboro">
      <v-img
        style="height: 700px; width: 100%; background-color: gainsboro"
        cover
        :src="
          data.image !== null ? path + data.image : 'https://assets-v2.lottiefiles.com/a/d0c1a50a-1171-11ee-bc55-77e80401811a/LgJcg4Igt2.gif'
        "
      >
        <template v-slot:placeholder>
          <v-row align="center" class="fill-height ma-0" justify="center">
            <v-progress-circular
              color="grey-lighten-5"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </template>
        <!--
        
        <h3 class="text-uppercase text-center text-white py-5 font-format">
          {{ data.title }}
        </h3> -->
      </v-img>
    </div>

    <div class="bg-grey-lighten-4">
      <h3 class="text-uppercase text-center text-blue-darken-3 pa-5">
        {{ data.title }}
      </h3>
      <div class="margin-auto font-format" v-html="data.desc"></div>
      <br />

    </div>
  </FrontendLayout>
</template>

<style scoped>
.font-format {
  font-size: 0.875rem;
  line-height: 25px;
}
</style>
