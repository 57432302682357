<script>
import CardImageItem from '@/views/client/projects/components/CardImageItem.vue';

export default {
  components: { CardImageItem },
  props: ['exploreMores'],
  name: 'CardNews',
};
</script>

<template>
  <br />
  <br />
<!--  <pre>-->
<!--    {{exploreMores[0]}}-->
<!--  </pre>-->
  <div class="margin-auto">
    <VRow>
      <VCol md="4" sm="6" cols="12" v-for="row in exploreMores" v-bind:key="row.id">
        <CardImageItem :row="row"/>
      </VCol>
    </VRow>
  </div>
  <br />
  <br />
</template>

