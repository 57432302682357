<script>
let defaultMessage = 'Created Successfully.';
export default {
  name: 'SuccessModel',
  props:[
    'dialog',
    'message',
    'onCloseModal',
  ],
  data () {
    return {
      isDialog: null,
      isMessage: '',
    }
  },
  methods:{
    onClose(){
      this.onCloseModal(false);
    }
  },
  created() {
    this.isDialog = this.dialog;
    this.isMessage = this.message ? this.message : defaultMessage;
  },
  watch: {
    isDialog(dialog){
      this.isDialog = dialog;
    }
  }
};
</script>

<template>
  <div class="text-center pa-4">
    <v-dialog
      v-model="isDialog"
      width="auto"
      persistent
    >
      <v-card
        min-width="400"
      >
        <br/>

        <div class="d-flex align-center justify-space-around">
            <v-icon color="green" icon="mdi-check-circle-outline" size="80"></v-icon>
        </div>


        <h1 class="text-center">
          Success
        </h1>

        <v-card-text class="text-center">
          {{isMessage}}
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="green"
            variant="outlined"
            block
            text="OK"
            @click="onClose()"
          ></v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>

</style>