<template>
    <MainLayout>
        <h1>
            Setting
        </h1>
    </MainLayout>
</template>

<script>
    import MainLayout from "../../../components/MainLayout.vue";

    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "Setting",
        components: {MainLayout}
    }
</script>

<style scoped>

</style>
