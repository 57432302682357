<template>
  <!--    <pre class="text-red">{{JSON.stringify(state.form,null,2)}}</pre>-->
  <v-card rounded="0" class="px-10 pa-3">
    <v-row>
      <v-col cols="6" sm="6" md="2">
        <v-img class="mt-2" width="215px" height="40px" :src="state.logo" alt="" />
      </v-col>

      <v-col cols="12" sm="12" md="8" class="hidden">
        <v-stepper :model-value="state.step" elevation="0" v-if="state.step > 0">
          <v-stepper-header>
            <v-stepper-item title="Account type" value="1" complete></v-stepper-item>

            <v-divider></v-divider>

            <v-stepper-item title="Country" value="2"></v-stepper-item>

            <v-divider></v-divider>

            <v-stepper-item title="2FA" value="3"></v-stepper-item>

            <v-divider></v-divider>

            <v-stepper-item title="Password" value="4"></v-stepper-item>
          </v-stepper-header>
        </v-stepper>
      </v-col>

      <v-col cols="6" sm="6" md="2">
        <div class="d-flex justify-end">
          <v-btn variant="text" icon="mdi-close" @click="handleBack"></v-btn>
        </div>
      </v-col>
    </v-row>
  </v-card>

  <!-- Step 1-->
  <div class="margin-center" v-if="state.step == 0">
    <br />
    <br />
    <h2 class="text-center">Create your migawb account</h2>
    <p class="text-center">
      Already have an account
      <router-link
        class="text-decoration-none text-black text-decoration-underline"
        to="/sign-in"
      >
        Login ?
      </router-link>
    </p>
    <div v-if="state.error">
      <br />
      <br />
      <v-card class="pa-4" color="grey-lighten-3" elevation="0">
        <v-avatar color="red">
          <v-icon>mdi-close</v-icon>
        </v-avatar>
        Something went wrong.
      </v-card>
    </div>
    <br />
    <p class="ma-1">First, enter your email address</p>
    <v-form ref="formLogin">
      <v-text-field
        v-model="state.form.email"
        variant="outlined"
        density="compact"
        placeholder="Enter your email address"
        v-on:keyup="handleChange"
        type="email"
        :rules="nameRules"
      ></v-text-field>

      <p>Referral code</p>

      <v-text-field
        variant="outlined"
        density="compact"
        placeholder="Enter referral code"
        v-model="state.form.referral"
      />

      <v-btn block rounded="xl" color="blue" elevation="0" @click="handleSubmit()">
        <p class="text-capitalize">Next</p>
      </v-btn>
    </v-form>

    <br />

    <v-row>
      <v-col md="6" cols="12">
        <p class="my-6s">Or login with</p>
      </v-col>
      <v-col md="6" cols="12">
        <router-link
          to="/forget-password"
          class="text-decoration-none text-blue-darken-1"
        >
          <p class="my-6s text-end">Forget password ?</p>
        </router-link>
      </v-col>
    </v-row>

    <!--            <v-btn block rounded="xl" color="white" @click="googleClick">-->
    <!--              <img-->
    <!--                src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png"-->
    <!--                alt=""-->
    <!--                height="30px"-->
    <!--                width="30px"-->
    <!--              />-->
    <!--              <p class="text-capitalize">Sign in with google</p>-->
    <!--            </v-btn>-->
    <v-row>
      <v-col cols="12">
        <GoogleSignInButton
          @success="handleLoginSuccess"
          @error="handleLoginError"
          ref="google"
        ></GoogleSignInButton>
        <!--                <button @click="recaptcha">Execute recaptcha</button>-->
      </v-col>
    </v-row>

    <br />
    <br />
    <p class="text-center">
      By registering, you accept our
      <router-link
        class="text-decoration-none text-black text-decoration-underline"
        to="/"
      >
        Term of use
      </router-link>
      and
      <router-link
        class="text-decoration-none text-black text-decoration-underline"
        to="/"
      >
        Privacy policy
      </router-link>
    </p>
  </div>
  <!-- Step 1-->

  <!-- Step 2-->
  <div class="margin-center" v-if="state.step == 1">
    <br />
    <br />
    <h2 class="text-center">What kind of account would you like to open today ?</h2>
    <p class="text-center">You can add another account later on, too.</p>
    <br />
    <br />
    <v-row>
      <v-col cols="12" md="6" sm="12">
        <v-card
          color="grey-lighten-3"
          class="text-center pa-3 cursor"
          elevation="0"
          v-on:click="handleClickAccountType(2, 'personal')"
        >
          <img
            src="../../../assets/house-medium@1x.webp"
            alt=""
            height="100px"
            width="100px"
          />
          <h3>Personal Account</h3>
          <span class="small-text">
            Send, spend, and receive around the world for less.
          </span>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-card
          color="grey-lighten-3"
          class="text-center pa-3 cursor-not-allow"
          elevation="0"
          v-on:click="handleClickAccountType(2, 'business')"
        >
          <img
            src="../../../assets/briefcase-medium@1x.webp"
            alt=""
            height="100px"
            width="100px"
          />
          <h3>Business Account</h3>
          <span class="small-text"> Do Business or freelance work internationally. </span>
        </v-card>
      </v-col>
    </v-row>
    <br />
    <br />
    <p class="text-center">
      You mus use Wish in line with our
      <router-link
        class="text-decoration-none text-black text-decoration-underline"
        to="/"
      >
        Acceptable Use Policy
      </router-link>
      <v-icon size="small">mdi-open-in-new</v-icon>
      . You cannot use a personal account for business purposes.
    </p>
  </div>
  <!-- Step 2-->

  <!-- Step 3-->
  <div class="margin-center" v-if="state.step == 2">
    <br />
    <v-btn variant="text" v-on:click="setStep(state.step - 1)">
      <v-icon>mdi-arrow-left</v-icon>
      Back
    </v-btn>
    <br />

    <h2 class="text-center">Where do you live most of the time ?</h2>
    <p class="text-center">By law, we may need to ask for proof of your address.</p>
    <br />
    <br />

    <v-autocomplete
      :items="state.countries"
      v-model="state.form.country"
      item-value="country"
      item-title="country"
      variant="outlined"
      density="compact"
    >
      <template v-slot:item="{ props, item }">
        <v-list-item v-bind="props" :subtitle="item.name"></v-list-item>
      </template>
    </v-autocomplete>

    <v-autocomplete
      :items="state.states"
      v-model="state.form.state"
      item-value="state"
      item-title="state"
      variant="outlined"
      density="compact"
    >
      <template v-slot:item="{ props, item }">
        <v-list-item v-bind="props" :subtitle="item.name"></v-list-item>
      </template>
    </v-autocomplete>

    <v-btn
      block
      rounded="xl"
      color="blue"
      elevation="0"
      v-on:click="handleSubmitAddress()"
    >
      <p class="text-capitalize">Continue</p>
    </v-btn>
    <br />
    <br />
    <p class="text-center">
      Migawb is authorized to operate in
      <router-link
        class="text-decoration-none text-black text-decoration-underline"
        to="/"
      >
        most states.
      </router-link>
      In the other states to which we'er a service provider
    </p>
  </div>
  <!-- Step 3-->

  <!-- Step 4-->
  <div class="margin-center" v-if="state.step == 3">
    <br />
    <v-btn variant="text" v-on:click="setStep(state.step - 1)">
      <v-icon>mdi-arrow-left</v-icon>
      Back
    </v-btn>
    <br />
    <h2 class="text-center">Very your phone number with a code</h2>
    <p class="text-center">
      It helps us keep your account secure.
      <router-link
        class="text-decoration-none text-black text-decoration-underline"
        to="/"
      >
        Learn more
      </router-link>
      <v-icon size="small">mdi-open-in-new</v-icon>
    </p>
    <br />
    <br />

    <v-row>
      <v-col cols="5" sm="5" md="3">
        <v-autocomplete
          :items="state.codes"
          v-model="state.form.prefix"
          variant="outlined"
          density="compact"
          item-value="dial_code"
          item-title="dial_code"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props" :subtitle="item.code"></v-list-item>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="7" sm="7" md="9">
        <v-text-field
          variant="outlined"
          density="compact"
          placeholder="Enter your phone number"
          v-model="state.form.phone"
          v-on:keyup="handlePhoneChange"
          type="number"
        />
      </v-col>
    </v-row>

    <v-btn
      :disabled="!state.form.phone"
      block
      rounded="xl"
      color="blue"
      elevation="0"
      v-on:click="handleSubmitVerification()"
    >
      <p class="text-capitalize">Sent verification code</p>
    </v-btn>
    <br />
    <br />
  </div>
  <!-- Step 4-->

  <!-- Step 5-->
  <div class="margin-center" v-if="state.step == 4">
    <br />
    <v-btn variant="text" v-on:click="setStep(state.step - 1)">
      <v-icon>mdi-arrow-left</v-icon>
      Back
    </v-btn>
    <br />
    <h2 class="text-center">Enter the 6-digit code</h2>
    <br />
    <p class="text-center">
      We sent it to {{ state.form.prefix + state.form.phone }} .
      <span
        class="text-decoration-none text-black text-decoration-underline"
        v-on:click="setStep(state.step - 1)"
      >
        Change phone number
      </span>
    </p>
    <br />
    <br />

    <p>Your 6-digit code</p>

    <v-text-field
      variant="outlined"
      density="compact"
      placeholder="Enter your 6-digit code"
      v-model="state.form.code"
      v-on:keyup="handleCodeChange"
    />

    <p class="text-none text-center text-decoration-underline">I don't receive a code</p>

    <br />

    <v-btn
      :disabled="!state.form.code"
      block
      rounded="xl"
      color="blue"
      elevation="0"
      v-on:click="handleSubmitVerify()"
    >
      <p class="text-capitalize">Sumit</p>
    </v-btn>
    <br />
    <br />
  </div>
  <!-- Step 5-->

  <!-- Step 6-->
  <div class="margin-center" v-if="state.step == 5">
    <br />
    <v-btn variant="text" v-on:click="setStep(state.step - 1)">
      <v-icon>mdi-arrow-left</v-icon>
      Back
    </v-btn>
    <br />
    <h2 class="text-center">We just sent you an SMS</h2>
    <br />
    <p class="text-center">
      To log in, enter the security code we sent to {{ state.form.phone }} .
      <br />
      <span class="text-decoration-none text-black text-decoration-underline">
        learn more
      </span>
    </p>
    <br />
    <br />

    <p>Your 6-digit code</p>

    <v-text-field
      variant="outlined"
      density="compact"
      placeholder="Enter your 6-digit code"
      v-model="state.form.code"
      v-on:keyup="handleCodeChange"
    />

    <v-btn
      :disabled="!state.form.code"
      block
      rounded="xl"
      color="blue"
      elevation="0"
      v-on:click="handleSubmitVerify()"
    >
      <p class="text-capitalize">Done</p>
    </v-btn>

    <br />

    <p class="text-none text-center text-decoration-underline">I don't receive a code</p>
    <br />
    <br />
  </div>
  <!-- Step 6-->

  <!-- Step 7-->
  <div class="margin-center" v-if="state.step == 6">
    <br />
    <v-btn variant="text" v-on:click="setStep(state.step - 1)">
      <v-icon>mdi-arrow-left</v-icon>
      Back
    </v-btn>
    <br />
    <h2 class="text-center">Create your password</h2>
    <br />
    <br />

    <p>Your Password</p>

    <v-text-field
      v-model="state.form.password"
      variant="outlined"
      density="compact"
      autocomplete="off"
      placeholder="Enter your password"
      prepend-inner-icon="mdi-lock"
      :append-inner-icon="!state.visible ? 'mdi-eye-off' : 'mdi-eye'"
      :rules="nameRulesPass"
      :type="!state.visible ? 'password' : 'text'"
      @click:append-inner="state.visible = !state.visible"
      @keydown="checkPassword"
    />

    <p
      class="text-none text-center text-red"
      v-if="state.form.password && !state.passwordStrong"
    >
      <span class="text-small">Password is weak. </span>
    </p>

    <p
      class="text-none text-center text-green"
      v-if="state.form.password && state.passwordStrong"
    >
      <span class="text-small">Password is very strong. </span>
    </p>

    <br />

    <p class="text-none text-center">
      <span class="text-small">Password must contain a</span> latter a number, and be
      minimum of 9 characters
    </p>

    <br />

    <v-btn
      :disabled="!state.passwordStrong"
      block
      rounded="xl"
      color="blue"
      elevation="0"
      v-on:click="handleSubmitPassword()"
    >
      <p class="text-capitalize">Continue</p>
    </v-btn>

    <br />
  </div>
  <!-- Step 7-->

  <!-- Step 8-->
  <div class="margin-center" v-if="state.step == 7">
    <br />
    <v-btn variant="text" v-on:click="setStep(state.step - 1)">
      <v-icon>mdi-arrow-left</v-icon>
      Back
    </v-btn>
    <br />
    <h2 class="text-center">Tell us about yourself</h2>
    <br />
    <br />

    <p>Country of residence</p>

    <v-autocomplete
      :items="state.countries"
      v-model="state.form.country"
      item-value="country"
      item-title="country"
      variant="outlined"
      density="compact"
    >
      <template v-slot:item="{ props, item }">
        <v-list-item v-bind="props" :subtitle="item.name"></v-list-item>
      </template>
    </v-autocomplete>

    <p>Your occupation</p>

    <v-text-field
      variant="outlined"
      density="compact"
      placeholder="Enter your occupation"
      v-model="state.form.occupation"
    />

    <p>Full legal first and middle name(s)</p>

    <v-text-field
      variant="outlined"
      density="compact"
      placeholder="Enter your first name"
      v-model="state.form.firstName"
    />

    <p>Full legal last name(s)</p>

    <v-text-field
      variant="outlined"
      density="compact"
      placeholder="Enter your last name"
      v-model="state.form.lastName"
    />

    <p>Date of birth</p>

    <v-row>
      <v-col cols="3">
        <v-text-field
          variant="outlined"
          density="compact"
          placeholder="DD"
          v-model="state.form.day"
          type="number"
        />
      </v-col>

      <v-col cols="6">
        <v-autocomplete
          :items="state.month"
          v-model="state.form.month"
          item-value="month"
          item-title="month"
          variant="outlined"
          density="compact"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props" :subtitle="item.name"></v-list-item>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="3">
        <v-text-field
          variant="outlined"
          density="compact"
          placeholder="YYYY"
          v-model="state.form.year"
          type="number"
        />
      </v-col>
    </v-row>

    <p>Phone number</p>

    <v-row>
      <v-col cols="3">
        <v-autocomplete
          :items="state.codes"
          v-model="state.form.prefix"
          variant="outlined"
          density="compact"
          item-value="dial_code"
          item-title="dial_code"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props" :subtitle="item.code"></v-list-item>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="9">
        <v-text-field
          variant="outlined"
          density="compact"
          placeholder="Enter your phone number"
          v-model="state.form.phone"
        />
      </v-col>
    </v-row>

    <br />

    <v-btn block rounded="xl" color="blue" elevation="0" v-on:click="handleSubmitInfo()">
      <p class="text-capitalize">Continue</p>
    </v-btn>

    <br />
  </div>
  <!-- Step 8-->

  <!-- Step 9-->
  <div class="margin-center" v-if="state.step == 8">
    <br />
    <v-btn variant="text" v-on:click="setStep(state.step - 1)">
      <v-icon>mdi-arrow-left</v-icon>
      Back
    </v-btn>
    <br />
    <h2 class="text-center">Create your password</h2>
    <br />
    <br />

    <p>Your Password</p>

    <v-text-field
      variant="outlined"
      density="compact"
      placeholder="Enter your password"
      v-model="state.form.password"
      type="password"
    />

    <p class="text-none text-center">
      <span class="text-small">Password must contain a</span> latter a number, and be
      minimum of 9 characters
    </p>

    <br />

    <v-btn
      :disabled="!state.form.password"
      block
      rounded="xl"
      color="blue"
      elevation="0"
      v-on:click="handleSubmitPassword()"
    >
      <p class="text-capitalize">Continue</p>
    </v-btn>

    <br />
  </div>
  <!-- Step 7-->

  <!-- Step 8-->
  <div class="margin-center" v-if="state.step == 9">
    <br />
    <v-btn variant="text" v-on:click="setStep(7)">
      <v-icon>mdi-arrow-left</v-icon>
      Back
    </v-btn>
    <br />
    <h2 class="text-center">Enter your address</h2>
    <br />

    <p class="text-center">You may need to provider proof of this.</p>
    <br />

    <p>Search address</p>

    <v-autocomplete
      :items="state.address"
      v-model="state.form.address"
      variant="outlined"
      density="compact"
      item-title="address"
      item-value="address"
      persistent-hint
      return-object
      single-line
    >
      <template v-slot:item="{ props, item }">
        <v-list-item v-bind="props" :subtitle="item.name"></v-list-item>
      </template>
    </v-autocomplete>

    <br />

    <v-btn
      block
      rounded="xl"
      color="blue"
      elevation="0"
      v-on:click="handleSubmitSearchAddress()"
    >
      <p class="text-capitalize">Continue</p>
    </v-btn>

    <br />
  </div>
  <!-- Step 9-->

  <!-- Step 10-->
  <div class="margin-center" v-if="state.step == 10">
    <br />
    <v-btn variant="text" v-on:click="setStep(9)">
      <v-icon>mdi-arrow-left</v-icon>
      Back
    </v-btn>
    <br />
    <h2 class="text-center">Confirm your address</h2>
    <br />

    <p class="text-center">You may need to provider proof of this.</p>
    <br />

    <p>Home address</p>

    <v-text-field
      variant="outlined"
      density="compact"
      placeholder="Enter home address"
      v-model="state.form.address.address"
    />

    <p>City</p>

    <v-text-field
      variant="outlined"
      density="compact"
      placeholder="Enter city"
      v-model="state.form.address.city"
    />

    <p>ZIP code</p>

    <v-text-field
      variant="outlined"
      density="compact"
      placeholder="Enter ZIP code "
      v-model="state.form.address.zip"
    />

    <p>State</p>

    <v-autocomplete
      :items="state.state"
      v-model="state.form.address.state"
      item-value="state"
      item-title="state"
      variant="outlined"
      density="compact"
    >
      <template v-slot:item="{ props, item }">
        <v-list-item v-bind="props" :subtitle="item.name"></v-list-item>
      </template>
    </v-autocomplete>

    <br />

    <v-btn
      block
      rounded="xl"
      color="blue"
      elevation="0"
      v-on:click="handleSubmitConfirmAddress()"
    >
      <p class="text-capitalize">Continue</p>
    </v-btn>

    <br />
  </div>
  <!-- Step 10-->

  <!-- Step 11-->
  <div class="margin-center" v-if="state.step == 11">
    <br />
    <v-btn variant="text" v-on:click="setStep(state.step - 1)">
      <v-icon>mdi-arrow-left</v-icon>
      Back
    </v-btn>
    <br />
    <h2 class="text-center">Additional infomation</h2>
    <br />

    <p class="text-center">Add the below information to continue.</p>
    <br />

    <p>Nationality</p>

    <v-autocomplete
      :items="state.nationality"
      v-model="state.form.nationality"
      variant="outlined"
      density="compact"
    >
      <template v-slot:item="{ props, item }">
        <v-list-item v-bind="props" :subtitle="item.name"></v-list-item>
      </template>
    </v-autocomplete>

    <br />

    <v-btn
      block
      rounded="xl"
      color="blue"
      elevation="0"
      v-on:click="handleNationality()"
      class="text-none"
    >
      <p class="">Confirm and continue</p>
    </v-btn>

    <br />

    <p
      class="text-none text-center text-decoration-underline"
      v-on:click="setStep(state.step - 1)"
    >
      Go back
    </p>

    <br />
  </div>
  <!-- Step 11-->
</template>

<script setup>
import { reactive, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import {
  setLocalStorage,
  getLocalStorage,
  countryOption,
  monthOption,
  nationalityOption,
  phoneOption,
} from '@/utils/function';
import { decodeCredential } from 'vue3-google-signin';
import axios from 'axios';
import { CUSTOMER_SIGN_UP } from '@/utils/customerApiUrl';

import moment from 'moment';
import { useStore } from 'vuex';
import VueCookies from 'vue-cookies';

const router = useRouter();

const countries = countryOption();

const states = [
  {
    id: '1',
    state: 'Kandal',
  },
  {
    id: '2',
    state: 'Takeo',
  },
  {
    id: '3',
    state: 'Kompong Cham',
  },
];

const month = monthOption();

const address = [
  {
    address: '123 Main St',
    city: 'Phnom Penh',
    state: 'Phnom Penh',
    zip: '12345',
  },
  {
    address: '456 Elm St',
    city: 'Phnom Penh',
    state: 'Phnom Penh',
    zip: '12345',
  },
  {
    address: '789 Oak St',
    city: 'Phnom Penh',
    state: 'Phnom Penh',
    zip: '12345',
  },
];

const nationality = nationalityOption();

const phone = phoneOption();

const state = reactive({
  logo: null,
  visible: false,
  passwordStrong: false,
  step: 0,
  form: {
    email: '',
    account_type: '',
    prefix: '+855',
    phone: '',
    code: '',
    first_name: '',
    last_name: '',
    password: '',
  },
  month: month,
  countries: countries,
  states: states,
  loading: false,
  codes: phone,
  address: address,
  nationality: nationality,
  userInfo: {
    ip: '175.100.46.65',
    city: 'Phnom Penh',
    region: 'Phnom Penh',
    region_code: '12',
    country: 'KH',
    country_name: 'Cambodia',
    country_code: 'KH',
    country_capital: 'Phnom Penh',
    postal: null,
    latitude: 0,
    longitude: 0,
    org: 'ISPIXP IN CAMBODIA WITH THE BEST VERVICE IN THERE.',
  },
  error: false,
  macAddress: '',
});

const setStep = async (step) => {
  state.step = step;
  setLocalStorage('step', step);
};

const handleBack = () => {
  setStep(0);
  setLocalStorage('form_register', []);
  router.go(-1);
};

const saveFormLocal = () => {
  setLocalStorage('form_register', state.form);
};

// step 1
const handleClickAccountType = (key, accountType = 'personal') => {
  setStep(key);
  state.form.account_type = accountType;
  saveFormLocal();
};

// step 2
const handleSubmitAddress = () => {
  setStep(3);
  saveFormLocal();
};

// step 4
const handleSubmitVerification = () => {
  setStep(4);
  saveFormLocal();
};

// step 5
const handleSubmitVerify = () => {
  setStep(6);
  saveFormLocal();
};

const handlePhoneChange = (event) => {
  state.form.phone = event.target.value;
};

const handleCodeChange = (event) => {
  state.form.code = event.target.value;
};

// step 6
const handleSubmitPassword = () => {
  setStep(7);
  saveFormLocal();
};

// step 7
const handleSubmitInfo = () => {
  setStep(9);
  saveFormLocal();
};

// step 8
const handleSubmitSearchAddress = () => {
  setStep(10);
  saveFormLocal();
};

// step 9
const handleSubmitConfirmAddress = () => {
  setStep(11);
  saveFormLocal();
};

const checkPassword = (e) => {
  let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,15}$/;
  let password = e.target.value;
  state.passwordStrong = password.length > 9;
  let isCheck = regex.test(password);
  state.passwordStrong = isCheck;

  //console.log(isCheck);
  // if (isCheck) {
  //      state.passwordStrong = true;
  // } else if (!isCheck) {
  //     state.passwordStrong = false;
  // }
  //console.log(pass1, regex.test(pass1));
  //console.log(e.target.value);
};

const signUp = (payload) => {
  axios
    .post(CUSTOMER_SIGN_UP, payload)
    .then((response) => {
      if (response.data.register) {
        setStep(1);
        return;
      }

      const { accessToken, refreshToken } = response.data;
      setLocalStorage('access_token', accessToken);
      setLocalStorage('refresh_token', refreshToken);
      setLocalStorage('form_register', []);
      setStep(0);

      if (accessToken) {
        //router.push('/');
        location.href = '/';
      }
    })
    .catch(() => {
      state.error = true;
    });
};

const handleNationality = () => {
  let monthIndex = month.findIndex((i) => i === state.form.month) + 1;

  let dateOfBirth = moment([state.form.year, monthIndex, state.form.day]).format(
    'YYYY-MM-DD',
  );

  state.form.dateOfBirth = dateOfBirth;

  saveFormLocal();

  let form = {
    ip: state.userInfo.ip,
    latitude: state.userInfo.latitude.toString(),
    longitude: state.userInfo.longitude.toString(),
    macAddress: state.macAddress,
    regionName: state.userInfo.region,
    verifyCode: '000000',
    ipAddress: state.userInfo.org,
    countryCode: state.userInfo.country_code,
    countryLocation: state.form.state,
    isp: state.userInfo.org,
    gender: 'Other',
    browser: navigator.userAgent,
    register: false,
    customer_login_information: {
      id: 0,
      customer_id: 0,
      latitude: state.userInfo.latitude.toString(),
      longitude: state.userInfo.longitude.toString(),
      ip: state.userInfo.ip,
      browser: navigator.userAgent,
      organization: state.userInfo.org,
      login_date: new Date(),
      country: 'Cambodia',
      country_code: '885',
      region: 'cambodia',
      city: 'phnom penh',
    },
    ...state.form,
    ...state.form.address,
  };

  //console.log(form);

  signUp(form);
};

let formLogin = ref(null);

const nameRules = [
  (value) => {
    if (value) return true;
    return 'Email is required.';
  },
  (value) => {
    if (value.length > 0) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || 'Invalid Email address';
    }
  },
];

// next submit email
const handleSubmit = () => {
  formLogin.value.validate().then(({ valid }) => {
    if (valid) {
      state.form = {
        google_id: '0',
        id: 0,
        email: state.form.email,
        last_name: '',
        first_name: '',
        name: '',
        profile: '',
        referral: '',
      };

      saveFormLocal();

      setStep(1);
    } else {
      state.error = true;
      return null;
    }
  });
};
//
const handleChange = (event) => {
  state.form.email = event.target.value;
  state.error = false;
};
//
const handleLoginSuccess = (response) => {
  //console.log(response);

  const { credential } = response;

  let decodedCredential = decodeCredential(credential);

  decodedCredential = {
    ...decodedCredential,
    phone: '+855',
    register: true,
    google_id: decodedCredential.id || 0,
    account_type: 'personal',
  };

  state.form = {
    id: decodedCredential.id || 0,
    email: decodedCredential.email,
    last_name: decodedCredential.given_name,
    first_name: decodedCredential.family_name,
    name: decodedCredential.name,
    profile: decodedCredential.picture,
    register: false,
  };

  saveFormLocal();

  responseLogin(decodedCredential);
};
//
const responseLogin = (data) => {
  //console.log(data);
  let payload = {
    ...data,
    password: data.email,
    address: data.email,
    city: state.userInfo.city,
    country: state.userInfo.country_name,
    countryCode: state.userInfo.country_code,
    countryLocation: state.userInfo.city,
    first_name: data.family_name,
    gender: 'Other',
    ip: state.userInfo.ip,
    latitude: state.userInfo.latitude.toString(),
    longitude: state.userInfo.longitude.toString(),
    mac_address: state.macAddress,
    nationality: 'Khmer',
    occupation: 'Web Developer',
    phone: data.phone,
    regionName: state.userInfo.region,
    state: state.userInfo.region,
    verify_code: '000000',
    ip_address: data.email,
    last_name: data.family_name,
    date_of_birth: '2020-10-20',
    isp: state.userInfo.org,
    browser: navigator.userAgent,
    customer_login_information: {
      id: 0,
      customer_id: 0,
      latitude: state.userInfo.latitude.toString(),
      longitude: state.userInfo.longitude.toString(),
      ip: state.userInfo.ip,
      browser: navigator.userAgent,
      organization: state.userInfo.org,
      login_date: new Date(),
      country: 'Cambodia',
      country_code: '885',
      region: 'cambodia',
      city: 'phnom penh',
    },
    register: true,
  };

  signUp(payload);
};

// handle an error event
const handleLoginError = () => {
  console.error('Login failed');
};

// fetch user info from IP API to get location data
const userInfo = async () => {
  let userInfo = localStorage.getItem('userInfo');
  if (userInfo !== null) {
    state.userInfo = JSON.parse(userInfo);
  }
  if (userInfo === null) {
    fetch('https://ipapi.co/json')
      .then((response) => response.json())
      .then((data) => {
        state.userInfo = data;
        localStorage.setItem('userInfo', JSON.stringify(data));
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

//
function recaptcha() {
  const recaptchaScript = document.createElement('script');
  recaptchaScript.setAttribute(
    'src',
    'https://www.google.com/recaptcha/enterprise.js?render=6LccbiwqAAAAAOIku8k_AASk_csbrGdaJRBuYwj0',
  );
  document.head.appendChild(recaptchaScript);
}

function formatMacAddress(mac) {
  const formattedMac = mac.replace(/[^A-Fa-f0-9]/g, ''); // Remove non-hex characters
  if (formattedMac.length === 12) {
    return formattedMac.match(/.{1,2}/g).join(':');
  }
  return null; // Invalid MAC address length
}

function syncMac() {
  let device = VueCookies.get('macAddress');
  if (!device) {
    let mac = moment().format('YYMMDDHHmmss');
    VueCookies.set('macAddress', formatMacAddress(mac), (365 * 10) + 'd');
  }
  state.macAddress = device;
}

const store = useStore();
store.dispatch('getCompanyInfo');

onMounted(() => {
  syncMac();
  state.logo = localStorage.getItem('logo') ?? null;
  state.step = getLocalStorage('step') || 0;
  state.form = getLocalStorage('form_register') || {};
  userInfo();
  if (!location.host.includes('localhost')) {
    recaptcha();
  }
  let referral = useRouter().currentRoute.value.query.referral;
  if (referral != null) {
    state.form.referral = referral;
  }
  //console.log(useRouter().currentRoute.value.query.referral-rewards)
});
</script>

<style>
.margin-center {
  width: 600px;
  margin: auto;
}

.small-text {
  font-size: 15px;
  color: #666;
}

.cursor {
  cursor: pointer;
}

.cursor-not-allow {
  cursor: not-allowed;
}

@media only screen and (max-width: 600px) {
  .margin-center {
    width: auto;
    margin: 10px;
  }

  .hidden {
    display: none;
  }
}
</style>
