import { BASE_URL } from "./customerApiUrl";

export const BASE_URL_ADMIN = BASE_URL;
export const URL_STAFF_LOGIN = BASE_URL_ADMIN + '/api/auth-staff/login';
export const URL_STAFF_LOGOUT = BASE_URL_ADMIN + '/api/auth-staff/';
export const URL_COUNTRY = BASE_URL_ADMIN + '/api/staff/all/country/';
export const URL_STAFF = BASE_URL_ADMIN + '/api/staff/';
export const URL_GET_STAFF_BY_PERMISSION = BASE_URL_ADMIN + '/api/staff/permission/';
export const URL_PERMISSION = BASE_URL_ADMIN + '/api/staff-permission/';
export const URL_UPLOAD_STAFF_IMAGE = BASE_URL_ADMIN + '/api/staff/upload-image';
export const URL_GET_STAFF_LOGIN = BASE_URL_ADMIN + '/api/view-staff-login-info';
export const URL_SECTOR = BASE_URL_ADMIN + "/api/sector/";
export const URL_Finance_Contract = BASE_URL_ADMIN + "/api/finance-contract/";
export const URL_Exchange_Deposit = BASE_URL_ADMIN + "/api/exchange-deposit/";
export const URL_STAFF_COUNTRY_PERMISSION = BASE_URL_ADMIN + "/api/exchange-deposit/staffcountry/";
export const URL_UPLOAD_LOGO_CURRENCY = BASE_URL_ADMIN + '/api/exchange-deposit/upload-logo-currency';
export const URL_SETTING_COUNTRY = BASE_URL + '/api/country/';
export const URL_UPLOAD_COUNTRY_FLAG_IMAGE = BASE_URL_ADMIN + '/api/country/upload-country-flag-img';
export const URL_UPLOAD_COUNTRY_CURRENCY_IMAGE = BASE_URL_ADMIN + '/api/country/upload-img-currency';
export const URL_Exchange_Withdraw = BASE_URL_ADMIN + "/api/exchange-withdraw/";
export const URL_UPLOAD_EXCHANGE_WITHDRAW_LOGO_CURRENCY = BASE_URL_ADMIN + "/api/upload-exchange-withdraw-logo-currency/";
export const URL_FEE = BASE_URL_ADMIN + "/api/fee/";
export const URL_INPUT_TOTAL_REPORT = BASE_URL_ADMIN + "/api/input-total-report/";
export const URL_SOCIAL_MEDIA = BASE_URL_ADMIN + "/api/social-media/";
export const URL_UPLOAD_LOGO_SOCIAL_MEDIA = BASE_URL_ADMIN + '/api/social-media/upload-logo-social-media';
export const URL_CUSTOMER_CONFIRMATION = BASE_URL_ADMIN + '/api/customer-confirmation/customer/';
export const URL_UPDATE_CUSTOMER_CONFIRMATION = BASE_URL_ADMIN + '/api/customer-confirmation/';
export const URL_CUSTOMER_TOTAL = BASE_URL_ADMIN + '/api/customer-confirmation/cusomer/total/customers';
export const URL_UPDATE_PROFILE = BASE_URL_ADMIN + '/api/staff/update/profile/';
export const URL_RESET_PASSWORD = BASE_URL_ADMIN + '/api/staff/reset-password/';
export const URL_CHANGE_PASS = BASE_URL_ADMIN + '/api/auth-staff/change-password/';
export const URL_STAFF_LOGIN_PERMISSION = BASE_URL_ADMIN + '/api/staff/staff-login-permission/';
export const URL_BLOCK_WITHDRAW = BASE_URL_ADMIN + '/api/block-withdraw/';
export const URL_UPDATE_MULTI_BLOCK_WITHDRAW = BASE_URL_ADMIN + '/api/block-withdraw/update-multi-block-withdraw/';
export const URL_CLEAR_NOTIFICATION = BASE_URL_ADMIN + '/api/customer-confirmation/clear-notofication/';
export const URL_STAFF_VIEW_LOGIN = BASE_URL_ADMIN + '/api/auth-staff/staff-login-locat-info';
export const URL_BANK_TYPE = BASE_URL_ADMIN + '/api/type-bank/';
export const URL_UPLOAD_LOGO_BANK_TYPE = BASE_URL_ADMIN + '/api/type-bank/upload-img-bank-type';
export const URL_BANK_ACC_DEPOSIT = BASE_URL_ADMIN + '/api/bank-acc-deposit-backend/';
export const URL_BANK_ACC_DEPOSIT_SEARCH = BASE_URL_ADMIN + '/api/bank-acc-deposit-backend/search/';
export const URL_BANK_NAME_BY_TYPE_METHOD_PAYMENT = BASE_URL_ADMIN + '/api/bank-acc-deposit-backend/bank-name-by-type-method-payment/';
export const URL_FEE_ACCOUNT_BALANCE = BASE_URL_ADMIN + '/api/fee-account-ballance/';
export const URL_CUS_FEE_ACCOUNT_BALANCE = BASE_URL_ADMIN + '/api/fee-account-ballance/populate-customers/by-country-of-staff-login/';
export const URL_FEE_ACCOUNT_BALANCE_UPDATE_CUS = BASE_URL_ADMIN + '/api/fee-account-ballance/update-customer/';
export const URL_RESET_PASS_CUS = BASE_URL_ADMIN + '/api/customer/reset-password/';
export const URL_CUSTOMERS = BASE_URL_ADMIN + '/api/customer/';
export const URL_CUSTOMERS_DETAIL = BASE_URL_ADMIN + '/api/customer/customer-detail/';
export const URL_CUSTOMERS_LOGIN = BASE_URL_ADMIN + '/api/customer-login/customer/';
export const URL_COMPENSATION = BASE_URL_ADMIN + '/api/compensation/';
export const URL_STAR_RANK_REFERRAL = BASE_URL_ADMIN + '/api/star-rank-referral/';
export const URL_ADD_MONEY_CLAIM_LEVEL = BASE_URL_ADMIN + '/api/add-money-claim-level/';
export const URL_COMPANY = BASE_URL_ADMIN + '/api/company/';
export const URL_PROJECT_TITLE = BASE_URL_ADMIN + '/api/project-title/';
export const URL_ABOUT_US = BASE_URL_ADMIN + '/api/about-us/';
export const URL_ADD_THREE_LEVEL_CLAIM = BASE_URL_ADMIN + '/api/add-three-level-claim/';
export const URL_PROJECT = BASE_URL_ADMIN + '/api/project/';
export const URL_PROJECT_STATUS = BASE_URL_ADMIN + '/api/project/get/project-status/';
export const URL_ASIA_PACIFIC = BASE_URL_ADMIN + '/api/asia-pacific/';
export const URL_PROJECT_EVENT = BASE_URL_ADMIN + '/api/project-event/';
export const URL_CONTACT_US = BASE_URL_ADMIN + '/api/contact-us/';
export const URL_CURRENCY = BASE_URL_ADMIN + '/api/currency/';
export const URL_COVID = BASE_URL_ADMIN + '/api/covid19/';
export const URL_OUR_IMPACT = BASE_URL_ADMIN + '/api/our-impact/';
export const URL_OUR_PROCESS = BASE_URL_ADMIN + '/api/our-process/';
export const URL_SMALL_INVESTMENT = BASE_URL_ADMIN + '/api/small-investment/';
export const URL_TERM_PRIVACY = BASE_URL_ADMIN + '/api/term-privacy/';
export const URL_Our_Political_Risk_Insurance = BASE_URL_ADMIN + '/api/our-political-risk-insurance/';
export const URL_Feedback_Admin = BASE_URL_ADMIN + '/api/feedback-admin/';
export const URL_SUBSCRIBE = BASE_URL_ADMIN + '/api/customer-subscribe/';
export const URL_GET_CUS_ID = BASE_URL_ADMIN + '/api/customer-subscribe/customer/';
export const URL_BLOCK_CUS_CONDITION_CLAIM = BASE_URL_ADMIN + '/api/customer-confirmation/block-condition-claim/';
export const URL_UNBLOCK_CUS_CONDITION_CLAIM = BASE_URL_ADMIN + '/api/customer-confirmation/unblock-condition-claim/';
export const URL_INVITE_FRIEND = BASE_URL_ADMIN + '/api/invite-friend/';

//Image
export const Path_Staff_Image = BASE_URL_ADMIN + '/api/staff/file/path/';
export const Path_COUNTRY_Currency_Image = BASE_URL_ADMIN + '/api/country/file/currency-img/';
export const Path_Customer_Image = BASE_URL_ADMIN + '/api/customer-confirmation/file/customer/image/';
export const Path_Country_Flag_Image = BASE_URL_ADMIN + '/api/country/file/country-flag/';
export const Path_Social_Media_Image = BASE_URL_ADMIN + '/api/social-media/file/logo-social-media/';
export const Path_Bank_Type_Logo = BASE_URL_ADMIN + '/api/type-bank/file/bank-type-logo/';
export const Path_LOGO_COMPANY = BASE_URL_ADMIN + '/api/company/file/company-logo/';
export const Path_PROJECT_TITLE_IMAGE = BASE_URL_ADMIN + '/api/project-title/file/project-title-image/';
export const Path_ABOUT_US_IMAGE = BASE_URL_ADMIN + '/api/about-us/file/about-us-image/';
export const Path_PROJECT_IMAGE = BASE_URL_ADMIN + '/api/project/file/project-image/';
export const Path_ASIA_PACIFIC_IMAGE = BASE_URL_ADMIN + '/api/asia-pacific/file/asia-pacific-image/';
export const Path_Project_Event_Image = BASE_URL_ADMIN + '/api/project-event/file/project-event-image/';
export const Path_Currency_Image = BASE_URL_ADMIN + '/api/currency/file/currency-image/';
export const Path_Covid_Image = BASE_URL_ADMIN + '/api/covid19/file/covid-image/';
export const Path_Our_Impact_Image = BASE_URL_ADMIN + '/api/our-impact/file/our-impact-image/';
export const Path_Our_Process_Image = BASE_URL_ADMIN + '/api/our-process/file/our-process-image/';
export const Path_Small_Investment_Image = BASE_URL_ADMIN + '/api/small-investment/file/small-investment-image/';
export const Path_Term_Privacy_Image = BASE_URL_ADMIN + '/api/term-privacy/file/term-privacy-image/';
export const Path_Our_Political_Risk_Insurance_Image = BASE_URL_ADMIN + '/api/our-political-risk-insurance/file/political-risk-insurance-image/';
export const Path_Feedback_Image = BASE_URL_ADMIN + '/api/feedback-admin/file/feedback-image/';
export const Path_Contact_Us_Image = BASE_URL_ADMIN + '/api/contact-us/file/contact-us-image/';
export const Path_Bank_Acc_Deposit_Qrcode = BASE_URL_ADMIN + '/api/bank-acc-deposit-backend/file/bank-acc-deposit-qrcode-image/';
export const Pah_Invite_Friend = BASE_URL_ADMIN + '/api/invite-friend/file/invite-friend-image/';

//Upload Image
export const URL_UPLOAD_COMPANY_LOGO = BASE_URL_ADMIN + '/api/company/upload-company-logo/';
export const URL_UPLOAD_PROJECT_TITLE_IMAGE = BASE_URL_ADMIN + '/api/project-title/upload-project-title-image/';
export const URL_UPLOAD_ABOUT_US_IMAGE = BASE_URL_ADMIN + '/api/about-us/upload-about-us-image/';
export const URL_UPLOAD_PROJECT_IMAGE = BASE_URL_ADMIN + '/api/project/upload-project-image/';
export const URL_UPLOAD_ASIA_PACIFIC_IMAGE = BASE_URL_ADMIN + '/api/asia-pacific/upload-asia-pacific-image/';
export const URL_UPLOAD_PROJECT_EVENT_IMAGE = BASE_URL_ADMIN + '/api/project-event/upload-project-event-image/';
export const URL_UPLOAD_CURRENCY_IMAGE = BASE_URL_ADMIN + '/api/currency/upload-currency-image/';
export const URL_UPLOAD_COVID_IMAGE = BASE_URL_ADMIN + '/api/covid19/upload-covid-image/';
export const URL_UPLOAD_OUR_IMPACT_IMAGE = BASE_URL_ADMIN + '/api/our-impact/upload-our-impact-image/';
export const URL_UPLOAD_OUR_PROCESS_IMAGE = BASE_URL_ADMIN + '/api/our-process/upload-our-process-image/';
export const URL_UPLOAD_SMALL_INVESTMENT_IMAGE = BASE_URL_ADMIN + '/api/small-investment/upload-small-investment-image/';
export const URL_UPLOAD_TERM_PRIVACY_IMAGE = BASE_URL_ADMIN + '/api/term-privacy/upload-term-privacy-image/';
export const URL_UPLOAD_Our_Political_Risk_Insurance_IMAGE = BASE_URL_ADMIN + '/api/our-political-risk-insurance/upload-political-risk-insurance-image/';
export const URL_UPLOAD_FEEDBACK_IMAGE = BASE_URL_ADMIN + '/api/feedback-admin/upload-feedback-image/';
export const URL_UPLOAD_CONTACT_US_IMAGE = BASE_URL_ADMIN + '/api/contact-us/upload-contact-us-image/';
export const URL_UPLOAD_Bank_Acc_Deposit_Qrcode = BASE_URL_ADMIN + '/api/bank-acc-deposit-backend/upload-bank-acc-deposit-qrcode-image/';
export const URL_UPLOAD_INVITE_FRIEND = BASE_URL_ADMIN + '/api/invite-friend/upload-invite-friend-image/';

// Frontend
export const URL_PROJECT_BY_LIMIT = BASE_URL_ADMIN + '/api/project/get-projects';
export const URL_FILTER_PROJECT_EVENT = BASE_URL_ADMIN + '/api/project-event/filter-event';