<script>
import FrontendLayout from '@/views/client/components/FrontendLayout.vue';
import { mapState } from 'vuex';

export default {
  name: "CovidDetailsPage",
  components:{
    FrontendLayout,
  },
  computed: {
    ...mapState([
      "covid",
    ]),
  },
  data(){
    return {
      form: {
        page: 1,
      },
    };
  },
}

</script>

<template>
  <FrontendLayout>

    <div class="bg-grey-lighten-4">
      <h2 class="text-uppercase text-center text-blue-darken-3 pa-5">
        COVID-19 Response
      </h2>

      <div class="margin-auto">

        <p class="text-content text-justify" v-html="covid?.description"></p>

        <br /><br />

        <v-card min-height="400" rounded="0" elevation="0">
          <v-img
            :src="covid?.image"
            lazy-src="https://loading.io/assets/mod/spinner/spinner/lg.gif">
          </v-img>
        </v-card>

      </div>

      <br /><br /><br />

    </div>
  </FrontendLayout>
</template>

<style scoped>

</style>