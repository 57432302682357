<template>
  <v-card flat>
    <template v-slot:text>
      <v-text-field
        v-model="search"
        label="Search contract..."
        prepend-inner-icon="mdi-magnify"
        variant="outlined"
        hide-details
        single-line
      ></v-text-field>
    </template>
    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
      location="top"
    >
      {{ snackText }}
    </v-snackbar>
    <v-data-table
      color="#b2d7ef"
      rounded="compact"
      divided
      striped
      hover
      class="elevation-1"
      fixed-header
      :search="search"
      :headers="headers"
      :items="contracts"
      :items-per-page="itemsPerPage"
      v-model:page="page"
      :sort-by="[{ key: 'createdAt', order: 'desc' }]"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ props }">
            <v-row>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="d-flex align-center mb-4"
              >
                <v-btn
                  class="mb-2 ml-4"
                  color="primary"
                  v-bind="props"
                  prepend-icon="mdi-plus"
                  variant="flat"
                >
                  <div class="text-none font-weight-regular">Add</div>
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="text-right d-flex mb-6 flex-row-reverse"
              >
                <v-sheet class="ma-2 pa-2 text-center">
                  <v-select
                    v-model="itemsPerPage"
                    :items="[10, 25, 50, 100, 1000]"
                    hide-details
                    density="compact"
                    variant="outlined"
                    @update:model-value="itemsPerPage = parseInt($event, 10)"
                    style="width: 100px"
                  ></v-select>
                </v-sheet>
                <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-toolbar>
              <v-toolbar-title prepend-icon="mdi-form-textbox">{{
                formTitle
              }}</v-toolbar-title>

              <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
            </v-toolbar>
            <v-divider class="mb-0"></v-divider>
            <v-divider class="mb-0"></v-divider>
            <v-form ref="form" enctype="multipart/form-data" lazy-validation>
              <v-card-text>
                <v-alert
                  border="end"
                  border-color="warning"
                  elevation="2"
                  color="error"
                  v-show="!visible"
                >
                  {{ msgError }}
                </v-alert>
                <v-row class="mt-1">
                  <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                    <input type="hidden" v-model="hiddenId" />
                    <v-text-field
                      v-model.number.trim="numberContrast"
                      :rules="[
                        () => !!numberContrast || 'This field is required',
                      ]"
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                      label="Number Contrast"
                      placeholder="Number Contrast"
                      variant="outlined"
                      prepend-inner-icon="mdi-cash"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                    <v-text-field
                      v-model.number.trim="valueContrast"
                      :rules="[
                        () => !!valueContrast || 'This field is required',
                      ]"
                      label="Value Contrast"
                      placeholder="Value Contrast"
                      variant="outlined"
                      prepend-inner-icon="mdi-cash"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                    <v-select
                      v-model="sectorId"
                      :items="sectors"
                      :item-title="'name'"
                      :item-value="'id'"
                      :rules="[() => !!sectorId || 'This field is required']"
                      label="Sector"
                      variant="outlined"
                      density="compact"
                      prepend-inner-icon="mdi-flag-outline"
                      required
                      autocomplete="off"
                      persistent-placeholder
                      placeholder="Choose sector..."
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea
                      clear-icon="mdi-close-circle"
                      label="Description"
                      rows="3"
                      v-model="descr"
                      variant="outlined"
                      density="compact"
                      required
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
            </v-form>

            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                text="Cancel"
                @click="close"
                variant="flat"
                color="error"
              ></v-btn>

              <v-btn
                class="text-none"
                color="primary"
                rounded="xl"
                :text="buttonTitle"
                variant="flat"
                @click="save"
                :disabled="isSubmitting"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <input type="hidden" v-model="hiddenId" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                color="blue-darken-1"
                variant="text"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- detect when error  -->
      <template v-slot:no-data>
        <v-alert
          v-if="items.length > 0"
          :value="true"
          color="error"
          icon="warning"
          class="text-left"
        >
          No data found.
        </v-alert>
      </template>
      <!-- end detect when error  -->
      <!-- Custom column table -->

      <template v-slot:[`item.numberContrast`]="{ item }">
        {{ this.convertNumber1000To1K1M1B(item.numberContrast) }}
      </template>
      <template v-slot:[`item.valueContrast`]="{ item }">
        {{ this.convertNumber1000To1K1M1B(item.valueContrast) }}
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <v-icon icon="mdi-clipboard-text-clock"></v-icon>
        {{ new Date(item.createdAt).toLocaleString() }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="ma-2"
          size="x-small"
          color="red-lighten-2"
          @click="editItem(item)"
        >
          <v-icon icon="mdi-pencil" start></v-icon>
          Edit
        </v-btn>
        <!--
        <v-icon title="Edit" class="me-2" size="small" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon title="Delete" size="small" @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            rounded="circle"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </div>
      </template>
      <!-- End custom column table -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import { URL_Finance_Contract, URL_SECTOR } from "@/utils/apiUrl";
import { TOKEN_HEADERS } from "@/utils/headerToken";
import { originalCode, formatNumber } from "@/utils/formatNumber";

export default {
  data: () => ({
    snack: false,
    dialog: false,
    dialogDelete: false,
    visible: true,
    isSubmitting: false,

    items: [],
    contracts: [],
    sectors: [],
    validation: {
      min: 0,
      max: 10,
      decimal: 10,
    },

    search: null,
    snackColor: null,
    snackText: null,
    msgError: null,
    numberContrast: 0,
    valueContrast: 0,
    descr: null,
    sectorId: null,

    editedIndex: -1,
    hiddenId: 0,

    page: 1,
    itemsPerPage: 10,

    headers: [
      { title: "", key: "actions", sortable: false, align: "center" },
      { title: "Sector name", key: "sector.name" },
      { title: "Number Contrast", key: "numberContrast", align: "center" },
      { title: "Value Contrast", key: "valueContrast", align: "center" },
      { title: "Description", key: "descr" },
      { title: "Create at", key: "createdAt", align: "center" },
      { title: "created by", key: "staff.fullName", align: "center" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Conract" : "Update Contract";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
    pageCount() {
      return Math.ceil(this.contracts.length / this.itemsPerPage);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.PopulateSectors();
    this.PopulateContracts();
  },
  methods: {
    clearContent() {
      this.hiddenId = 0;
      this.numberContrast = 0;
      this.valueContrast = 0;
      this.descr = "";
      this.sectorId = null;
      this.visible = true;
    },

    async PopulateContracts() {
      await axios
        .get(URL_Finance_Contract, { headers: TOKEN_HEADERS })
        .then((res) => {
          this.contracts = res.data;
          this.items.push(this.contracts);
        });
    },

    editItem(item) {
      this.editedIndex = this.contracts.indexOf(item);
      this.hiddenId = item.id;
      //this.editedItem = Object.assign({}, item);
      this.numberContrast = item.numberContrast;
      this.valueContrast = item.valueContrast;
      this.sectorId = item.sectorId;
      this.descr = item.descr;

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.contracts.indexOf(item);
      this.hiddenId = item.id;

      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //delete from arraylist
      this.contracts.splice(this.editedIndex, 1);
      //delete from DB
      const id = this.hiddenId;
      const url = URL_Finance_Contract + id;
      axios
        .delete(url, { headers: TOKEN_HEADERS })
        .then(function (response) {
          this.snakeMessage("error", response.data.message);
          this.closeDelete();
        })
        .catch((error) => console.error("record deleted was an error!", error));

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    async submitData() {
      const data = {
        sectorId: parseInt(this.sectorId),
        numberContrast: parseFloat(this.numberContrast),
        valueContrast: parseFloat(this.valueContrast),
        descr: this.descr,
        createdBy: originalCode(parseInt(localStorage.getItem("id"))),
      };

      if (this.editedIndex > -1) {
        // edit
        const id = this.hiddenId;
        const url = URL_Finance_Contract + id;

        await axios
          .patch(url, data, { headers: TOKEN_HEADERS })
          .then((response) => {
            this.snakeMessage("success", response.data.message);
            this.clearContent();
            this.dialog = false;
          })
          .catch(
            (error) => {
              this.visible = false;

              if (error.response.data.statusCode === 400) {
                //this.snakeMessage("error", error.response.data.message);
                this.msgError = error.response.data.message;
              } else if (error.request) {
                // The request was made but no response was received
                //this.snakeMessage("error", error.request);
                this.msgError = error.request;
                // Something happened in setting up the request that triggered an Error
                //this.snakeMessage("error", error.message);
                this.msgError = error.message;
              }

              this.dialog = true;
              return false;
            }
            //console.error("record updated was an error!", error)
          );

        this.close();
      } // create new
      else {
        await axios
          .post(URL_Finance_Contract, data, { headers: TOKEN_HEADERS })
          .then((response) => {
            this.snakeMessage("success", response.data.message);
            this.clearContent();
            this.dialog = false;
          })
          .catch(
            (error) => {
              this.visible = false;

              if (error.response.data.statusCode === 400) {
                //this.snakeMessage("error", error.response.data.message);
                this.msgError = error.response.data.message;
              } else if (error.request) {
                // The request was made but no response was received
                //this.snakeMessage("error", error.request);
                this.msgError = error.request;
                // Something happened in setting up the request that triggered an Error
                //this.snakeMessage("error", error.message);
                this.msgError = error.message;
              }

              this.dialog = true;
              return false;
            }
            //console.error("record updated was an error!", error)
          );
      }

      //this.clearContent();
      this.PopulateContracts();
    },

    async save() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        if (this.isSubmitting) return; // Prevent multiple submissions
        this.isSubmitting = true;

        try {
          // Send your form data to the server
          await this.submitData();
        } catch (error) {
          console.error(error);
        } finally {
          this.isSubmitting = false; // Re-enable the button after the request
        }
        //this.dialog = false;
      }
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },

    async PopulateSectors() {
      axios.get(URL_SECTOR, { headers: TOKEN_HEADERS }).then((response) => {
        response.data.forEach((sectors) => {
          this.sectors.push({
            id: sectors.id,
            name: sectors.name,
          });
        });
      });
    },

    formatNumber(e) {
      if (e > this.validation.max) {
        return this.validation.max;
      } else if (e < this.validation.min) {
        return this.validation.min;
      } else if (
        Math.round(e * this.validation.decimal) / this.validation.decimal !==
        e
      ) {
        return this.lastValue;
      } else {
        this.lastValue = e;
        return e;
      }
    },

    convertNumber1000To1K1M1B(value) {
      return formatNumber(value);
    },
  },
};
</script>
