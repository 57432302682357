<template>
  <div>
<!--    <pre>-->
<!--      {{startRankReferrals}}-->
<!--    </pre>-->
    <v-data-table  hide-default-footer density="compact">
      <thead class="row-none-wrap">
        <tr class="bg-primary">
          <td class="">Member Level</td>
          <td class=" text-center">Total Value</td>
          <td class=" text-center">Ratio</td>
          <td class=" text-center">Transfer & Upgrade</td>
          <td class=" text-center">Service Fee</td>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(row,index) in startRankReferrals" v-bind:key="index" class="row-none-wrap">
          <td class="tds">
            <p v-if="row.num_star === 0">{{row.rank_name}}</p>
            <div v-if="row.num_star> 0">
              <v-icon
                v-for="i in (row.num_star)"
                :key="i"
                size="18"
                color="orange"
                icon="mdi-star"
              ></v-icon>
              <v-icon
                v-for="i in 5 - row.num_star"
                :key="i"
                size="18"
                color="grey"
                icon="mdi-star"
              ></v-icon>
            </div>

          </td>
          <td class=" text-center">$ - {{ formatPrice(row.total_value) }}</td>
          <td class=" text-center">{{ formatPrice(row.percent_ratio) }}</td>
          <td class=" text-center">
            <v-icon color="green" v-if="row.transfer_upgrade">mdi-check-circle</v-icon>
            <v-icon color="red" v-else>mdi-minus-circle</v-icon>
          </td>
          <td class=" text-center">{{row.service_fee}} %</td>
        </tr>
      </tbody>

    </v-data-table>

  </div>
</template>
<script>
import { mapState } from 'vuex';
import { formatPrice } from '@/utils/function';

export default {
  name: "MemberLevel",
  computed: {
    ...mapState(['startRankReferrals'])
  },
  data() {
    return {
      formatPrice: formatPrice,
      // memberLevel: [
      //   {
      //     "id": 8,
      //     "rank_name": "Ordinary Level",
      //     "num_star": 0,
      //     "percent_ratio": 1,
      //     "description": "",
      //     "transfer_upgrade": "",
      //     "total_value": 0,
      //     "service_fee": 0
      //   },
      //   {
      //     "id": 9,
      //     "rank_name": "Level Star 1",
      //     "num_star": 1,
      //     "percent_ratio": 2,
      //     "description": "",
      //     "transfer_upgrade": "",
      //     "total_value": 0,
      //     "service_fee": 0
      //   },
      //   {
      //     "id": 10,
      //     "rank_name": "Level Star 2",
      //     "num_star": 3,
      //     "percent_ratio": 3,
      //     "description": "",
      //     "transfer_upgrade": "",
      //     "total_value": 0,
      //     "service_fee": 0
      //   },
      //   {
      //     "id": 11,
      //     "rank_name": "Level Star 3",
      //     "num_star": 3,
      //     "percent_ratio": 4,
      //     "description": "",
      //     "transfer_upgrade": "",
      //     "total_value": 0,
      //     "service_fee": 0
      //   },
      //   {
      //     "id": 12,
      //     "rank_name": "Level Star 4",
      //     "num_star": 4,
      //     "percent_ratio": 5,
      //     "description": "",
      //     "transfer_upgrade": "",
      //     "total_value": 0,
      //     "service_fee": 0
      //   },
      //   {
      //     "id": 13,
      //     "rank_name": "Level Star 5",
      //     "num_star": 5,
      //     "percent_ratio": 6,
      //     "description": "",
      //     "transfer_upgrade": "",
      //     "total_value": 0,
      //     "service_fee": 10
      //   }
      // ],
    };
  },
};
</script>
<style scoped>
.td {
  padding: 10px;
}
</style>
