<template>
  <v-card flat>
    <template v-slot:text>
      <v-row>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-text-field
            v-model.trim="start"
            label="Start Date"
            placeholder="Registered Date"
            variant="outlined"
            autocomplete="off"
            hide-details
            single-line
            type="Date"
            @change="filterItems"
            persistent-placeholder
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-text-field
            v-model.trim="end"
            label="End Date"
            placeholder="Registered Date"
            variant="outlined"
            autocomplete="off"
            hide-details
            single-line
            type="Date"
            @change="filterItems"
            persistent-placeholder
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-select
            v-if="(isAllCountry === 1) | (isAdmin === 1)"
            v-model="countryId"
            :items="countries"
            :item-title="'name'"
            :item-value="'id'"
            label="Country"
            variant="outlined"
            prepend-inner-icon="mdi-flag-outline"
            required
            persistent-placeholder
            placeholder="Choose country..."
            return-object
            single-line
            @update:modelValue="filterItems"
          >
          </v-select>
          <!-- NOTE: when we use @update.movelValue or :on-change the data return to json 
                                  but else the data return as value normally -->
          <v-select
            v-else
            v-model="countryId"
            :items="countries"
            :item-title="'name'"
            :item-value="'id'"
            label="Country"
            variant="outlined"
            prepend-inner-icon="mdi-flag-outline"
            required
            persistent-placeholder
            placeholder="Choose country..."
            return-object
            single-line
            disabled
            @update:modelValue="filterItems"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-text-field
            v-model="search"
            label="Search staff login..."
            prepend-inner-icon="mdi-magnify"
            variant="outlined"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor" location="top">
      {{ snackText }}
    </v-snackbar>
    <v-data-table
      class="dark"
      rounded="compact"
      divided
      fixed-header
      striped
      hover
      color="secondary"
      :search="search"
      :headers="headers"
      :items="staffLogin"
      :items-per-page="itemsPerPage"
      v-model:page="page"
      :sort-by="[{ key: 'createdAt', order: 'asc' }]"
    >
      <template v-slot:top>
        <v-sheet class="d-flex flex-row-reverse">
          <v-sheet class="ma-2 pa-2 text-center">
            <v-select
              v-model="itemsPerPage"
              :items="[10, 25, 50, 100, 1000]"
              hide-details
              density="compact"
              variant="outlined"
              @update:model-value="itemsPerPage = parseInt($event, 10)"
              style="width: 100px"
            ></v-select>
          </v-sheet>
          <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
        </v-sheet>
        <v-dialog
          v-model="dialog"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar>
              <v-toolbar-title prepend-icon="mdi-form-textbox">{{
                formTitle
              }}</v-toolbar-title>

              <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
            </v-toolbar>
            <v-divider class="mb-4"></v-divider>
            <v-sheet border="lg opacity-12" class="text-body-2">
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-card variant="variant" class="mx-auto" elevation="16">
                      <v-img
                        height="40vh"
                        :src="
                          profileImg === null
                            ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                            : profileImg
                        "
                        contain
                      >
                        <template v-slot:placeholder>
                          <v-row align="center" class="fill-height ma-0" justify="center">
                            <v-progress-circular
                              color="grey-lighten-5"
                              indeterminate
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-col>

                  <v-col cols="12" md="4">
                    <ul class="ps-4 mb-6" style="list-style-type: none">
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-account"></v-icon> First name:
                          {{ firstName }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-account"></v-icon> Last name:
                          {{ fullName }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-email"></v-icon> Email:
                          {{ email }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-clipboard-text-clock"></v-icon> DoB:
                          {{ dob }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-card-account-details-outline"></v-icon>
                          Card name:
                          {{ idCardName }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-map-marker"></v-icon> Address:
                          {{ address }}
                        </p>
                      </li>
                      <!--
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-ip"></v-icon> IP:
                          {{ ip }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon
                            icon="mdi-map-marker-account-outline"
                          ></v-icon>
                          Longitude:
                          {{ longitude }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon
                            icon="mdi-map-marker-account-outline"
                          ></v-icon>
                          Latitude:
                          {{ latitude }}
                        </p>
                      </li>

                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-code-braces-box"></v-icon> Country
                          code:
                          {{ countryCode }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-rename-box"></v-icon> Region code:
                          {{ countryRegion }}
                        </p>
                      </li>
                      -->
                    </ul>
                  </v-col>
                  <v-col cols="12" md="4">
                    <ul class="ps-4 mb-6" style="list-style-type: none">
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-account"></v-icon> Last name:
                          {{ lastName }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-gender-male"></v-icon> Sex:
                          {{ gender }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-phone-in-talk"></v-icon> Phone:
                          {{ phone }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-list-status"></v-icon> Status:
                          {{ status }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-list-status"></v-icon> Card No.:
                          {{ idCardNumber }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-login"></v-icon> Login at:
                          {{ loginAt }}
                        </p>
                      </li>
                      <!--
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-open-in-app"></v-icon> Browser:
                          {{ browser }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-city"></v-icon> City:
                          {{ city }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-flag"></v-icon> Country:
                          {{ country }}
                        </p>
                      </li>
                      -->
                    </ul>
                  </v-col>
                  <!--
                  <v-col cols="12">
                    <GoogleMap
                      api-key="AIzaSyCu9J7pzagPked6IaykBLi4VZORGeDzvUw"
                      style="width: 100%; height: 400px"
                      :center="center"
                      :zoom="15"
                    >
                      <Marker :options="{ position: center }" />
                    </GoogleMap>
                  </v-col>
                  -->
                </v-row>
              </v-container>
            </v-sheet>

            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                text="Cancel"
                @click="close"
                variant="flat"
                color="error"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- detect when error  -->
      <template v-slot:no-data>
        <v-alert
          v-if="items.length > 0"
          :value="true"
          color="error"
          icon="warning"
          class="text-left"
        >
          No data found.
        </v-alert>
      </template>
      <!-- end detect when error  -->
      <!-- Custom column table -->
      <template v-slot:[`item.staff.fullName`]="{ item }">
        <v-avatar size="64" class="my-1" v-if="item.staff.imgProfile !== null">
          <v-img :src="path + item.staff.imgProfile" cover>
            <!--
            <div
              :class="
                item.staff.online === 1 ? 'dot online-green' : 'dot offline-red'
              "
            ></div> -->
            <template v-slot:placeholder>
              <v-row align="center" class="fill-height ma-0" justify="center">
                <v-progress-circular
                  color="grey-lighten-5"
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-avatar>
        <v-avatar
          class="my-1"
          v-else
          image="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
          size="80"
        >
          <template v-slot:placeholder>
            <v-row align="center" class="fill-height ma-0" justify="center">
              <v-progress-circular
                color="grey-lighten-5"
                indeterminate
              ></v-progress-circular>
            </v-row>
          </template>
        </v-avatar>

        <v-span class="ml-2">
          {{ item.staff !== null ? item.staff.fullName : "" }}
        </v-span>
      </template>

      <template v-slot:[`item.loginAt`]="{ item }">
        {{ new Date(item.loginAt).toLocaleString() }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="ma-2" size="x-small" color="success" @click="editItem(item)">
          <v-icon icon="mdi-eye" start></v-icon>
          Previous
        </v-btn>
        <!--
        <v-icon
          class="me-2"
          color="success"
          size="large"
          @click="editItem(item)"
           title="Edit"
        >
          mdi-eye
        </v-icon> -->
      </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            rounded="circle"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </div>
      </template>
      <!-- End custom column table -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import { Path_Staff_Image, URL_COUNTRY, URL_GET_STAFF_LOGIN } from "@/utils/apiUrl";
import { TOKEN_HEADERS } from "@/utils/headerToken";
/**import { GoogleMap, Marker } from "vue3-google-map";*/
import { originalCode } from "@/utils/formatNumber";

export default {
  /**components: { GoogleMap, Marker },*/
  data: () => ({
    search: "",
    start: null,
    end: null,
    snack: false,
    snackColor: "",
    snackText: "",
    dialog: false,
    dialogDelete: false,

    items: [],
    staffLogin: [],
    countries: ["-- All --"],

    path: Path_Staff_Image,
    profileImg: null,
    firstName: null,
    lastName: null,
    fullName: null,
    phone: null,
    email: null,
    gender: null,
    dob: null,
    address: null,
    idCardName: null,
    idCardNumber: null,
    ip: null,
    isp: null,
    browser: null,
    longitude: null,
    latitude: null,
    city: null,
    country: null,
    countryCode: null,
    countryRegion: null,
    loginAt: null,
    status: null,
    center: null,
    countryId: null,

    isAdmin: originalCode(parseInt(localStorage.getItem("admin"))),
    isAllCountry: originalCode(parseInt(localStorage.getItem("allCountry"))),
    staffCountryId: originalCode(parseInt(localStorage.getItem("countryId"))),

    page: 1,
    itemsPerPage: 10,

    headers: [
      { title: "", align: "center", key: "actions", sortable: false },
      {
        title: "Staff",
        align: "left",
        sortable: true,
        key: "staff.fullName",
      },
      { title: "Email", align: "center", key: "staff.email" },
      { title: "Phone", align: "center", key: "staff.phone" },
      { title: "DoB", align: "center", key: "staff.dob" },
      { title: "Address", align: "center", key: "staff.address" },
      { title: "create at", align: "center", key: "loginAt" },
    ],

    editedIndex: -1,
    hiddenId: 0,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "View Staff Login" : "Edit Permission";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
    pageCount() {
      return Math.ceil(this.staffLogin.length / this.itemsPerPage);
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.populateStaffLogin();
    this.PopulateCountries();
  },
  methods: {
    async populateStaffLogin() {
      await axios.get(URL_GET_STAFF_LOGIN, { headers: TOKEN_HEADERS }).then((res) => {
        this.staffLogin = res.data;
        this.items.push(res.data);
      });
    },

    isAdminOrAllCountryPermission() {
      return (this.isAdmin || this.isAllCountry) === 1 ? true : false;
    },

    async filterItems() {
      if (this.countryId === null) {
        this.populateStaffLogin();
      } else {
        await axios
          .get(URL_GET_STAFF_LOGIN, {
            headers: TOKEN_HEADERS,
          })
          .then((res) => {
            //this.customerConfirmations = res.data;
            const startDate = this.start
              ? new Date(this.start).toLocaleDateString()
              : null;
            const endDate = this.end ? new Date(this.end).toLocaleDateString() : null;

            const searchCountryId = this.countryId.id;

            this.staffLogin = res.data.filter((item) => {
              const itemDate = new Date(item.loginAt).toLocaleDateString();
              const itemCountry = item.staff.country.id;

              const matchesDateRange =
                (!startDate || itemDate >= startDate) &&
                (!endDate || itemDate <= endDate);
              const matchesCountry = !searchCountryId || itemCountry === searchCountryId;

              //alert(startDate + ", " + itemDate + ", " + matchesDateRange);
              //const matchesSearch = !search || item.name.toLowerCase().includes(search);

              return matchesDateRange && matchesCountry;
            });

            this.items.push(this.staffLogin);
          });
      }
    },

    editItem(item) {
      this.profileImg =
        item.staff.imgProfile === null
          ? "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
          : this.path + item.staff.imgProfile;

      this.firstName = item.staff.firstName;
      this.lastName = item.staff.lastName;
      this.fullName = item.staff.fullName;
      this.gender = item.staff.gender;
      this.email = item.staff.email;
      this.phone = item.staff.phone;
      this.dob = item.staff.dob;
      this.status = item.staff.status;
      this.idCardName = item.staff.idCardName;
      this.idCardNumber = item.staff.idCardNumber;
      this.address = item.staff.address;
      this.loginAt = new Date(item.loginAt).toLocaleString();
      this.ip = item.ip;
      this.isp = item.isp;
      this.browser = item.browser;
      this.longitude = item.longitude;
      this.latitude = item.latitude;
      this.city = item.city;
      this.country = item.country;
      this.countryCode = item.countryCode;
      this.countryRegion = item.countryRegion;

      this.center = {
        lat: parseFloat(item.latitude),
        lng: parseFloat(item.longitude),
      };

      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async PopulateCountries() {
      axios.get(URL_COUNTRY, { headers: TOKEN_HEADERS }).then((response) => {
        response.data.forEach((country) => {
          this.countries.push({
            id: country.id,
            name: country.name,
          });
        });

        this.countryId = this.isAdminOrAllCountryPermission()
          ? "-- All --"
          : this.staffCountryId;
      });
    },
  },
};
</script>
<style scoped>
.dot {
  position: relative;
  top: 39px;
  right: -44px;
  width: 15px;
  height: 15px;

  border-radius: 50%;
}
.online-green {
  background-color: green;
}
.offline-red {
  background-color: red;
}
</style>
