<script>
import CardRelated from '@/views/client/projects/components/CardRelated.vue';
import { mapState } from 'vuex';

export default {
  name: 'RelatedNews',
  computed: {
    ...mapState(["featureProject"]),
  },
  components: { CardRelated },
  data() {
    return {};
  },
};
</script>

<template v-if="featureProject.length > 0">
  <h3>
    Related
  </h3>
  <VDivider />
  <template v-for="(item,i) in featureProject" v-bind:key="i">
    <CardRelated :item="item" />
  </template>
  <VDivider />
</template>

<style scoped>

</style>