import { createStore } from 'vuex';
import { API_URL_USER, BANK_TYPE, COMPANY, CUSTOMER_PROFILE } from '@/utils/customerApiUrl';
import axiosInstance from '@/servicesAxios/axiosInstance';
import { getParams, getClientToken } from '@/utils/function';

export default createStore({
  state: {
    token: getClientToken(),
    login: false,
    profile: {
      email: '',
      firstName: '',
      lastName: '',
      name: '',
      profile: '',
      numberLevel: '',
      phone: '',
      status: '',
      myReferral: '',
      identity: '',
      expireDate: '',
      gender: '',
      nationality: '',
      countryOfBirth: '',
      cityOfBirth: '',
      dateOfBirth: '',
      occupation: '',
      typeCard: '',
      cardNumber: '',
      frontCard: '',
      backCard: '',
      referral: '',
      referralLink: '',
    },
    wallet: [
      {
        id: 0,
        availableBalance: 0,
        accountBalance: 0,
        bankAccount: 'N/A',
        walletType: '',
        pin_code: '',
      },
    ],
    socialMedia: {
      facebookLink: null,
      telegramLink: null,
      whatsApp: null,
      linkedIn: null,
      twitterLink: null,
      remark: null,
    },
    bankType: [
      // [
      //     {
      //         "id": 1,
      //         "bankName": "ABA Bank",
      //         "logoBank": "https://cdn6.aptoide.com/imgs/2/a/6/2a6b391e2053870eac06539bd99d51a6_icon.png",
      //         "paymentMethod": "USDT"
      //     },
      //     {
      //         "id": 2,
      //         "bankName": "Wing Bank",
      //         "logoBank": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0bm5ePMqe3PDWJChqdXheEWyJbx9RwAi6t4dxBPSbvy-57L1nAx8fP2zVz71_9L5sD2I&usqp=CAU",
      //         "paymentMethod": "USDT"
      //     },
      //     {
      //         "id": 3,
      //         "bankName": "Bitcon",
      //         "logoBank": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWc-OjffrnH_evPd-49v8XxFi9MpiSK9rhDg&s",
      //         "paymentMethod": "USDT"
      //     },
      // ]
    ],
    bankAccountWithdraw: [
      // {
      //     "id": 1,
      //     "bankTypeId": 6,
      //     "fullName": "Admin",
      //     "typeBank": null,
      //     "checkType": "Checking",
      //     "routing": "12343678",
      //     "usdtQr": "",
      //     "usdtLink": "11",
      //     "wallet": null,
      //     "cardNumber": "12343678",
      //     "cvc": null,
      //     "expire": null,
      //     "createdAt": "2024-09-04T01:13:39.568Z",
      //     "updatedAt": "2024-09-04T01:13:39.569Z",
      //     "is_active": 1,
      //     "customer_id": 1
      // },
    ],
    company: {},
    aboutUs: {},
    project: {},
    contact: {},
    ourProcess: {},
    covid: {},
    totalReport: {},
    featureProject: {},
    country: '',
    compensations: [],
    startRankReferrals: [],
    notification: 0,
  },
  getters: {
    getProfile: (state) => state.profile,
  },
  mutations: {
    SET_LOGIN(state, payload) {
      state.login = payload;
    },
    SET_PROFILE(state, payload) {
      state.profile = payload;
    },
    SET_WALLET(state, payload) {
      state.wallet = payload;
    },
    SET_SOCIAL_MEDIA(state, payload) {
      state.socialMedia = payload;
    },
    SET_BANK_TYPE(state, payload) {
      state.bankType = payload;
    },
    SET_BANK_ACCOUNT_WITHDRAW(state, payload) {
      state.bankAccountWithdraw = payload;
    },
    SET_COMPANY(state, payload) {
      state.company = payload;
    },
    SET_ABOUT_US(state, payload) {
      state.aboutUs = payload;
    },
    SET_PROJECT(state, payload) {
      state.project = payload;
    },
    SET_CONTACT(state, payload) {
      state.contact = payload;
    },
    SET_OUR_PROCESS(state, payload) {
      state.ourProcess = payload;
    },
    SET_COVID(state, payload) {
      state.covid = payload;
    },
    SET_FEATURE_PROJECT(state, payload) {
      state.featureProject = payload;
    },
    SET_TOTAL_REPORT(state, payload) {
      state.totalReport = payload;
    },
    SET_COUNTRY(state, payload) {
      state.country = payload;
    },
    SET_COMPENSATION(state, payload) {
      state.compensations = payload;
    },
    SET_STAR_RANK_REFERRALS(state, payload) {
      state.startRankReferrals = payload;
    },
    SET_NOTIFICATION(state, payload) {
      state.notification = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
  },
  actions: {
    async getUserProfile({ commit }) {
      if (await getClientToken()) {
        axiosInstance
          .get(CUSTOMER_PROFILE)
          .then((response) => {
            if (response.data !== null) {
              const {
                user,
                wallet,
                socialMedia,
                bankAccountWithdraw,
                compensations,
                startRankReferrals,
                notification,
              } = response.data;
              commit('SET_PROFILE', user);
              commit('SET_WALLET', wallet);
              commit('SET_SOCIAL_MEDIA', socialMedia[0]);
              commit('SET_LOGIN', true);
              commit('SET_BANK_ACCOUNT_WITHDRAW', bankAccountWithdraw);
              commit('SET_COMPENSATION', compensations);
              commit('SET_STAR_RANK_REFERRALS', startRankReferrals);
              commit('SET_NOTIFICATION', notification);
            }
          })
          .catch((error) => {
            commit('SET_LOGIN', false);
            console.log('profile : ', error);
          });
      }
    },

    async getCompanyInfo({ commit }) {

      if (Object.keys(this.state.company).length === 0) {
        // get current country
        let country_name = '';
        await fetch(API_URL_USER)
          .then((response) => response.json())
          .then((data) => {
            country_name = data.country_name ? data.country_name : data.name;
            commit('SET_COUNTRY', country_name);
          })
          .catch((error) => {
            console.error(error);
          });

        if (await getClientToken()) {
          let params = getParams({
            page: 0,
            pageSize: 100,
            country: country_name,
          });
          axiosInstance
            .get(BANK_TYPE + params)
            .then((response) => {
              if (response.data !== null) {
                const { data } = response;
                commit('SET_BANK_TYPE', data);
              }
            })
            .catch((error) => {
              commit('SET_LOGIN', false);
              console.log('bank type : ', error);
            });
        }

        let paramsInfo = getParams({
          type: 'info',
          country: country_name,
        });
        axiosInstance
          .get(COMPANY + paramsInfo)
          .then((response) => {
            if (response.data !== null) {
              const {
                company,
                ourProcess,
                contact,
                project,
                aboutUs,
                covid,
                featureProject,
                totalReport,
              } = response.data;
              commit('SET_COMPANY', company);
              commit('SET_ABOUT_US', aboutUs);
              commit('SET_CONTACT', contact);
              commit('SET_PROJECT', project);
              commit('SET_OUR_PROCESS', ourProcess);
              commit('SET_COVID', covid);
              commit('SET_FEATURE_PROJECT', featureProject);
              commit('SET_TOTAL_REPORT', totalReport);
              let logo = localStorage.getItem('logo');
              if (!logo) {
                localStorage.setItem('logo', company.logo);
                localStorage.setItem('companyName', company.companyName);
              }
              //
              if (company) {
                const link = document.createElement('link');
                link.rel = 'icon';
                link.type = 'image/png';
                link.href = company.logo;
                document.head.appendChild(link);
                document.head.title = company.companyName;
              }
            }
          })
          .catch((error) => {
            console.log('bank type : ', error);
          });
      }
    },
  },
});
