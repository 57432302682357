import OurImpactPage from '@/views/client/our-process/OurImpactPage.vue';
import AsiaPacificWebPage from '@/views/client/our-process/AsiaPacificWebPage.vue';
import SmallInvestmentProgramPage from '@/views/client/our-process/SmallInvestmentProgramPage.vue';
import CurrencyWebPage from '@/views/client/our-process/CurrencyWebPage.vue';
import UkrainePage from '@/views/client/ukraine/UkrainePage.vue';
import PoliticalRiskInsurancePage from '@/views/client/about-us/PoliticalRiskInsurancePage.vue';
import AnnualReportPage from '@/views/client/about-us/AnnualReportPage.vue';
import CovidPage from '@/views/client/covid/CovidPage.vue';
import CovidDetailsPage from '@/views/client/covid/CovidDetailsPage.vue';
import EventsPage from '@/views/client/about-us/EventsPage.vue';
import EventsDetailsPage from '@/views/client/about-us/EventsDetailsPage.vue';
import ClosingSoonPage from '@/views/client/projects/ClosingSoonPage.vue';
import CrowdfundingPage from '@/views/client/projects/CrowdfundingPage.vue';
import FeaturedProjectsPage from '@/views/client/projects/FeaturedProjectsPage.vue';
import NewestProjectPage from '@/views/client/projects/NewestProjectPage.vue';
import SubscribeFormPage from '@/views/client/contact-us/SubscribeFormPage.vue';
import AboutUs from '@/views/client/about-us/AboutUs.vue';
import ProjectPage from '@/views/client/projects/ProjectPage.vue';
import OutProcessPage from '@/views/client/our-process/OutProcessPage.vue';
import ContactUs from '@/views/client/contact-us/ContactUs.vue';
import ProjectDetailsPage from '@/views/client/projects/ProjectDetailsPage.vue';
import ProfilePage from '@/views/client/profile/ProfilePage.vue';
import LoginForm from '@/views/client/auth/LoginForm.vue';
import ForgetPasswordPage from '@/views/client/auth/ForgetPasswordPage.vue';
import SignupPage from '@/views/client/auth/SignupPage.vue';
import SignInPage from '@/views/client/auth/SignInPage.vue';
import HomePage from '@/views/client/home/HomePage.vue';
import PrivacyPage from '@/views/client/about-us/PrivacyPage.vue';

export const clientRouters = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
    meta: { requiredAuth: false },
  },
  {
    path: "/sign-in",
    name: "LoginForm",
    component: LoginForm,
    meta: { requiredAuth: false },
  },
  {
    path: "/sign-up",
    name: "SignupPage",
    component: SignupPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/sign-up-with-phone",
    name: "SignInPage",
    component: SignInPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/forget-password",
    name: "ForgetPasswordPage",
    component: ForgetPasswordPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/profile",
    name: "ProfilePage",
    component: ProfilePage,
    meta: { requiredAuth: true },
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUs,
    meta: { requiredAuth: false },
  },
  {
    path: "/our-process",
    name: "OutProcessPage",
    component: OutProcessPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/about-us", // to  about-us
    name: "AboutUs",
    component: AboutUs,
    meta: { requiredAuth: false },
  },
  {
    path: "/projects",
    name: "ProjectPage",
    component: ProjectPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/project-details",
    name: "ProjectDetailsPage",
    component: ProjectDetailsPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/our-impact",
    name: "OurImpactPage",
    component: OurImpactPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/asia-pacific-web",
    name: "AsiaPacificWebPage",
    component: AsiaPacificWebPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/small-investment-program",
    name: "SmallInvestmentProgramPage",
    component: SmallInvestmentProgramPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/currency-web",
    name: "CurrencyWebPage",
    component: CurrencyWebPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/ukraine",
    name: "UkrainePage",
    component: UkrainePage,
    meta: { requiredAuth: false },
  },
  {
    path: "/political-risk-insurances",
    name: "PoliticalRiskInsurancePage",
    component: PoliticalRiskInsurancePage,
    meta: { requiredAuth: false },
  },
  {
    path: "/annual-report",
    name: "AnnualReportPage",
    component: AnnualReportPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/covid",
    name: "CovidPage",
    component: CovidPage,
    meta: { requiredAuth: false },
  },

  {
    path: "/covid-details",
    name: "CovidDetailsPage",
    component: CovidDetailsPage,
    meta: { requiredAuth: false },
  },

  {
    path: "/events",
    name: "EventsPage",
    component: EventsPage,
    meta: { requiredAuth: false },
  },

  {
    path: "/events-details",
    name: "EventsDetailsPage",
    component: EventsDetailsPage,
    meta: { requiredAuth: false },
  },

  {
    path: "/closing-soon",
    name: "ClosingSoonPage",
    component: ClosingSoonPage,
    meta: { requiredAuth: false },
  },

  {
    path: "/crowd-funding",
    name: "CrowdfundingPage",
    component: CrowdfundingPage,
    meta: { requiredAuth: false },
  },

  {
    path: "/feature-projects",
    name: "FeaturedProjectsPage",
    component: FeaturedProjectsPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/newest-project",
    name: "NewestProjectPage",
    component: NewestProjectPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/subscribe-form",
    name: "SubscribeFormPage",
    component: SubscribeFormPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/privacy",
    name: "PrivacyPage",
    component: PrivacyPage,
    meta: { requiredAuth: false },
  },
];