<script>
import { CUSTOMER_INVEST_TRANSACTION } from '@/utils/customerApiUrl';
import { formatPrice, getParams, formatDate } from '@/utils/function';
import ProjectSelect from '@/views/client/projects/components/ProjectSelect.vue';
import SearchInvestSelect from '@/views/client/profile/transactions/components/SearchInvestSelect.vue';
import moment from 'moment/moment';

export default {
  name: 'NoticesTab',
  components: { SearchInvestSelect, ProjectSelect },
  data() {
    return {
      formatDate: formatDate,
      formatPrice: formatPrice,
      dateRange: null,
      params: {
        take: 10,
        skip: 1,
        projectId: '',
        startDate: '',
        endDate: '',
        search: '',
        invest_no: '',
        status: '',
        is_crowdfunding: '',
      },
      loading: false,
      totalPages: 0,
      total: 0,
      data: [],
      headers: [
        { align: 'start', key: 'id', sortable: false, title: 'Project ID' },
        { key: 'invest_no', title: 'Invest No' },
        { key: 'buy_share', title: 'buy project' },
        { key: 'price_per_share', title: ' Price per share' },
        { key: 'interest', title: 'Interest (%)' },
        { key: 'star_rank', title: 'Star rank (%)' },
        { key: 'type', title: 'Type return ' },
        { key: 'contrast', title: 'Contrast' },
        { key: 'status', title: 'Status' },
        { key: 'interest_profit', title: 'Interest profit' },
        { key: 'total_profit', title: 'Total profit' },
        { key: 'created_at', title: 'Project Start' },
        { key: 'created_at', title: 'Project End' },
        { key: 'is_crowdfunding', title: 'Project Type' },
      ],
      // for test
      dialog: false,
      row: {},
      // project overview
      dialogOverview: false,
    };
  },
  methods: {

    async fetchData() {
      this.loading = true;
      let params = getParams(this.params);
      await this.axios
        .get(CUSTOMER_INVEST_TRANSACTION + params)
        .then((response) => {
          let { data, total, totalPages } = response.data;
          ///console.log(response.data.data);
          this.data = data;
          this.total = total;
          this.totalPages = totalPages;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error('Failed to', error);
        });
    },

    callbackProject(project) {
      if (project) {
        this.params.projectId = project.id;
        this.fetchData();
      }
    },

    callbackInvest(invest) {
      if (invest) {
        this.params.invest_no = invest.invest_no;
        this.fetchData();
      }
    },

    rowClick(row) {
      this.dialog = true;
      this.row = row;
    },

    rowClickDialogOverview(row) {
      this.dialogOverview = true;
      this.row = row;
    },

    onTransfer(row) {
      if (row.status === 'Transfer') {
        console.log(row);
        alert('Are you sure you want to transfer ?');
      }
    },
  },
  created() {
    this.fetchData();
  },
  watch: {
    // filter page
    'params.skip': function() {
      this.fetchData();
    },
    'params.take': function() {
      this.fetchData();
    },
    'params.search': function(search) {
      this.params.search = search;
      this.fetchData();
    },
    'params.is_crowdfunding': function(is_crowdfunding) {
      if (is_crowdfunding.includes('All')) {
        this.params.is_crowdfunding = '';
      }
      this.fetchData();
    },
    'params.status': function(status) {
      if (status.includes('All')) {
        this.params.status = '';
      }
      this.fetchData();
    },
    'dateRange': function(dateRange) {
      if (dateRange && dateRange.length > 0) {
        let startDate = dateRange[0];
        let endDate = dateRange[dateRange.length - 1];
        this.params.startDate = moment(startDate).format('YYYY-MM-DD');
        this.params.endDate = moment(endDate).format('YYYY-MM-DD');
        this.fetchData();
      }
    },
  },
};
</script>

<template>
<!--        <pre>-->
<!--          {{ data[0] }}-->
<!--        </pre>-->
  <v-card class="pa-5" rounded="0" min-height="140" elevation="0" border>
    <v-row>
      <v-col cols="12" md="2">
        <p class="ma-2">Choose Date Range</p>

        <div>
          <v-date-input
            prepend-icon=""
            v-model="dateRange"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            class="elevation-0"
            append-inner-icon="mdi-calendar-range"
            placeholder="YYYY/MM/DD - YYYY/MM/DD"
            color="blue"
            multiple="range"
          ></v-date-input>
        </div>

      </v-col>

      <v-col cols="12" md="2">
        <ProjectSelect
          :callback-project="callbackProject"
          is-density="comfortable"
          is-title="Select Project ID"
          is-variant="outlined"
          :propertyAll="false"
        />
      </v-col>

      <v-col cols="12" md="2">
        <SearchInvestSelect
          :callback-invest="callbackInvest"
          is-density="comfortable"
          is-title="Select Invest No"
          is-variant="outlined" />
      </v-col>

      <v-col cols="12" md="2">
        <p class="ma-2">Select Status</p>

        <div>
          <v-autocomplete
            menu-icon="mdi-chevron-right"
            v-model="params.status"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            :items="[
              'All',
              'Successful',
              'Transfer',
              'Pending',
            ]"
            class="elevation-0"
            color="blue"
          ></v-autocomplete>
        </div>

      </v-col>

      <v-col cols="12" md="2">
        <p class="ma-2">Project Type</p>
        <v-autocomplete
          menu-icon="mdi-chevron-right"
          v-model="params.is_crowdfunding"
          density="comfortable"
          rounded="xl"
          variant="outlined"
          :items="[
              'All',
              'Normal',
              'Crowdfunding',
            ]"
          class="elevation-0"
          color="blue"
        ></v-autocomplete>
      </v-col>

    </v-row>
  </v-card>

  <br />

  <v-row>
    <v-col cols="12" md="2">
      <div class="d-flex justify-start">
        <span class="ma-2">Show</span>

        <div>
          <v-select
            menu-icon=""
            v-model="params.take"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            :items="['10', '25', '50', '100', '500', '1000',]"
            class="elevation-0"
            color="blue"
          ></v-select>
        </div>

        <span class="ma-2">Entries</span>
      </div>
    </v-col>
    <v-col cols="12" md="10">
      <div class="d-flex justify-end">
        <span class="ma-2">Search</span>

        <div style="width: 30%">
          <v-text-field
            placeholder="search ..."
            append-inner-icon="mdi-magnify"
            width="100%"
            menu-icon=""
            v-model="params.search"
            density="comfortable"
            rounded="xl"
            variant="outlined"
            class="elevation-0"
            color="blue"
          ></v-text-field>
        </div>
      </div>
    </v-col>
  </v-row>

  <v-card rounded="0">
    <v-data-table
      density="comfortable"
      hide-default-footer
      :loading="loading"
    >
      <v-card elevation="0" rounded="0" class="pa-5" v-if="data.length < 1">
        No record available.
      </v-card>

      <thead>
      <tr class="bg-primary">
        <th v-for="row in headers" v-bind:key="row.key" class="row-none-wrap text-capitalize">
          {{ row.title }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="row in data" v-bind:key="row.id" class="row-none-wrap">
        <td>
          {{ row.Project.project_id }}
          <v-btn rounded="xl" @click="rowClickDialogOverview(row)" density="compact" variant="text" color="primary"
                 icon="mdi-help-circle-outline">
          </v-btn>
        </td>
        <td class="font-weight-bold">
          <v-btn rounded="xl" @click="rowClick(row)" density="compact" variant="tonal" color="primary">
            {{ row.invest_no }}
          </v-btn>
        </td>
        <td>{{ row.buy_share }}</td>
        <td>$ {{ formatPrice(row.price_per_share) }}</td>
        <td>{{ formatPrice(row.interest) }}</td>
        <td>{{ formatPrice(row.level_percent) }}%</td>
        <td>{{ row.interest_name }}</td>
        <td>{{ row.contrast }} days</td>
        <td>
          <v-btn @click="onTransfer(row)" :color="row.status === 'Pending' ? 'primary' : 'green'" class="text-none"
                 density="compact" rounded="xl" variant="tonal" :disabled="row.status === 'Completed'">
            {{ row.status }}
          </v-btn>
        </td>
        <td>$ {{ formatPrice(row.profit) }}</td>
        <td class="text-green">$ {{ formatPrice(row.total_profit) }}</td>
        <td>
          {{ formatDate(row.project_start, 'YYYY-MM-DD HH:mm A') }}
        </td>
        <td>{{ formatDate(row.project_end, 'YYYY-MM-DD HH:mm A') }}</td>
        <td>{{ row.is_crowdfunding }}</td>
      </tr>
      </tbody>
    </v-data-table>
  </v-card>

  <div class="app-paginate mt-5">
    <v-pagination
      v-model="params.skip"
      :length="totalPages"
      class="d-flex align-center"
      rounded="circle"
      color="primary"
      border
      total-visible="10"
    >
      <template #prev>
        <div class="d-flex">
          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip=1"
            :disabled="1 === totalPages"
            variant="outlined"
            border
            color="primary"
          >
            First
          </VBtn>

          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip--"
            :disabled="params.skip === 1"
            variant="outlined"
            border
            color="primary"
          >
            Previous
          </VBtn>
        </div>
      </template>
      <template #next>
        <div class="d-flex">
          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip++"
            :disabled="params.skip === totalPages"
            variant="outlined"
            border
            color="primary"
          >
            Next
          </VBtn>

          <VBtn
            width="100"
            rounded="xl"
            class="ma-2 text-none hide"
            @click="params.skip = totalPages"
            :disabled="params.skip === totalPages"
            variant="outlined"
            border
            color="primary"
          >
            Last
          </VBtn>
        </div>
      </template>
    </v-pagination>
  </div>

  <v-dialog width="1000" v-model="dialog" persistent>
    <v-card rounded="xl">
      <v-card-title class="my-3">
        <div class="d-flex justify-space-between">
          <p class="text-primary">
            <v-icon>mdi-clipboard-list-outline</v-icon>
            Report Interest Receiving
          </p>
          <v-btn color="red" rounded="xl" density="comfortable" icon="mdi-close" @click="dialog=false">
          </v-btn>
        </div>
      </v-card-title>
      <v-divider />
      <div>
        <!--        <pre>-->
        <!--          {{row.returnInterest[0]}}-->
        <!--        </pre>-->
        <v-data-table hide-default-footer density="compact">
          <thead>
          <tr class="text-capitalize">
            <th>no</th>
            <th>interest name</th>
            <th>interest receiving</th>
            <th>return date</th>
            <th>is return</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row,index) in row.returnInterest" v-bind:key="index">
            <td>{{ (index + 1) }}</td>
            <td>{{ row.interest_name }} - {{ (index + 1) }}</td>
            <td>${{ formatPrice(row.profit) }}</td>
            <td>{{ formatDate(row.return_date, 'YYYY-MM-DD HH:mm A') }}</td>
            <td>
              <v-icon v-if="row.dateReturnInterest> 0" color="blue">mdi-check-circle</v-icon>
              <v-icon v-else color="pink">mdi-clock-outline</v-icon>
            </td>
          </tr>
          </tbody>
        </v-data-table>

      </div>
      <v-divider />
      <v-card-actions>
        <v-btn
          class="text-none"
          block
          rounded="xl"
          width="200"
          color="primary"
          text="Disabled"
          variant="flat"
          @click="dialog = false"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog width="1000" v-model="dialogOverview" persistent>
    <v-card rounded="xl">
      <v-card-title class="my-3">
        <div class="d-flex justify-space-between">
          <p class="text-primary">
            <v-icon>mdi-help-circle-outline</v-icon>
            Overview
          </p>
          <v-btn color="red" rounded="xl" density="comfortable" icon="mdi-close" @click="dialogOverview=false">
          </v-btn>
        </div>
      </v-card-title>
      <v-divider />

      <v-card-title class="bg-blue-grey-darken-4">
        <div class="d-flex justify-space-between">
          <p class="text-white text-uppercase">
            Notice At-A-Glance
          </p>
        </div>
      </v-card-title>

      <v-row class="ma-2">
        <v-col cols="6" md="3">
          <p class="font-weight-bold my-2">Project ID</p>
          <p>{{ row.Project.project_id }}</p>
        </v-col>
        <v-col cols="6" md="3">
          <p class="font-weight-bold my-2">Project Title</p>
          <p>{{ row.Project.project_title }}</p>
        </v-col>
        <v-col cols="6" md="3">
          <p class="font-weight-bold my-2">Country</p>
          <p class="text-capitalize">{{ row.Project.Country.name }}</p>
        </v-col>
        <v-col cols="6" md="3">
          <p class="font-weight-bold my-2">Notice No</p>
          <p>{{ row.Project.notice_no }}</p>
        </v-col>

        <v-col cols="6" md="3">
          <p class="font-weight-bold my-2">Notice Type</p>
          <p>{{ row.Project.notice_type }}</p>
        </v-col>
        <v-col cols="6" md="3">
          <p class="font-weight-bold my-2">Notice Status</p>
          <p>{{ row.Project.notice_status }}</p>
        </v-col>
        <v-col cols="6" md="3">
          <p class="font-weight-bold my-2">Borrower Bid Reference</p>
          <p>{{ row.Project.borrower_bid_reference }}</p>
        </v-col>
        <v-col cols="6" md="3">
          <p class="font-weight-bold my-2">Procurement Method</p>
          <p>{{ row.Project.procurement_method }}</p>
        </v-col>

        <v-col cols="6" md="3">
          <p class="font-weight-bold my-2">Language of Notice</p>
          <p>{{ row.Project.language_of_notice }}</p>
        </v-col>
        <v-col cols="6" md="3">
          <p class="font-weight-bold my-2">Submission Deadline Date/Time</p>
          <p>{{ formatDate(row.Project.created_at, 'MMM DD, YYYY HH:mm') }}</p>
        </v-col>
        <v-col cols="6" md="6">
          <p class="font-weight-bold my-2">Published Date</p>
          <p>{{ formatDate(row.Project.board_approval_date, 'MMM DD, YYYY') }}</p>
        </v-col>

        <!--        <v-col cols="6" md="3">-->
        <!--          <v-btn color="primary" class="text-none">-->
        <!--            Feedback Survey-->
        <!--          </v-btn>-->
        <!--        </v-col>-->

      </v-row>

      <!--            <pre>-->
      <!--              {{ row.Project }}-->
      <!--            </pre>-->
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>